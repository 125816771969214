import React from 'react';
import '../../App.css';
import { 
    Marker,
    Tooltip
   } from "react-leaflet";
import { Icon } from "leaflet";
import MarkModal from '../mark/MarkModal';

export default function MapMarks({marks}) {
    const [activeMark, setactiveMark] = React.useState(null);
    const [modalShow, setModalShow] = React.useState(false);

    function hideModal(){
        setModalShow(false)
        setactiveMark(null)
    }

    return (
      <div>
        {marks.map(mark => (
          <Marker
            key={mark.id}
            position={[
              mark.lat,
              mark.lng
            ]}
            onClick={() => {
                setactiveMark(mark);
                setModalShow(true);
                /*console.log('Mark click');*/
            }}
            icon={new Icon({
              iconUrl: `/images/${mark.icon}`,
              iconSize: [25, 25]
            })}
          >
            
            <Tooltip direction="top">
              <span><strong>{mark.title}</strong></span>
            </Tooltip>

          </Marker>            
        ))}

       {activeMark && (
          <MarkModal show={modalShow} onHide={() => hideModal()} mark={activeMark} />
        )}
      </div>
    )
  }