import React, { useState, useEffect } from 'react'
import reactParse from 'html-react-parser'
import {
    Card,
    Image,
    Table,
    Alert
} from 'react-bootstrap'
import GroupRow from './GroupRow'
import YoutubePlayer from '../mark/YoutubePlayer'
import dbService from '../../services/db'
import {allGroups} from '../../components/checks/ChecksData';
import {urlUploads} from '../../components/checks/ChecksData';
import Spinner from '../LoadSpinner'

export default function MarkCard({mark}) {

  const [data, setData]= useState({images:[],
                                   texts:[],
                                   groups:[],
                                   error: null,
                                   loading:'Loading data in progress'})

  //fetch data
  useEffect(() => {  
    setData({...data,
                loading:'Loading data in progress'})
    //setTimeout(() => {
    dbService
      .getMarkData(mark.id)      
      .then(response => {
        //console.log('Mark data: ',response.data)
        let groups = response.data[2]
        let groupsId = []
        groups.forEach(group => {
          groupsId.push(group.id)
        });
        if (!groupsId.includes(mark.primary_group)){
          groups.unshift(addPrimary(mark.primary_group))
        } 

        setData({ images:response.data[0],
                  texts:response.data[1],
                  groups:groups,
                  error: null,
                  loading:null})
      })  
      .catch(err => {
        //console.log(err)
        setData({...data,
                    error: 'Oh no.. Just now the application can not show data. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.',
                    loading:null})
      })  
     // }, 1500)
    }, []) 

    function addPrimary(primId){
      let allgroups = allGroups()
      let prim = allgroups[primId]
      return prim
    }

  function eventTime (){
    //console.log('mark start: ',mark.start)
    //console.log('mark end: ',mark.end)
    if (mark.start==null || mark.start=='0000-00-00') return ''
    let startDate = new Date(mark.start)
    //console.log('mark startDate: ',startDate)
    let start = startDate.toLocaleDateString()
    //console.log('mark start: ',start)
    if (start!=='Invalid Date'){
      if (mark.end===null || mark.end==='0000-00-00'){
        return start
      }else{
        let endDate = new Date(mark.end)
        let end = endDate.toLocaleDateString()
        if (start===end || end==='Invalid Date'){
          return start
        } else {
          return start + '-' + end
        }
      }
    }else{
      return ''
    }
  }

  function videoId(link){
    let id = link.slice(link.lastIndexOf('/')+1,link.length)
    return id
  }

  function showAlert(text){
    return <Alert variant='danger'>
              {text}
           </Alert>
  }

  return (
    <>
    <Card className="markCard">
      <Card.Body>
        <h4>{mark.title}</h4>

        {(data.loading!==null)
        ?<Spinner title={data.loading} />
        :(data.error===null)
          ?<>
            {data.images.map((image, i) => 
                <Image 
                  key={i} 
                  src={urlUploads+image.imgName} 
                  className='cardImage'
                  alt={data.images[0].imgName}/>
            )}

            {mark.type===2
            ?<>
              {(data.images.length===0) &&
                <YoutubePlayer videoId={videoId(mark.link)}/>
              }
            </>
            :<>
              <div>
                <div className="cardGroups" >
                  <GroupRow groups={data.groups} />
                </div>
                {(mark.type===1) &&
                  <p className="cardTime">{eventTime()}</p>
                }
              </div>
              <div style={{clear: "both"}}></div>
            </>
            }


            {data.texts.map((text, i) => 
              <>
              <div className="cardText" key={i}>
              {reactParse(text.text)}
              </div>
              </>
            )}

            {mark.type!==2 &&
              <Table style={{width: '100%'}} className='mt-2 mb-2' responsive>
              <tbody>
                {mark.orgname!=='' &&
                  <tr>
                    <td>Organization: </td>
                    <td>{mark.orgname}</td>
                  </tr>
                }
                {mark.contact!=='' &&
                  <tr>
                    <td>Contact: </td>
                    <td>{mark.contact}</td>
                  </tr> 
                }
                {mark.link!=='' &&
                  <tr>
                    <td>Link: </td>
                    {mark.type===2
                      ?<td><a href={mark.link} target="_blank">{mark.link}</a></td>
                      :<td><a href={mark.link} target="_blank">{mark.link}</a></td>
                    }
                    
                  </tr>            
                }
              </tbody>
              </Table>
            }
          </>
          :showAlert(data.error)
        }
      </Card.Body>
    </Card>
    </>
  );
}
