export const urlImages='/images/';
//export const urlUploads='http://localhost:5000/uploads/'
//export const urlUploads='https://vomgomapapp.azurewebsites.net/uploads/'
export const urlUploads='https://map.villagesonmove.com/uploads/'

export const suitables =[44,12,39,13,46,16,17]
export const eventsList =[19,33,21,20,24,49,32,45,36]
export const ideasList =[43,42,27,30,24,40]

export const firesoul = {
    icon: 'firesoul.png',
    name: 'Firesoul',
    value: 47}

export const once = {
    icon: 'once.png',
    groupname: 'Once',
    value: 19}

export const regular = {
    icon: 'regular.png',
    groupname: 'Regular',
    value: 33}


export const suitableGroups =[{
    icon: 'kids.png',
    name: 'Kids',
    value: 44},
    {icon: 'young.png',
    name: 'Young',
    value: 12},
    {icon: 'adult.png',
    name: 'Adult',
    value: 39},
    {icon: 'senior.png',
    name: 'Senior',
    value: 13},
    {icon: 'family.png',
    name: 'Family',
    value: 46},
    {icon: 'disabled.png',
    name: 'Disabled',
    value: 16},
    {icon: 'multiple.png',
    name: 'Multiple',
    value: 17}]

export const eventGroups = [{
    groupname: 'Frequency',
    group1:{icon:once.icon,name: once.groupname,value: once.value},
    group2:{icon:regular.icon,name: regular.groupname,value: regular.value}
    },
    {
    groupname: 'Price',
    group1:{icon: 'fee.png',name: 'Fee',value: 21},
    group2:{icon: 'free.png',name: 'Free',value: 20}
    },
    {groupname: 'Skills level',
    group1:{icon: 'easy.png',name: 'Easy',value: 24},
    group2:{icon: 'advantage.png',name: 'Advantage',value: 49},
    group3:{icon: 'professional.png',name: 'Professional',value: 32}
    },
    {groupname: 'Style',
    group1:{icon: 'new.png',name: 'New',value: 45},
    group2:{icon: 'traditional.png',name: 'Traditional',value: 36}
    }]

export const ideaGroups = [{
    groupname: 'Season',
    group1:{icon: 'summer.png',name: 'Summer',value: 43},
    group2:{icon: 'winter.png',name: 'Winter',value: 42}
    },
    {groupname: 'Place',
    group1:{icon: 'inside.png',name: 'Inside',value: 27},
    group2:{icon: 'outside.png',name: 'Outside',value: 30}
    },
    {groupname: 'Preparation',
    group1:{icon: 'easy.png',name: 'Easy',value: 24},
    group2:{icon: 'need_preparation.png',name: 'Need preparation',value: 40}
    }]

export const allGroups = () => {
    let groups = []
    for (let i = 0; i < 50; i++) {
        groups.push({groupname:'',icon:'',value:null});
    }
    for (let i = 0; i < 7; i++) {
        groups[suitableGroups[i].value].groupname=suitableGroups[i].name
        groups[suitableGroups[i].value].icon=suitableGroups[i].icon
        groups[suitableGroups[i].value].value=suitableGroups[i].value
      }
    for (let i = 0; i < 3; i++) {
        groups[ideaGroups[i].group1.value].groupname=ideaGroups[i].group1.name
        groups[ideaGroups[i].group2.value].groupname=ideaGroups[i].group2.name
        groups[ideaGroups[i].group1.value].icon=ideaGroups[i].group1.icon
        groups[ideaGroups[i].group2.value].icon=ideaGroups[i].group2.icon
        groups[ideaGroups[i].group1.value].value=ideaGroups[i].group1.value
        groups[ideaGroups[i].group2.value].value=ideaGroups[i].group2.value
    }
    for (let i = 0; i < 4; i++) {
        groups[eventGroups[i].group1.value].groupname=eventGroups[i].group1.name
        groups[eventGroups[i].group2.value].groupname=eventGroups[i].group2.name
        
        groups[eventGroups[i].group1.value].icon=eventGroups[i].group1.icon
        groups[eventGroups[i].group2.value].icon=eventGroups[i].group2.icon
        
        groups[eventGroups[i].group1.value].value=eventGroups[i].group1.value
        groups[eventGroups[i].group2.value].value=eventGroups[i].group2.value
        
    }
    groups[32].groupname=eventGroups[2].group3.name
    groups[32].icon=eventGroups[2].group3.icon
    groups[32].value=eventGroups[2].group3.value

    groups[firesoul.value].value=firesoul.value
    groups[firesoul.value].icon=firesoul.icon
    groups[firesoul.value].name=firesoul.name

    //console.log('all groups: ',groups)
    return groups
}