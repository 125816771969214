import React from 'react';
import {
    Table,
    Image,
    Alert
  } from 'react-bootstrap';
import dbService from '../../../services/db';
import {allGroups, urlImages} from '../../checks/ChecksData';
import Spinner from '../../LoadSpinner';


class Summary extends React.PureComponent {
    constructor(props){
      super(props)
      this.state = {
                allPcs:[],
                eventsPublish: [],
                eventsArchived: [],
                ideasPublish: [],
                ideasArchived: [],
                users: [],
                loading:null,
                error:null}
    }

    //fetch summarydata
    componentDidMount() {   
      this.summaryData()
    }

    summaryData(){
      this.setState({
        ...this.state,
        loading:'Data loading in progress',
        error:null
      })
      //setTimeout(() => {
      dbService
        .getSummaryData()
        .then(response => {
          console.log('summaryPcs: ',response.data);

          //all marks pcs
          let pcsAll = []
          for (let i = 0; i < 3; i++) {
            pcsAll.push({type:i,archived:0,publish:0})
          }

          response.data.all.forEach(e => {
            if (e.status===0) pcsAll[e.type].archived=e.pcs
            if (e.status==1) pcsAll[e.type].publish=e.pcs
          });

          //primarys arrays
          let primEventPublish = []
          let primEventArchived = []
          let primIdeaPublish = []
          let primIdeaArchived = []

          let primFiresoulPublish = 0
          let primFireSoulArchived = 0

          let eventPublish = []
          let eventArchived = []
          let ideaPublish = []
          let ideaArchived = []

          for (let index = 0; index < 50; index++) {
            primEventPublish.push(0)
            primEventArchived.push(0)
            primIdeaPublish.push(0)
            primIdeaArchived.push(0)
            eventPublish.push(0)
            eventArchived.push(0)
            ideaPublish.push(0)
            ideaArchived.push(0)
          }

          response.data.primarys.forEach(primary => {
            switch (primary.type) {
              case 0:
                if (primary.status===0) primIdeaArchived[primary.groupId]=primIdeaArchived[primary.groupId]+primary.pcs
                if (primary.status===1) primIdeaPublish[primary.groupId]=primIdeaPublish[primary.groupId]+primary.pcs
                break;
              case 1:
                  if (primary.status===0) primEventArchived[primary.groupId]=primEventArchived[primary.groupId]+primary.pcs
                  if (primary.status===1) primEventPublish[primary.groupId]=primEventPublish[primary.groupId]+primary.pcs
                break;
              case 2:
                if (primary.status===0) primFireSoulArchived=primFireSoulArchived+primary.pcs
                if (primary.status===1) primFiresoulPublish=primFiresoulPublish+primary.pcs
                break;
              default:
                break;
            }
          });

          //groups (classification)
          response.data.eventPublish.forEach(event => {
            eventPublish[event.groupId]=event.pcs
          });

          response.data.eventArchived.forEach(event => {
            eventArchived[event.groupId]=event.pcs
          });

          response.data.ideaPublish.forEach(idea => {
            ideaPublish[idea.groupId]=idea.pcs
          });

          response.data.ideaArchived.forEach(idea => {
            ideaArchived[idea.groupId]=idea.pcs
          });

          //make arrays for grouptables
          let eventsPublish = this.makeGroupArray(eventPublish,primEventPublish,pcsAll[1].publish)
          let eventsArchived = this.makeGroupArray(eventArchived,primEventArchived,pcsAll[1].archived)
          let ideasPublish = this.makeGroupArray(ideaPublish,primIdeaPublish,pcsAll[0].publish)
          let ideasArchived = this.makeGroupArray(ideaArchived,primIdeaArchived,pcsAll[0].archived)

          //make data for userstables
          let usersData = this.makeUsersData(response.data.users)
          
          this.setState({
                  allPcs:pcsAll,
                  eventsPublish: eventsPublish,
                  eventsArchived: eventsArchived,
                  ideasPublish: ideasPublish,
                  ideasArchived: ideasArchived,
                  users: usersData,
                  loading:null,
                  error:null
                  })
                  this.setState({...this.state,loading:null})
      })
      .catch(err => {
        /*let message = 'getSummaryData DB: ';
        if (err.response) {
          message=message+'client received an error response '+err;
        } else if (err.request) {
          message=message+'client never received a response, or request never left';
        } else {
          message=message+'unexpected error '+err;
        }
        console.log(message);*/

        this.setState({
                    ...this.state,
                    loading:null,
                    error:'Database error: Failed to fetch data. Please, try later again.'
                    })
      })
    //}, 1000);
  }

  makeUsersData(data){
    let usersData = []

    let prevId = 0
    let name = ''
    let userItem = null
    data.forEach(user => {
      console.log('user: ',user)
      if (user.id===prevId){
        userItem.marks[user.type] = user.pcs
      }else{
        name = user.firstname+' '+user.lastname
        userItem = {id:user.id,name:name,organization:user.organization,orgtype:user.orgtype,marks:[0,0,0]}
        userItem.marks[user.type] = user.pcs
        usersData.push(userItem)
        prevId=user.id
      }
    });
    return usersData
  }

  //group class
  makeGroupArray(groups,prims,groupSum){
    let groupArray = []
    let groupItem = null

    for (let i = 0; i < 50; i++) {
      groupItem = {groupId:null,pcs:0,prim:0,class:0,portion:0}
      if ((groups[i]>0)||(prims[i]>0)){
          let sum = groups[i]+prims[i]
          groupItem.groupId = i
          groupItem.pcs = sum
          groupItem.prim = prims[i]
          groupItem.class = groups[i]
          groupItem.portion = ((sum/groupSum)*100).toFixed(1)
          groupArray.push(groupItem)
      }
    }
    
    groupArray.sort(this.comparePortions);

    return groupArray
  }

  comparePortions(a, b) {
    const portionA = a.portion;
    const portionB = b.portion;
    return portionB-portionA
  }

  columnTitle(type){
    if (type===0) return 'Ideas'
    if (type===1) return 'Events'
    if (type===2) return 'Firesouls'
  }

  summaryTable(marks){
    return <>
      <Table striped bordered hover size="sm" className='tableSummary'>
          <thead>
              <tr>
              <th></th>
              <th>Publish</th>
              <th>Archived</th>
              <th>Total</th>
              </tr>
          </thead>
          <tbody>
              {marks.map((mark,i) =>
                  <tr key={i}>
                      <td className='firstColumn'>{this.columnTitle(mark.type)}</td>
                      <td>{mark.publish}</td>
                      <td>{mark.archived}</td>
                      <td>{mark.publish+mark.archived}</td>
                  </tr>
              )}
          </tbody>
      </Table>
    </>
  }

  groupsTable(groups,title){
    let allgroups = allGroups()
    return <>
      <Table striped bordered hover size="sm" className='tableSummary' responsive>
          <thead>
              <tr>
                <th>{title}</th>
                <th>Prim</th>
                <th>Class</th>
                <th>Total</th>
                <th>% of targets</th>
              </tr>
          </thead>
          <tbody>
              {groups.map((group,i) =>
                <tr key={i}>
                  <td className='firstColumn'>
                    <Image src={urlImages+allgroups[group.groupId].icon} className='imgSearch'  />
                    {allgroups[group.groupId].groupname}
                  </td>
                  <td>
                    {group.prim}
                  </td>
                  <td>
                    {group.class}
                  </td>
                  <td>
                    {group.pcs}
                  </td>
                  <td>
                    {group.portion} %
                  </td>
                </tr>
              )}
          </tbody>
      </Table>
    </>
  }

  usersTable(users){
    return <>
      <Table striped bordered hover size="sm" className='tableSummary' responsive>
          <thead>
              <tr>
                <th>Name</th>
                <th>Organization</th>
                <th>Type</th>
                <th>Ideas</th>
                <th>Events</th>
                <th>Firesouls</th>
              </tr>
          </thead>
          <tbody>
              {users.map((user,i) =>
                <tr key={i}>
                  <td>
                    {user.name}
                  </td>
                  <td>
                    {user.organization}
                  </td>
                  <td>
                    {user.orgtype}
                  </td>
                  <td>
                    {user.marks[0]}
                  </td>
                  <td>
                    {user.marks[1]}
                  </td>
                  <td>
                    {user.marks[2]}
                  </td>
                </tr>
              )}
          </tbody>
      </Table>
    </>    
  }

  render() {
    return (
      <>
        {this.state.loading!==null
          ?<Spinner title={this.state.loading} />
          :<>
            {this.state.error!==null
              ?
              <div className="CrudBox mt-2">
                <Alert variant='danger' className='m-4'>
                  {this.state.error}
                </Alert>
              </div>
              :<div className="CrudBox mt-2">
                  <h4>All marks</h4>
                  {this.summaryTable(this.state.allPcs)}
                  <br/>

                  <h4>Events</h4>
                  {this.groupsTable(this.state.eventsPublish,'Publish')}
                  <br/>
                  {this.groupsTable(this.state.eventsArchived,'Archived')}
                  <br/>

                  <h4>Ideas</h4>
                  {this.groupsTable(this.state.ideasPublish,'Publish')}
                  <br/>
                  {this.groupsTable(this.state.ideasArchived,'Archived')}

                  <h4>Users</h4>
                  {this.usersTable(this.state.users)}
              </div>
            }
          </>
        }
      </>
    )
  }
}

export default Summary;