import React, {useState,useEffect} from 'react'
import {
  Form,
  Button,
  Card
} from 'react-bootstrap';
import dbService from '../../services/db';
import {useSessionStorage} from '../../services/sessionStorageHook';
import Spinner from '../LoadSpinner';

export default function ChangePassword({showLevel}) {

    //console.log('ChangePassword showLevel: ',showLevel)

    const [sessionData] = useSessionStorage("vomgoUser")

    const [data, setData] = useState({
      id: sessionData.id,
      password: '',
      confirm: '',
      changed:false,
      error:null,
      saved: false,
      loading: false
    });

    const [validated, setValidated] = useState(false);
    const errorServer = 'Failed to update profile: server error. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'

    useEffect(() => {
      if (sessionData.level!==showLevel){ //if admin edit partners ids
        setData({...data,id:2,loading:false})
      }
    }, [])

    const onFormSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
  
      if (form.checkValidity() === false) {
        event.stopPropagation();
      }else{
        if (data.changed){
          updatePasswordDB(data.id)
        }
      }
      setValidated(true);   
    }

    const handleOnChange = (event) => {
      let value = event.target.value;
      let name = event.target.name;
      setData({...data,
          [name]:value,
          changed: true,
          error: null,
          saved:false,
          loading:false})
      setValidated(false); 
    }

    function updatePasswordDB(id){
      let ids = {id:id,password:data.password}
      setData({...data,error:null,saved:false,loading:true})
    //setTimeout(() => {
      dbService
        .updatePassword(ids)
        .then(response => {
          //console.log('Update user password: ', response.data)
          setData({...data,error:null,saved:true,loading:false})
        })  
        .catch(err => {
          /* let message = 'Update user password DB: ';
          if (err.response) {
              message=message+'client received an error response (5xx, 4xx) '+err;
          } else if (err.request) {
              message=message+'client never received a response, or request never left '+err;
          } else {
              message=message+'unexpected error: '+err;
          }
          console.log('error: '+message) */
          setData({...data,error:errorServer,saved:false,loading:false})
        }) 
      //}, 1000);
    }

    function invalidConfirmPassword(){
      if (data.confirm==='') return 'Please confirm your new password'
      return "Please try again. Passwords don`t match each others."
    } 
  
    function invalidPassword(){
      if (data.password==='') return 'Please enter a new password.'
      return 'Password minimum length is 6 character.'
    } 
  
    return (
      <>
      {data.loading===true
        ?<Spinner title={'Change password in progress.'} />
        :<>
          <Form  noValidate validated={validated} onSubmit={onFormSubmit} className="loginForm mb-4">
            <h5 className='font-weight-bold mb-2'>Change password</h5>

            <Form.Group controlId="formPassword">
                  <Form.Label>New password *</Form.Label>
                  <Form.Control type="password" name="password" value={data.password} onChange={handleOnChange} required minLength="6"/>
                  <Form.Control.Feedback type="invalid">
                      {invalidPassword()}
                  </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPassword">
                  <Form.Label>Confirm new password *</Form.Label>
                  <Form.Control type="password" name="confirm" value={data.confirm} onChange={handleOnChange} required pattern={data.password}/>
                  <Form.Control.Feedback type="invalid">
                      {invalidConfirmPassword()}
                  </Form.Control.Feedback>
              </Form.Group>

              {data.saved===true
              ?
                <Card bg="success" text="white" className='mb-2 text-center' style={{width:'100%'}}>
                  <Card.Header>Password is saved</Card.Header>
                </Card>
              :
                <>
                {data.error===null
                  ?
                  <div className='buttonRight'>
                    {data.changed
                    ?<Button variant="success" type="submit">Save</Button>
                    :<Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button> 
                    }
                  </div>
                  :
                  <Card bg="danger" text="white" className='mb-2 text-center' style={{width:'100%'}}>
                    <Card.Header>{data.error}</Card.Header>
                  </Card>
                }
                </>
              }
            </Form>    
          </>
        } 
      </>
    );
  }

