import React, { useState, useEffect } from 'react'
import {
    Image,
    OverlayTrigger,
    Tooltip
  } from 'react-bootstrap';
import {urlImages} from '../../components/checks/ChecksData';

export default function GroupRow({groups}) {

    function renderTooltip(props) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          {props}
        </Tooltip>
      );
    }

    return (
      <div className="mb-2">

      {groups.length!==0
      ? <>
        {groups.map((group, i) => 
        <OverlayTrigger
        key={i} 
        placement="bottom"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip(group.groupname)}
        >
          <Image 
             key={i} 
             src={urlImages+group.icon} 
             className='groupIcon hoverinfo'
             alt={'icon'}/>
        </OverlayTrigger>
        )}
        </>
      :<p>Database error: classification failed</p>
      }
      </div>
    )
}