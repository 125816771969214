import React, {useState} from 'react'
import reactParse from 'html-react-parser'
import {
    Card,
    Image,
    Dropdown,
    DropdownButton,
    ButtonGroup,
    Button,
    Table
} from 'react-bootstrap'
import NewModal from './NewModal'
import GroupRow from '../../../mark/GroupRow'
import YoutubePlayer from '../../../mark/YoutubePlayer'
import {once,regular} from '../../../checks/ChecksData'

export default function PreviewMark({newMark, showDialog}) {
  const [newModalShow, setNewModalShow] = useState({show:false, type:null, mark:null});

  //console.log('newmark: ',newMark)

  function typeChech(type){
    if (type===4) return 2
    return type
  }

  const [mark,setMark] = useState({
                        lat:newMark.lat,
                        lng:newMark.lng,
                        title:newMark.title,
                        primary_group:newMark.primary_group,
                        startDate:newMark.startDate,
                        endDate:newMark.endDate,
                        link:newMark.link,
                        author:newMark.author,
                        orgname:newMark.orgname,
                        contact:newMark.contact,
                        status:newMark.start,  
                        type: typeChech(newMark.type2),
                        userId:newMark.userId,
                        groups:newMark.groups, //only ids
                        text1:newMark.text1,  //english
                        text2:newMark.text2,  //other language
                        images:newMark.images,
                        type2:newMark.type2
  })

  //console.log('mark: ',mark)

  function groupsRow(){
    let groups = mark.groups
    let ids = []
    groups.forEach(group => {
      ids.push(group.value)
    });
    if (!ids.includes(mark.primary_group.id)){
      let primary={groupname: mark.primary_group.name, icon: mark.primary_group.icon, value: mark.primary_group.id}
      groups.unshift(primary)
    } 
    return groups
  }

  function eventTime (){
    if (mark.startDate==null || mark.startDate=='0000-00-00') return 'Regular'
    let startDate = new Date(mark.startDate)
    let start = startDate.toLocaleDateString()
    if (start!=='Invalid Date'){
      if (mark.endDate===null || mark.endDate==='0000-00-00'){
        return start
      }else{
        let endDate = new Date(mark.endDate)
        let end = endDate.toLocaleDateString()
        if (start===end || end==='Invalid Date'){
          return start
        } else {
          return start + '-' + end
        }
      }
    }
  }

  function showNewModal(mark,type){
    setNewModalShow({show:true,type:type,mark:mark});
  }

  function newModal(type) {
    let title = ''

    if (type === 'Contacts')    title='Title and Contact Information';
    if (type === 'Images')      title='Images';
    if (type === 'Groups')      title='Classification';
    if (type === 'Location')    title='Location';
    if (type === 'Texts')       title='Texts';
    if (type === 'Frequency')   title='Frequency';
  
    return <NewModal show={newModalShow.show} onHide={(data) => hideNewModal(data)}  mark={newModalShow.mark} title={title}/>
  } 

  function hideNewModal(data){
    console.log('preview edit data:',data)
    if (data!=='noChanges'){
      switch (newModalShow.type) {
        case 'Contacts':
          setMark({...mark,
                      title: data.title,
                      author: data.author,
                      contact: data.contact,
                      link: data.link,
                      orgname: data.orgname
          })
          break;
        case 'Images':
          setMark({...mark,
                      images: data
          })
          break;
        case 'Texts':
          setMark({...mark,
                      text1: data[0],
                      text2: data[1]
          })
          break;
        case 'Groups':
          setMark({...mark,
                      primary_group: {id:data.primary.id,
                                      name:data.primary.name,
                                      icon:data.primary.icon},
                      groups: data.groups
          })
          break;
        case 'Location':
          setMark({...mark,
                    lat: data[0],
                    lng:data[1]
          })
          break;
        case 'Frequency':
          let timegroup = [data.timegroup]
          let groups = [...mark.groups]

          let timeRegular = groups.indexOf(regular)
          let timeOnce = groups.indexOf(once)

          if ((timeRegular===-1)&&(timeOnce===-1)){
            groups.push(timegroup) 
          }else{
            if ((timeRegular!==-1)&&(timegroup!==regular)){
              groups[timeRegular]=once
            }else{
              if ((timeOnce!==-1)&&(timegroup!==once)){
                groups[timeOnce]=regular
              }
            }
          }

          setMark({...mark,
                    startDate:data.from,
                    endDate:data.to,
                    groups:groups
          })
          break;
        default:
          break;
      }
    }
    setNewModalShow({show:false,type:null,mark:null});
  }


  function videoId(link){
    let id = link.slice(link.lastIndexOf('/')+1,link.length)
    return id
  }

  return (
    <>
    <h4>Preview</h4>
    <Card className="markCard">
      <Card.Body>
  
        <table style={{width: '100%'}} className='mb-2'>
          <tbody>
            <tr>
              <td><h3>{mark.title}</h3></td>
              {mark.type===1 &&
                <td style={{float: 'right'}}>
                  <span className='cardTime'>{eventTime()}</span>
                </td>
              }
            </tr>
          </tbody>
        </table>

        { mark.images.map((image, i) => 
            <Image 
               key={i} 
               src={URL.createObjectURL(image)}  
               className='cardImage'
               alt={image}/>
        )}

        {newMark.type2===2 &&
          <YoutubePlayer videoId={videoId(mark.link)}/>
        }

        {mark.type!==2 &&
          <GroupRow className="groupRow" groups={groupsRow()}/>
        }

        {(mark.text1!='') &&
            <div className="cardText">
              {reactParse(mark.text1)}
            </div>
        }

        {(mark.text2!='') &&
            <div className="cardText">
              {reactParse(mark.text2)}
            </div>
        }

        {mark.type!==2 &&
          <Table style={{width: '100%'}} className='mt-2 mb-2' responsive>
          <tbody>
            {mark.orgname!=='' &&
              <tr>
                <td><b>Organization: </b></td>
                <td>{mark.orgname}</td>
              </tr>
            }
            {mark.contact!=='' &&
              <tr>
                <td><b>Contact: </b></td>
                <td>{mark.contact}</td>
              </tr> 
            }
            {mark.link!=='' &&
              <tr>
                <td><b>Link: </b></td>
                <td><a href={mark.link}>{mark.link}</a></td>
              </tr>            
            }
          </tbody>
        </Table>
        }

      </Card.Body>
    </Card>
    <div className='mt-2 mb-4'>
      <DropdownButton id="dropdown-item-button2" title="Edit" className='dropdown'>
        <Dropdown.Item as="button" onClick={() => showNewModal(mark,'Contacts')} className='dropButton'>Title and contacts</Dropdown.Item>
        {mark.type===1 && //only event
          <Dropdown.Item as="button" onClick={() => showNewModal(mark,'Frequency')} className='dropButton'>Frequency</Dropdown.Item>
        }
        {((mark.type===0)||(mark.type===1)||(newMark.type2===4)) && //event and idea and imageFiresoul
          <Dropdown.Item as="button" onClick={() => showNewModal(mark,'Images')} className='dropButton'>Images</Dropdown.Item>
        }
        <Dropdown.Item as="button" onClick={() => showNewModal(mark,'Texts')} className='dropButton'>Descriptions</Dropdown.Item>
        {((mark.type===0)||(mark.type===1)) && //event and idea
          <Dropdown.Item as="button" onClick={() => showNewModal(mark,'Groups')} className='dropButton'>Classification</Dropdown.Item>
        }
        <Dropdown.Item as="button" onClick={() => showNewModal(mark,'Location')} className='dropButton'>Location</Dropdown.Item>
      </DropdownButton>

      <ButtonGroup aria-label="Basic example" style={{float: "right"}}>
        <Button variant="primary" onClick={() => showDialog(mark,'publish')}>Publish</Button>
        <Button variant="danger" onClick={() => showDialog(mark,'delete')}>Delete</Button>
      </ButtonGroup>

      {newModalShow.show && (
        newModal(newModalShow.type)
      )}
    </div>

    </>
  );
}
