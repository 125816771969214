import React from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap';
import MarkCard from './MarkCard'

export default function MarkModal({show,onHide,mark}) {

  let cssClass='markHeader'
  
  function showTitle(type,archived){
    let title = 'Event';
    if (type===0) title = 'Idea';
    if (type===2) title = 'Firesoul';
    if (archived===0) title = 'Archived '+ title;
    return title;
  }

  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={cssClass} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {showTitle(mark.type, mark.status)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="modalBg">
        <MarkCard mark={mark} />
      </Modal.Body>

      <Modal.Footer className="markHeader">
          <img
            src="/images/logo.png"
            height="60"
            className="d-inline-block align-top rounded mr-2"
            alt="logo"
            style={{backgroundColor:'ghostwhite', padding: '4px'}}
          />
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
  }
