import React, {useState} from 'react';
import {
    Button,
    ListGroup,
    Card
} from 'react-bootstrap';


export default function NewGuide({onHide, type}) {  

    const [showText, setShowText] = useState(false)

    let markName =''
    let partPcs = 0

    switch (type) {
        case 0:
          markName='idea';
          partPcs = 6;
          break;
        case 1:
          markName='event';
          partPcs = 7;
          break;
        case 2:
          markName='firesoul';
          partPcs = 4;
          break;    
        default:
          break;
      }


    function goNext(text){
        onHide(text)
    }

    const instructionText = () => (
      <Card>
        <Card.Header as="h5">
            Instructions
            <Button variant="secondary" size="sm" style={{float:'right'}} onClick={() => setShowText(false)}>Close</Button>
        </Card.Header>
          <Card.Body>
          <ListGroup variant="flush">
              {type===2 //firesoul
                ? <ListGroup.Item>The new {markName} description has 4 (with video) or 5 (with images) parts. You can follow the numbers in the top-right-corner.</ListGroup.Item>
                :<ListGroup.Item>The new {markName} description has {partPcs} parts. You can follow the numbers in the top-right-corner.</ListGroup.Item>
              }
              <ListGroup.Item>Every part has a <i>Next</i> -button in the down-right-corner. Press it when you want to proceed to the next part of your {markName}.</ListGroup.Item> 
              <ListGroup.Item>Finally, after filling up the all parts, you can review and edit the whole new {markName} before publishing.</ListGroup.Item>
              <ListGroup.Item>You can close the entire creation process with the X -button in the top right-corner at any time.</ListGroup.Item>
              </ListGroup>              
          </Card.Body>
      </Card>
    )

    return (
        <>
        <div className='mb-4 mt-2'>
            <h4 className='mb-4'>Welcome to create a new {markName}! </h4>
            
            <p className='infoBox mb-4 p-4'>
            <span style={{fontSize: 18}}>Please, note!</span>
              <hr/>
              <b>Don't use browser's Back -button. </b> 
              It will close the entire create process and you’ll lose all added information.

              {type===2 && <><hr/>
                <b>Before starting to create Fire Soul</b>, please contact: <a href='mailto:villagesonmovego@gmail.com'>villagesonmovego@gmail.com</a> and describe the person, who you are going to suggest.
                <br/><br/> 

                The main criteria for a fire soul is one or several form the below ones: <br/>

                -	Passion for sport <br/> 
                -	Inspiration for others to have physical activity <br/> 
                -	Promoting facilities for sport <br/> 
                -	Building new knowledge for sports <br/>
                </>
              }
 
            </p>

            {showText &&
              instructionText()
            }

        </div>

        {type!==2 
        ?<div className='buttonRight'>
          {!showText &&
            <Button variant="success" className={'mt-2'} onClick={() => setShowText(true)}>Read more instructions</Button>
          }
          <Button variant="primary" className={'mt-2 ml-2'} onClick={() => goNext('noChanges')} >Start creating</Button>
         </div>
        :<div className='buttonRight'>
          {!showText &&
            <Button variant="success" className={'mt-2'} onClick={() => setShowText(true)}>Read more instructions</Button>
          }
          <Button variant="primary" className={'mt-2 ml-2'} onClick={() => goNext('videoFiresoul')} >Start creating with video</Button>
          <Button variant="primary" className={'mt-2 ml-2'} onClick={() => goNext('imageFiresoul')} >Start creating with image</Button>
         </div>
        }

        </>
    )
}