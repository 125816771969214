import React, {useContext, useState} from 'react'
import {
    Modal,
    Button,
    Card
} from 'react-bootstrap';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import ChangePassword from './ChangePassword';
import {useHistory} from 'react-router-dom';
import {MapContext} from '../../contexts/MapContext';


export default function LoginModal({show,onHide,setUser,formName,showLevel}) {

  const [mapData] = useContext(MapContext);

  const [formType, setFormType] = useState(formName)

  let cssClass='modalHeader';
  let history = useHistory();

  const nextShow = (typeForm) => {
    switch (typeForm) {
      case 'openManagement':
        openManagement()
        break;
      case 'SignIn':
        setFormType('SignIn')     //log in form
        break;
      case 'SignUp':
        setFormType('SignUp')     //profile form (new or edit Registration), include username
        break;
      case 'ChangeIds':
        setFormType('ChangeIds')  //change password
        break;
      case 'EditProfile':
        setFormType('SignUp')
        break;
      case 'ForgotPassword':
        setFormType('ForgotPassword')
        break;
      default:
        break;
    }
  };

  function openManagement(){
    mapData.changeRoute(3)
    onHide();
    history.push("/Management");
  }

  function showForm(type){
    if (type === 'SignIn') return <SignInForm nextShow={nextShow} setUser={(data) => setUser(data)}/>
    if (type === 'SignUp') return <SignUpForm nextShow={nextShow} />
    if (type === 'ChangeIds') return <ChangePassword nextShow={nextShow} showLevel={showLevel}/>
    if (type === 'EditProfile') return <SignUpForm nextShow={nextShow} showLevel={showLevel}/>
  }

  function modalTitle(type){
    if (type === 'SignIn') return 'Welcome to cooperate!'
    if (type === 'SignUp') return 'Welcome to cooperate!'
    if (type === 'ChangeIds') return 'Change password'
    if (type === 'EditProfile') return 'Edit profile'
    if (type === 'ForgotPassword') return 'Forgot password'
  }

  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={cssClass} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle(formType)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      <Card>
        
        <Card.Body>
          {formType==='SignIn' &&
          <>
            <h2 style={{textAlign: "center"}}>Share Your Ideas and Events!</h2>

            <p className="infoParagraph">
            Share Your ideas, events or fire souls on the map. 
            After publishing, You can edit yours targets here. 
            Please feel free to share Your information. 
            </p>
          </>
          }

          {formType==='ForgotPassword'
          ?<>
            <h3 style={{textAlign: "center"}}>Reset your password</h3>

            <p className="infoParagraph">
              Please, send us an email to <a href="mailto:villagesonmovego@gmail.com">villagesonmovego@gmail.com</a> using your user account's email address 
              and ask for reseting your password. It will take a couple of days. Then we will send you the new password. Thank you!
            </p>
          </>
          :showForm(formType)}
          
        </Card.Body>
      </Card>    
        
      </Modal.Body>

      <Modal.Footer>
        <img
          src="/images/logo.png"
          height="60"
          className="d-inline-block align-top rounded mr-2"
          alt="logo"
          style={{backgroundColor:'ghostwhite', padding: '4px'}}
        />
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>

    </Modal>
    </>
  );
  }