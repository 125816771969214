import React, { useState } from 'react';
import {
    Button,
    Card,
    Form,
    Alert
} from 'react-bootstrap';
import Dialog from '../../DialogModal';


export default function NewImages({onHide,images,edit,type}) {

  const [imagesData, setImagesData] = useState({
                                      images : images,
                                      file : null,
                                      load:  false,
                                      addLabel: 'Select image..',
                                      error: ''});

  /* ADD NEW IN ARRAY----------------------------------*/

  const fileSelected = async (event) => {
      let validType = ['image/jpeg','image/png','image/gif','image/tiff','image/gif','image/webp']
      let imgFile = event.target.files[0]
      let images = imagesData.images
      if (validType.includes(imgFile.type)){ //Is it images type valid?
        images.push(imgFile)
        setImagesData({
          images: images,
          file: null,
          load: false,
          addLabel: 'Select image..',
          error: ''     
        });  
        console.log('images set object: ',imagesData.images)
      }else{
        setImagesData({...imagesData,
          error:'Images type is invalid. Valid image types are jpg, jpeg, gif, tiff, png or webp.'})
      }
  }

  /* REMOVE -------------------------------------------*/

  function removeImage(index){
    let images=imagesData.images 
    images.splice(index,1)
    setImagesData({...imagesData,
                  images: images})
    hideDialog();
  }

  const [deleteDialog, setDeleteDialog] = useState({
    show:false,
    arrayId: null
  });

  function hideDialog(){
    setDeleteDialog({
      show:false,
      arrayId: null
    })
  }

  function showDialog(ind){
    setDeleteDialog({
      show:true,
      arrayId: ind
    })
  }

  function goNext(images){
    if (type!==2){ //not firesoul
      onHide(images)
    }else{
      if (images.length===0){
        setImagesData({...imagesData,
          error:'Image is required.'})
      }else{
        onHide(images)
      }
    }
  }

  return (
    <>
    {type===2
      ?<h4 className="mb-4">Add image *</h4>
      :<h4 className="mb-4">Add image (optional)</h4>
    }
    

    <Form >
      <Form.File 
        id="custom-file"
        label={imagesData.addLabel}
        onChange={fileSelected}
        custom
      />
    </Form>

    {imagesData.error!=='' &&
     <>
       <Alert variant='danger' className='mt-2' style={{width:'100%'}}>{imagesData.error}</Alert>
     </>
    }

    {imagesData.images.length!==0 &&
      <>
      <h4 className='mt-4'>Image(s)</h4>
        {imagesData.images.map((img, i) => 
          <Card className="imageCard" key={i}>
            <Card.Img 
              variant="top" 
              src={URL.createObjectURL(img)} 
              alt={img} />
            <button onClick={() => showDialog(i)} size="sm" className="imgDelete">Remove</button>
          </Card>
        )}
      </>
    }

  {deleteDialog && (
    <Dialog show={deleteDialog.show} onHide={() => hideDialog()} onYes={() => removeImage(deleteDialog.arrayId)} title='Remove image' message='Are you sure you want to remove this image?' style='dialogUnsaved' btext='Remove'/>
  )}

  <div className='mt-4 buttonRight'>
    {edit
      ?<Button variant="primary" onClick={() => onHide(imagesData.images)}>Close</Button>
      :<Button variant="primary" onClick={() => goNext(imagesData.images)}>Next</Button>
    }
  </div>

    </>
  );
  }