import React, { useState } from 'react';
import {
    Modal
} from 'react-bootstrap';
import Dialog from '../../DialogModal';
import EditContacts from './EditContacts';
import EditImages from './EditImages';
import EditLocation from './EditLocation';
import EditGroups from '../../new/newModals/NewGroups';
import EditText from './EditText';
import EditTime from './EditTime';

export default function EditModal({show,onHide,mark,title}) {

  let cssClass='modalHeader'

  const [dialog, setDialog] = useState({type: null,
                                        show: false})
    
  function hideDialog(){
  setDialog({type: null,
            show: false})
  }

  function showDialog(type){
  setDialog({type: type,
            show: true})
  }

  function showContent(name){
    //console.log('editModal content name: ',name)
    //console.log('editModal mark: ',mark)
    if (name==='Title and Contact Information') return <EditContacts mark={mark} showDialog={(type) =>showDialog(type)} onHide={onHide}/>
    if (name==='Classification')                return <EditGroups type={mark.type} markId={mark.id} markGroups={mark.groups} markPrimary={mark.primary_group} onHide={onHide} showDialog={(type) =>showDialog(type)}/>
    if (name==='Location')                      return <EditLocation id={mark.id} lat={mark.lat} lng={mark.lng} showDialog={(type) =>showDialog(type)} onHide={onHide} edit={true} newEdit={false}/>
    if (name==='Texts')                         return <EditText mark={mark} showDialog={(type) =>showDialog(type)} onHide={onHide} edit={true} newEdit={false}/>
    if (name==='Images')                        return <EditImages markId={mark.id} showDialog={(type) =>showDialog(type)} onHide={onHide} type={mark.type}/>
    if (name==='Frequency')                     return <EditTime onHide={onHide} timedata={makeTimeData(mark.start,mark.end)} markId={mark.id} showDialog={(type) =>showDialog(type)}/>
  }

  function makeTimeData(start,end){
    let startday = start
    let endday = end
    if (startday === '0000-00-00') startday=null
    if (endday === '0000-00-00') endday=null
    //console.log('makeTimeData start: ',startday)
    //console.log('makeTimeData end: ',endday)
    let data = null
    data = {timegroup:null,
            start: startday,
            end: endday}
    return data
  }

  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header className={cssClass} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {mark.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="editModal">

          {title==='Texts'
          ?showContent(title)
          :<><div className="CrudBox mt-2">
              <h4>{title}</h4>
              {showContent(title)}
            </div>
          </>}

        </div>

        {dialog.type==='unsaved' &&        
          <Dialog 
            show={dialog.show} 
            onHide={() => hideDialog()} 
            onYes={() => onHide(false)} 
            title='Confirm close' 
            message='You have unsaved changes that will be lost, if you decide to continue? Are you sure that you want to close this form?' 
            style='dialogUnsaved' 
            btext='Yes'/>
        }

        {dialog.type==='saved' &&        
          <Dialog 
            show={dialog.show} 
            onHide={() => hideDialog()} 
            onYes={() => onHide(true)} 
            title="Changes saved!" 
            message="All changes have been saved."
            style='dialogSaved' 
            btext=''/>
        }

      </Modal.Body>

    </Modal>
    </>
  );
}