import React, { useContext } from 'react'
import '../../App.css';
import MapMarks from './MapMarks'
import { 
  Map,
  TileLayer 
 } from "react-leaflet"
 import {
  Popover,
  OverlayTrigger,
  Button
} from 'react-bootstrap';
import MarkerClusterGroup from "react-leaflet-markercluster"; //https://github.com/leaflet/Leaflet.markercluster
import {MapContext} from '../../contexts/MapContext';
import {useSessionStorage} from '../../services/sessionStorageHook';
import SearchInfo from './SearchInfo';
import {urlImages} from '../checks/ChecksData';


export default function MapView({marks}) { 
  
  let userInit = ({
    id:0,
    firstname: '',
    lastname:'',
    organization:'',
    orgtype:'',
    email:'',
    username:'',
    level:null,    //admin=0, partner=1, private=2
    mapType: 1     // 0 = ideas, 1= events, 2 = firesouls, 3 = management
    });
  
  const [mapData] = useContext(MapContext);
  const [user] = useSessionStorage("vomgoUser",userInit)

  //console.log('MAPVIEW.JS type: ', user.mapType)

  const popover = (
    <Popover id="popoverInfo">
      <Popover.Title as="h3">
      {user.mapType===0 &&
        'Ideas'
      }
      {user.mapType===1 &&
        'Events'
      }
      {user.mapType===2 &&
        'Fire Souls'
      }
      </Popover.Title>
      <Popover.Content>
        {user.mapType===0 &&
        <>
          <p><strong>Ideas of sports activating us!  – find best ideas for different target groups on the map and get inspired.</strong></p>
          <p>Develop your own ideas and share them on the map from <i>Cooperate</i> menubutton – let’s support physical activity of our communities together. </p> 
        </>
        }
        {user.mapType===1 &&
        <>
          <p><strong>Events of sports activating us!  – find best recreational and competitive events of sport and physical activity for different target groups on the map and get inspired.</strong></p>
          <p>Share your own innovative events on the map from <i>Cooperate</i> menubutton – let’s learn together and promote physical activity of our communities. </p> 
        </>
        }
        {user.mapType===2 &&
        <>
          <p><strong>Fire Souls of rural sports activating us!  – find them on the map and read their amazing stories.</strong></p>
          <p>In small villages, suburbs and places the sport activities are often dependent on individual activators, local enthusiasts, 
            “Fire Souls” whose work supports the physical activity of the whole community.</p> 
          <p>In VOMGO -project we want to share stories of the Fire Souls, both from voluntary based sport clubs and small enterprises.</p>
        </>
        }
        <img
            src="/images/logo.png"
            height="60"
            className="d-inline-block align-top rounded mr-2"
            alt="logo"
            style={{backgroundColor:'ghostwhite', padding: '4px'}}
         /> 
      </Popover.Content>
    </Popover>
  );
  
  const infoPopover = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Button className="mapInfoButton"><img src={urlImages+'info_white.png'}/></Button>
    </OverlayTrigger>
  );

  function infoTitle(type){
    let text = ''
    switch (type) {
      case 0: text='ALL IDEAS'; break;
      case 1: text='ALL EVENTS'; break;
      case 2: text='FIRE SOULS'; break;
      default: return ''
    }
    return <div className="mapInfo2">
                <span className="mapInfoText2">{text}</span> {infoPopover()}
           </div>
  }

  return (
    <>
      {(mapData.searchGroups.length===0 && mapData.startDate===null && mapData.error===null)
        ? infoTitle(user.mapType)
        : <SearchInfo/>
      }

      <Map center={[54, 29]} zoom={3} minZoom={2} id="bigMap">
        <TileLayer
          noWrap={true}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors |<a href="https://www.villagesonmove.com/privacy-policy/" target="_blank"> Privacy Police <a/> | <a href="https://www.villagesonmove.com/privacy-policy/" target="_blank">Cookies<a/>'
        />

        <MarkerClusterGroup 
          showCoverageOnHover={false} //import: have to be false
          zoomToBoundsOnClick={true} 
          chunkedLoading={true} 
          maxClusterRadius={60}>

          {(mapData.searchGroups.length===0 && mapData.startDate===null)
              ? <MapMarks marks={marks}/>
              : <MapMarks marks={mapData.searchMarks}/>}
          
        </MarkerClusterGroup>
      </Map>
    </>
  );
}
