import React from 'react'
import {
  Image,
  Form
} from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {ideaGroups} from './ChecksData'
import {urlImages} from '../../components/checks/ChecksData';

export default function CheckIdea({type, checked, onChange}) {

    return (
        <>
         <div className="checks">
          {ideaGroups.map((group,idx) => (
            <div key={idx}>
            <h6 className='checkH6'>{group.groupname}</h6>
            <div className="searchGroup">
                
              <FormCheckLabel >
                  <Image src={urlImages+group.group1.icon} className="imgSearch" />
                  <Form.Check 
                    inline
                    custom 
                    label={group.group1.name} 
                    type={type} 
                    id={idx+10} 
                    className="searchCheck" 
                    value={group.group1.value} 
                    name={group.groupname} 
                    defaultChecked={checked[group.group1.value]} 
                    onChange={onChange} />
              </FormCheckLabel>
              <FormCheckLabel >
                  <Image src={urlImages+group.group2.icon} className="imgSearch" />
                  <Form.Check 
                    inline 
                    custom 
                    label={group.group2.name} 
                    type={type} 
                    id={idx+20} 
                    className="searchCheck" 
                    value={group.group2.value} 
                    name={group.groupname} 
                    defaultChecked={checked[group.group2.value]} 
                    onChange={onChange}/>
              </FormCheckLabel>
                
            </div>
            </div>
            ))}
        </div>
        </>
    )
}
