import React, {useState, useContext} from 'react';
import {
    Navbar
  } from 'react-bootstrap';
import MapNav from './MapNav'; 
import ManagementNav from './ManagementNav';
import InfoModal from '../info/InfoModal';
import LoginModal from '../login/LoginModal';
import SearchModal from '../search/SearchModal';
import {MapContext} from '../../contexts/MapContext';
import {useSessionStorage} from '../../services/sessionStorageHook';
import {useHistory} from "react-router-dom";

  const Navigation = ({path,getMarks}) => {

    //console.log('navigation path: ',path)

    let history = useHistory();

    const [mapData] = useContext(MapContext);

    let userInit = ({
                    id:0,
                    firstname: '',
                    lastname:'',
                    organization:'',
                    orgtype:'',
                    email:'',
                    username:'',
                    level:null,    //admin=0, partner=1, private=2
                    mapType: 1     // 0 = ideas, 1= events, 2 = firesouls, 3 = management
                    });

    const [user, setUser] = useSessionStorage("vomgoUser", userInit)

    //console.log('NAVIGATION user from sessionStorage: ', user)

    let firstTime = false;
    let modaltype = null;

    //user start to use application
    if (path==="/") {
      path="/Events"
      firstTime=true;
      modaltype="Info"
    }

    const [modalShow, setModalShow] = useState({ show: firstTime, type: modaltype, level: user.level})

    const handleSelect = (eventKey) => {
      if ((eventKey==="0")||(eventKey==="1")||(eventKey==="2")||(eventKey==="3")){
        setUser({...user,mapType:(parseInt(eventKey))})
        mapData.changeRoute(parseInt(eventKey))
      }else{
        showModal(eventKey,user.level)
      }
    }

    function showModal(type,level){
      setModalShow({show: true, 
                    type: type,
                    level: level})
      //console.log('showModal type: ',type,' and level: ',level)  
    }   

    function hideModal(){
      let modalname = modalShow.type
      let data = JSON.parse(window.sessionStorage.getItem('vomgoUser'))
      //console.log('hidemodal parse userdata: ',data)
      if (data!==null){
        if(data.id!==0){
          if ((modalname==='Login')||(modalname==='SignUp')||(modalname==='EditProfile')||(modalname==='ChangeIds')){ 
            setUser({...data})
          }
        }
      }
      setModalShow({show: false, 
                    type: null,
                    level: user.level})
      //console.log('Hidemodal level: ',user.level)
    }

    function login(data){
      setUser(data)
      mapData.changeRoute(3)
    }

    function logout(level){
      setUser(userInit)
      mapData.changeRoute(1)
      getMarks()
      if (level!==1) history.push("/Events");
    }

    function backMap(){
      let data = JSON.parse(window.sessionStorage.getItem('vomgoUser'))
      //console.log('backmap userdata: ',data)
      setUser({...data,mapType:1})
      mapData.changeRoute(1)
      getMarks()
    }

    function editProfile(level){
      //console.log('edit profile user: ',level)
      showModal('EditProfile',level)
    }

    function changeIds(level){
      //console.log('change ids user: ',level)
      showModal('ChangeIds',level)
    }

    function modal(type) {
      if (type === 'Info')    return <InfoModal show={modalShow.show} onHide={() => hideModal()} type={'info'}/>
      if (type === 'Login')  return <LoginModal show={modalShow.show} onHide={() => hideModal()} setUser={(data) => login(data)} formName={'SignIn'} />
      if (type === 'Search')  return <SearchModal show={modalShow.show} onHide={() => hideModal()} mapType={user.mapType} />
      if (type === 'ChangeIds')  return <LoginModal show={modalShow.show} onHide={() => hideModal()} setUser={(data) => login(data)} formName={'ChangeIds'} showLevel={modalShow.level}/>
      if (type === 'EditProfile')  return <LoginModal show={modalShow.show} onHide={() => hideModal()} setUser={(data) => login(data)} formName={'EditProfile'} showLevel={modalShow.level} />
      if (type === 'ResetPassword') return <InfoModal show={modalShow.show} onHide={() => hideModal()} type={'reset'}/>
    } 

    function showNavs(type,modal){
      if (type===3)   return <ManagementNav backMap = {() => backMap()} logout = {(level) => logout(level)} editProfile = {(level) => editProfile(level)} changeIds = {(level) => changeIds(level)} userLevel={user.level} resetPassword = {() => showModal('ResetPassword')}/>
      if (modal)      return <MapNav handleSelect={handleSelect} type={type} active={modalShow.type} userId={user.id}/>
      if (!modal)     return <MapNav handleSelect={handleSelect} type={type} active={type} userId={user.id}/>
    }

    return (
      <>
      <Navbar collapseOnSelect bg="light" variant="light" expand="lg">
        <Navbar.Brand href="#home">
          <img
            src="/images/logo.png"
            height="45"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>

        {user.id!==0 &&
          <Navbar.Text className="loginUser">
            Login as {user.username}
          </Navbar.Text>  
        }

        {showNavs(user.mapType,modalShow.show)}
  
      </Navbar>

      {modalShow.show &&
        modal(modalShow.type)
      }
      </>
    )
  }

  export default Navigation;