import React, { useState } from 'react';
import {
    Modal
} from 'react-bootstrap';
import Dialog from '../../DialogModal';
import Contacts from './NewContacts';
import Images from './NewImages';
import Location from '../../edit/editModals/EditLocation';
import Groups from './NewGroups';
import Text from '../../edit/editModals/EditText';
import Time from './NewTime';
import {once,regular} from '../../../checks/ChecksData'

export default function NewModal({show,onHide,mark,title}) {

  let cssClass='modalHeader'

  const [dialog, setDialog] = useState({type: null,
                                        show: false})
    
  function hideDialog(){
  setDialog({type: null,
            show: false})
  }

  function showDialog(type){
  setDialog({type: type,
            show: true})
  }

  function showContent(name){
    if (name==='Title and Contact Information') return <Contacts mark={mark} showDialog={(type) =>showDialog(type)} onHide={onHide} edit={true}/>
    if (name==='Classification')                return <Groups type={mark.type} markId={null} onHide={onHide}  markGroups={mark.groups} markPrimary={mark.primary_group} showDialog={(type) =>showDialog(type)}/>
    if (name==='Location')                      return <Location id={mark.id} lat={mark.lat} lng={mark.lng} showDialog={(type) =>showDialog(type)} onHide={onHide} edit={true} newEdit={true}/>
    if (name==='Texts')                         return <Text mark={mark} showDialog={(type) =>showDialog(type)} onHide={onHide} edit={true} newEdit={true}/>
    if (name==='Images')                        return <Images onHide={onHide} images={mark.images} edit={true} type={mark.type}/>
    if (name==='Frequency')                     return <Time onHide={onHide} timedata={makeTimedata(mark.groups,mark.startDate, mark.endDate)}/>
  }

  function makeTimedata(groups,start,end){
    let timegroup = null
    console.log('makeTimeData groups: ',groups)
    if (groups.includes(regular)) timegroup = regular
    if (groups.includes(once)) timegroup = once
    let timeobject = ({timegroup: timegroup,
                       start: start,
                       end: end})
    console.log('makeTimeData timeobject: ',timeobject)
    return timeobject
  }

  return (
    <>
    <Modal
      show={show}
      onHide={() => onHide('noChanges')}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header className={cssClass} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {mark.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="editModal">

        {title==='Texts'
        ?showContent(title)
        :<><div className="CrudBox mt-2">
            <h4>{title}</h4>
            {showContent(title)}
           </div>
        </>}

        </div>

        {dialog.type==='unsaved' &&        
          <Dialog 
            show={dialog.show} 
            onHide={() => hideDialog()} 
            onYes={() => onHide('noChanges')} 
            title='Confirm close' 
            message='You have unsaved changes that will be lost, if you decide to continue? Are you sure that you want to close this form?' 
            style='dialogUnsaved' 
            btext='Yes'/>
        }

        {dialog.type==='saved' &&        
          <Dialog 
            show={dialog.show} 
            onHide={() => hideDialog()} 
            onYes={() => onHide(true)} 
            title="Changes saved!" 
            message="All changes have been saved." 
            style='dialogSaved' 
            btext=''/>
        }

      </Modal.Body>

    </Modal>
    </>
  );
}