import React, { useState } from 'react';
import {
    Image,
    Form,
    Button
  } from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {once,regular,urlImages} from '../../../checks/ChecksData'
import DateRange from '../../../DateRange';

export default function NewTime({onHide,timedata}) {    //also edit new time

    const [time, setTime] = useState({timegroup:timedata.timegroup,
                                      start:timedata.start,
                                      end:timedata.end});

    function setDates(data){
        if (data.type==='start'){
            data.date===null ? setTime({...time,start:null}) : setTime({...time,start:parseDate(data.date)})
        }
        if (data.type==='end'){
            data.date===null ? setTime({...time,end:null}) : setTime({...time,end:parseDate(data.date)})
        }
      }
  
    function parseDate(d){
    let dd = d.getDate()
    let mm = d.getMonth()+1
    let yyyy = d.getFullYear()
    return yyyy+'-'+mm+'-'+dd
    }

    function onFormSubmit(event) {
        event.preventDefault();
        let end = time.end
        if (end===null) end=time.start
        let data = {timegroup: time.timegroup,
                    from: time.start,
                    to: end
                   }
        onHide(data); 
    }

    const handleOnChange = (event) => {
        let value = parseInt(event.target.value);
        console.log('time changed value: ',value)
        if (value===once.value){
            setTime({...time,timegroup:once})
        }else{
            setTime({...time,timegroup:regular})
        }
        
    }

    function showTime(group){
        return <>
            <h6 className='checkH6 mt-2 mb-2'>Time</h6>
            <DateRange setDateData={(data) =>setDates(data)} start={time.start} end={time.end}/>

            {group===regular 
                ?<p className='infoBox mb-4 mt-4 ml-2'>
                    Select the time-period, when regular events are held.<br/>
                    You will be able to write more details in next description part.
                </p>
                :<p className='infoBox mb-4 mt-4 ml-2'>
                    For one-day event you can select only the starting day (from).
                </p>
            } 
        </>
    }

    function checkData(){
        if (time.start===null) return false
        if ((time.timegroup===once)&&(time.start!==null)) return true
        if ((time.timegroup===regular)&&(time.end!==null)) return true
    }

    function closeOnClick(){
        onHide('noChanges')
    }

    function radioChecked(value){
        console.log('radicheck value: ',value)
        console.log('time timegroup: ',time.timegroup)
        if (timedata.timegroup===null) return false
        if (value===time.timegroup.value){
            return true
        }else{
            return false
        } 
    }

    return (
        <>
        <Form onSubmit={onFormSubmit}>
            <div className="checks">
                {timedata.timegroup===null &&  
                    <h5>Frequency</h5>
                }
                <div className="searchGroup">
                    <FormCheckLabel >
                        <Image src={urlImages+once.icon} className="imgSearch" />
                        <Form.Check 
                            inline 
                            custom 
                            label={once.groupname} 
                            type={'radio'} 
                            id={11} 
                            className="searchCheck" 
                            value={once.value} 
                            name={'time'} 
                            defaultChecked={radioChecked(once.value)} 
                            onChange={handleOnChange}/>
                    </FormCheckLabel>

                    <FormCheckLabel >
                        <Image src={urlImages+regular.icon} className="imgSearch" />
                        <Form.Check 
                            inline 
                            custom 
                            label={regular.groupname} 
                            type={'radio'} 
                            id={12} 
                            className="searchCheck" 
                            value={regular.value} 
                            name={'time'} 
                            defaultChecked={radioChecked(regular.value)} 
                            onChange={handleOnChange}/>
                    </FormCheckLabel>

                    {time.timegroup!==null &&
                        showTime(time.timegroup)
                    }

                </div>
            </div>

            {timedata.timegroup===null && (   //new
                <div className='mt-2 buttonRight'>
                    {checkData()
                        ?<Button variant="primary" type="submit">Next</Button>
                        :<Button variant="primary" type="submit" className='disabledButton' disabled>Next</Button>
                    }
                </div>
            )}

            {timedata.timegroup!==null && (   //edit new
                <div className='mt-2 buttonRight'>
                    {checkData()
                        ?<Button variant="success" type="submit" className='mr-2'>Save</Button>
                        :<Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button>
                    } 
                    <Button onClick={() => closeOnClick()}>Close</Button>
                </div>
            )}
        </Form>

      
        </>
    )
}