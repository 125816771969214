import React from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap';
import InfoCard from './InfoCard'
import ResetCard from './ResetCard'

export default function InfoModal({show,onHide,type}) {

  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='modalHeader' closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {type==='info'
            ?'Info'
            :'Reset'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      {type==='info'
        ?<InfoCard />
        :<ResetCard />}
        
      </Modal.Body>
      <Modal.Footer>
        <img
          src="/images/logo.png"
          height="60"
          className="d-inline-block align-top rounded mr-2"
          alt="logo"
          style={{backgroundColor:'ghostwhite', padding: '4px'}}
        />
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
  }
