import React, {useState, useContext,useEffect} from 'react';
import {
    Button
} from 'react-bootstrap';
import New from '../new/New';
import Edit from '../edit/Edit';
import ManagementCards from './ManagementCard'
import {CrudContext} from '../../../contexts/CrudContext';
import Dialog from '../DialogModal';
import Summary from './PrintSummary';
import OldEvents from './OldEvents';
import WaitingMarks from './WaitingMarks';
import DeleteUsers from './DeleteUsers';
import {useSessionStorage} from '../../../services/sessionStorageHook';
import { createBrowserHistory } from "history";



export default function Management() {

    const [crudData, setCrudData] = useContext(CrudContext);

    const [show, setShow]=useState({part: 'buttons',
                                    type: 1,
                                    dialog: false})
    
    const [user] = useSessionStorage("vomgoUser")

    //console.log('MANAGEMENT user from sessionStorage: ', user)  
    
    /*console.log('MANAGEMENT----------------------------------------') 
    console.log('crudData: ', crudData) 
    console.log('-------------------------------------------------') */

    //dialog texts
    let markType = ''       
    switch (show.type) {
        case 0:
          markType='idea'
          break;
        case 1:
          markType='event'
          break;
        case 2:
          markType='firesoul'
          break;    
        default:
          break;
      }                     
    let dialogTitle = `Confirm close`
    let dialogText = `You are closing the creation of a new  ${markType}. All new information you provide will be destroyed. Are you sure you want to do that?`
    let buttonText = 'Close' 

    const browsehistory = createBrowserHistory()
    let currentPathname = null
    let currentSearch = null

    useEffect(() => {  
        //console.log('Management.js useEffect')
        
        browsehistory.listen((newLocation, action) => {
          if (action === "PUSH") {
            if (
              newLocation.pathname !== currentPathname ||
              newLocation.search !== currentSearch
            ) {
              // Save new location
              currentPathname = newLocation.pathname;
              currentSearch = newLocation.search;
    
              // Clone location object and push it to history
              browsehistory.push({
                pathname: newLocation.pathname,
                search: newLocation.search
              });
            }
          } else {
            // Send user back if they try to navigate back
            browsehistory.go(1);
          }
        });
      }, []) 

    function showPart(part){
        //console.log('show: ',show)
        if (part==='buttons') return <ManagementCards userLevel={user.level} nextShow={(data) => setShow({...show,part:data.part,type:data.type})}/>
        if (part==='edit') return <Edit type={show.type} nextShow={(part) => setShow({...show,part:part})} userId={user.id}/>
        if (part==='new') return <New type={show.type} nextShow={(part) => setShow({...show,part:part})} user={user}/>
        if (part==='summary') return <Summary />
        if (part==='oldEvents') return <OldEvents />
        if (part==='waitingMarks') return <WaitingMarks />
        if (part==='deleteUsers') return <DeleteUsers />
    }

    function showTitle(part,type){
        if (part==='buttons') return 'Cooperate with us'
        if (part==='edit'){
            if (type===1) return 'Edit events'
            if (type===0) return 'Edit ideas'
            if (type===2) return 'Edit firesouls'
        }
        if (part==='new'){
            if (type===1) return 'New event'
            if (type===0) return 'New idea'
            if (type===2) return 'New firesoul'
        }
        if (part==='summary') return 'Summary'
        if (part==='oldEvents') return 'Past events'
        if (part==='editProfile') return 'Profile'
        if (part==='changeIds') return 'Login ids'
        if (part==='waitingMarks') return 'Accept new targets'
        if (part==='deleteUsers') return 'Delete users'
    }

    const handleClose = () => {
        if ((show.part==='edit')||(show.part==='summary')||(show.part==='oldEvents')||(show.part==='editProfile')||(show.part==='changeIds')||(show.part==='waitingMarks')||(show.part==='deleteUsers')){
            setCrudData({...crudData,
                type : show.type,
                marks : [],
                activeMark: null,
                status: false,
                keyword:'',
                error: ''}); 
                setShow({...show, part:'buttons'})
        }else{
            setShow({...show, dialog:true})
        }
        
      };

    return (
        <>
        <div className="crudPage">
            
            <div>
            {show.part!=='buttons' &&(
                <Button variant="warning" onClick={()=> handleClose()} className="closeX"> X </Button>
            )}
            <h3 className="crudH2 pt-2">{showTitle(show.part,show.type)}</h3>

            {showPart(show.part)}

            </div>

            {show.dialog &&(        
                <Dialog 
                    show={show.dialog} 
                    onHide={() => setShow({...show, dialog: false})} 
                    onYes={() => setShow({...show, part:'buttons',dialog: false})} 
                    title={dialogTitle} 
                    message={dialogText}
                    style='dialogUnsaved' 
                    btext={buttonText}
                    message2='' />
                )}
        </div>
        </>
    );
  }