import React from 'react'
import {
    Button,
    Row,
    Card,
    Col,
    Image
} from 'react-bootstrap';

export default function ManagementCard({userLevel,nextShow}) {

    function markersTitle(level){
        switch (level) {
            case 0: //admin
                return 'All targets on the map'
                break;
            case 1: //partner
                return 'Targets on the map'
                break;    
            default: //private user
                return 'Your targets on the map'
                break;
        }
    }

    return (
        <>
        <div className="CrudBox mt-2">

        <h4>{markersTitle(userLevel)}</h4>

            <Row className="justify-content-center">
                <Col md="auto" lg="auto" xs="auto">
                    <Card className='crudCard'>
                        <Card.Header as="h5">Create a new</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Publish target on the map.
                            </Card.Text>
                            <Button variant="primary" onClick={() => nextShow({part:'new',type:1})} className='crudButton'>Event</Button>
                            <Button variant="info" onClick={() => nextShow({part:'new',type:0})} className='crudButton'>Idea</Button>
                            <Button variant="secondary" onClick={() => nextShow({part:'new',type:2})} className='crudButton'>Firesoul</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="auto" lg="auto" xs="auto">
                    <Card className='crudCard'>
                        <Card.Header as="h5">Edit existing targets</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Update, delete, archive or publish.
                            </Card.Text>
                            <Button variant="primary" onClick={() => nextShow({part:'edit',type:1})} className='crudButton'>Events</Button>
                            <Button variant="info" onClick={() => nextShow({part:'edit',type:0})} className='crudButton'>Ideas</Button>
                            <Button variant="secondary" onClick={() => nextShow({part:'edit',type:2})} className='crudButton'>Firesouls</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            {userLevel===0 &&
            <>
            <h4 className='pt-2'>Application management</h4>
              <Row className="justify-content-center">
                <Col md="auto" lg="auto" xs="auto">
                    <Card className='crudCard'>
                        <Card.Header as="h5">Admin's tasks</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Approve new targets. Look at the summary report and past events. Control users.
                            </Card.Text>
                            <Button variant="success" onClick={() => nextShow({part:'waitingMarks',type:null})} className='crudButton'>Accept new targets</Button>
                            <Button variant="warning" onClick={() => nextShow({part:'summary',type:null})} className='crudButton'>Show a summary</Button>
                            <Button variant="secondary" onClick={() => nextShow({part:'oldEvents',type:null})} className='crudButton'>Show past events</Button>
                            <Button variant="danger" onClick={() => nextShow({part:'deleteUsers',type:null})} className='crudButton'>Delete users</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="auto" lg="auto" xs="auto">
                    <Card border='light' className='crudImageCard'>
                        <Card.Body>
                            <Image src='/images/logo1.png' className='crudImage'/>
                        </Card.Body>
                    </Card>
                </Col>
              </Row> 
            </>
            }
                                 
        </div>
        </>
    );
  }