import React, {useState} from 'react'
import {
    Badge,
    Alert
} from 'react-bootstrap';
import Contacts from './newModals/NewContacts';
import EditText from '../edit/editModals/EditText';
import Location from '../edit/editModals/EditLocation';
import Images from './newModals/NewImages';
import Groups from './newModals/NewGroups';
import Time from './newModals/NewTime';
import PreviewMark from './newModals/PreviewMark';
import Dialog from '../DialogModal';
import dbService from '../../../services/db';
import Guide from './newModals/NewGuide';
import Spinner from '../../LoadSpinner';

export default function New({type, nextShow, user}) {

  //console.log('New.js user: ',user)

  let primary = null
  let initData = {author:'',
                  orgname:'',
                  contact:''}
  initVariables()

  function initVariables(){
    if (type===2) primary = 47          //if marktype is firesoul
    if (user.email!==null) initData.contact=user.email
    if (user.level!==1){                //if user not partner
      if (user.firstname!==null) initData.author=user.firstname
      if (user.lastname!==null) initData.author=initData.author+' '+user.lastname
      if (user.organization!==null) initData.orgname=user.organization
    }
  }

  const [newMark,setNewMark] = useState({
                 lat:0,
                 lng:0,
                 title:'',
                 primary_group:primary,
                 startDate:null,
                 endDate:null,
                 link:'',
                 author:initData.author,
                 orgname:initData.orgname,
                 contact:initData.contact,
                 status:0,   //archived
                 type:type,
                 groups: [],
                 text1:'',  //english
                 text2:'',  //other language
                 images:[],
                 nextPart:10,
                 userId:user.id,
                 type2:type, //for firesouls video (2) or image (4) types
                 loading: null,
                 info: null,
                 error:null
                })

  const [dialog, setDialog] = useState({show:false,
                                        title:'',
                                        text1:'',
                                        text2:'',
                                        btext:'',
                                        mark: null,
                                        operation: ''})

  let typeName = ''; 
  switch (type) {
    case 0:
      typeName='idea'
      break;
    case 1:
      typeName='event'
      break;
    case 2:
      typeName='firesoul'
      break;    
    default:
      break;
  }



  const dialogShow = (mark,operation) => {
    let dialogTitle = ""
    let dialogText1 = ""
    let dialogText2 = ""
    let buttonText = ''

    switch (operation) {
      case 'publish':
        dialogTitle = `Publish ${typeName}`
        dialogText1 = `Are you sure that you want publish: ${mark.title} ?`        
        buttonText = 'Publish'          
        break;
      case 'delete':
        dialogTitle = `Delete a new ${typeName}`
        dialogText1 = `Are you sure that you want delete: ${mark.title} ?`
        buttonText = 'Delete'      
        break;
      default:
        break;
    }

    setDialog({ show:true,
                title:dialogTitle,
                text1:dialogText1,
                text2:dialogText2,
                btext:buttonText,
                mark: mark,
                operation: operation})
  }

  const dialogClose = (succeedDialog) => {
    if (succeedDialog){
      nextShow('buttons')
    }else{
      let operation=dialog.operation
      let mark = dialog.mark

      setDialog({ show:false,
                  title:'',
                  text1:'',
                  text2:'',
                  btext:'',
                  id:null,
                  mark: null,
                  operation: ''})

      switch (operation) {
        case 'publish':
          if (mark.userId===1||mark.userId===2){               //admin or partner
            type===2?addNewFiresoul(mark,1):addNewMark(mark,1) //publish in map
          }else{
            type===2?addNewFiresoul(mark,2):addNewMark(mark,2) //awaiting approval
          }
          break;
        case 'delete':
          nextShow('buttons')
          break;
        case 'close':
          nextShow('buttons')
          break;
        default:
          console.log('Error when dialogClose, switch operation')
          break;
      }
    }
  }

  function capitalizeFirstLetter(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  function addNewMark(mark,status){
    let userId =mark.userId
    if (userId===1) userId=2 //admins mark userId same as partner
    let markData = {lat: mark.lat, lng: mark.lng, title: mark.title, primary_group: mark.primary_group.id, start:mark.startDate, end:mark.endDate, link: mark.link, author: mark.author, orgname: mark.orgname, contact: mark.contact, status: status, type: mark.type, userId:userId, text1: mark.text1, text2: mark.text2}
    let groups = []
    mark.groups.forEach(group => {
      groups.push(group.value)
    });
    setNewMark({...newMark,
                  loading:'Publishing is in progress',
                  info:null,
                  error:null}) 
    //setTimeout(() => {
     dbService
      .postMark(markData,groups)
      .then(response => {
        //console.log('Add new mark data: ', response.data[0][0].idMark)

        let id = response.data[0][0].idMark

        let dialogText = ""
        if (mark.userId===1||mark.userId===2){
          dialogText = `The ${typeName} has been published, now you can look at it on the map and also edit it on the Edit existing: ${typeName}s.`
        }else{
          dialogText = `The ${typeName} has been send to the administrator. She will first check this ${typeName}. It will take a couple of days. After then you can look at your target on the map and also edit it by choosing Edit existing: ${typeName}s.`
        }

        addImgFiles(mark.images,id)
          .then(() => {
            //console.log('All images added2')
            setNewMark({...newMark,
                          loading:null,
                          info:dialogText,
                          error:null})
          })
          .catch(err => {
            //console.log('addImg error: '+err);
            let infoText = ''
            if (mark.userId===1||mark.userId===2){
              infoText = `The ${typeName} details have been published successfully, but unfortunately the images failed to upload. Please try adding images via editing (Edit existing targets -> `+capitalizeFirstLetter(typeName)+`s).`
            }else{
              infoText = `The ${typeName} has been send to the administrator, but unfortunately the images failed to upload. Administrator will first check this ${typeName}. It will take a couple of days. After then you can try adding images via editing (Edit existing targets -> `+capitalizeFirstLetter(typeName)+`s).`
            }
            setNewMark({...newMark,
                           loading:null,
                           info:infoText,
                           error:'images error'})
          })
      })  
     .catch(err => {
      /*let message = 'Add new mark DB: ';
      if (err.response) {
          message=message+'client received an error response (5xx, 4xx) '+err;
      } else if (err.request) {
          message=message+'client never received a response, or request never left '+err;
      } else {
          message=message+'unexpected error';
      }
      console.log('error: '+message)*/
      setNewMark({...newMark,
                     loading:null,
                     info:null,
                     error:`Failed to publish the ${typeName}. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.`})
     })
   //}, 1000); 
  }

  function addImgFiles(images,id) { 
    return new Promise((resolve,reject) => {
        //console.log('addImgFiles:', images,' ,',id) 
        Promise.all(images.map(img => dbService.postMarkImage(img,id)))
               .then(()=>{
                  //console.log('all images added1');
                  resolve();})
               .catch(err => {
                  //console.log('addImg error: '+err);
                  reject(new Error('addImg error'));
              }) 
    });
  }

  function addNewFiresoul(mark,status){
    let userId =mark.userId
    if (userId===1) userId=2 //admins mark userId same as partner
    let markData = {lat: mark.lat, lng: mark.lng, title: mark.title, primary_group: mark.primary_group, link: mark.link, author: mark.author, orgname: mark.orgname, contact: mark.contact, status: status, type: mark.type, userId:userId, text1: mark.text1, text2: mark.text2}
    
    setNewMark({...newMark,
      loading:'Publishing is in progress',
      info:null,
      error:null}) 

    //setTimeout(() => {
      dbService
        .postMark(markData,null)
        .then(response => {
          let id = response.data[0][0].idMark
          console.log('Add new firesoul: ', id)

          let dialogText = ""
          if (mark.userId===1||mark.userId===2){
            dialogText = `The firesoul has been published, now you can look at it on the map and also edit it on the Edit existing targets -> Firesouls.`
          }else{
            dialogText = `The firesoul has been send to the administrator. She will first check this firesoul. It will take a couple of days. After then you can look at your target on the map and also edit it by choosing Edit existing targets -> Firesouls.`
          }

          if (mark.type2===4){ //imageFiresoul
            addImgFiles(mark.images,id)
              .then(() => {
                console.log('All firesoul images added2')
                setNewMark({...newMark,
                              loading:null,
                              info:dialogText,
                              error:null})
              })
              .catch(err => {
                console.log('addImg error: '+err);
                let infoText = ''
                if (mark.userId===1||mark.userId===2){
                  infoText = `The firesoul details have been published successfully, but unfortunately the images failed to upload. Please try adding images via editing (Edit existing targets -> Firesouls).`
                }else{
                  infoText = `The firesoul has been send to the administrator, but unfortunately the images failed to upload. Administrator will first check this firesoul. It will take a couple of days. After then you can try adding images via editing (Edit existing targets -> Firesouls).`
                }
                setNewMark({...newMark,
                               loading:null,
                               info:infoText,
                               error:'images error'})
              }) 
          }else{      //video firesoul
            setNewMark({...newMark,
                           loading:null,
                           info:dialogText,
                           error:null})
          }
        })
        .catch(err => {
          /*let message = 'Add new firesoul DB: ';
          if (err.response) {
              message=message+'client received an error response (5xx, 4xx) '+err;
          } else if (err.request) {
              message=message+'client never received a response, or request never left '+err;
          } else {
              message=message+'unexpected error';
          }
          console.log('error: '+message)*/
          setNewMark({...newMark,
                         loading:null,
                         info:null,
                         error:`Failed to publish the firesoul. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.`})
          }) 
    //}, 1000); 
  }

  function nextPart(){
    if (newMark.type2===1){ //new event
      if (newMark.nextPart===1) return 7 //jump to time
      if (newMark.nextPart===7) return 2 //jump to description       
    }
    if (newMark.type2===2){ //new videofiresoul
      if (newMark.nextPart===2) return 5 //jump over images and groups
    }
    if (newMark.type2===4){ //new imagefiresoul
      if (newMark.nextPart===3) return 5 //jump over images groups
    }
    return newMark.nextPart+1            //else (idea) follow the order
  }

  function savePart(data){
    //console.log('savePart: ',data)
    console.log('type2: ',newMark.type2)
    switch (newMark.nextPart) {
      case 1:
        setNewMark({...newMark,
                    title: data.title,
                    author: data.author,
                    contact: data.contact,
                    link: data.link,
                    orgname: data.orgname,
                    nextPart:nextPart()
        })
        break;
      case 2:
        setNewMark({...newMark,
                    text1: data[0],
                    text2: data[1],
                    nextPart:nextPart()
        })
        break;
      case 3:
        setNewMark({...newMark,
                    images: data,
                    nextPart:nextPart()
        })
        break;
      case 4:
        if ((type===1) && (newMark.groups.length>0)){
            data.groups.push(newMark.groups[0]) //add timegroup
        }
        setNewMark({...newMark,
                    groups: data.groups,
                    primary_group:data.primary,
                    nextPart:nextPart()
        })
        break;
      case 5:
        setNewMark({...newMark,
                  lat: data[0],
                  lng:data[1],
                  nextPart: nextPart()
        })
        break;
      case 7:
        let timegroup = [data.timegroup]
        setNewMark({...newMark,
                  startDate:data.from,
                  endDate:data.to,
                  groups:timegroup,
                  nextPart: nextPart()
        })
        break;
      case 10:
        let type2 = type
        if (data==='imageFiresoul') type2=4
        setNewMark({...newMark,
                      nextPart: 1,
                      type2: type2
        })
        break;
      default:
        break;
    }
  }

  function showPart(part){
    if (part===1) return <Contacts mark={newMark} onHide={(data) =>savePart(data)} edit={false}/>                
    if (part===2) return <EditText mark={newMark} onHide={(data) =>savePart(data)} edit={false} newEdit={false}/>
    if (part===3) return <Images onHide={(data) =>savePart(data)} images={[]} edit={false} type={type}/>
    if (part===4) return <Groups type={newMark.type} markId={null} onHide={(data) =>savePart(data)} markGroups={newMark.groups} markPrimary={null}/>
    if (part===5) return <Location id={newMark.id} lat={53} lng={17} onHide={(data) =>savePart(data)} edit={false} newEdit={false}/>
    if (part===6) return <PreviewMark newMark={newMark} showDialog={dialogShow}/>
    if (part===7) return <Time onHide={(data) =>savePart(data)} timedata={{timegroup:null,start:null,end:null}}/>
    if (part===10) return <Guide onHide={(data) =>savePart(data)} type={type}/>
  }

  function showPills(){
    if (newMark.type2===2){ //videoFiresoul
      return <>
        {newMark.nextPart===6
          ?<Badge pill variant="primary" className="badge">4</Badge>
          :<Badge pill variant="secondary" className="badge">4</Badge>
        }
        {newMark.nextPart===5
          ?<Badge pill variant="primary" className="badge">3</Badge>
          :<Badge pill variant="secondary" className="badge">3</Badge>
        }
        {newMark.nextPart===3
          ?<Badge pill variant="primary" className="badge">2</Badge>
          :<Badge pill variant="secondary" className="badge">2</Badge>
        }     
        {newMark.nextPart===1
          ?<Badge pill variant="primary" className="badge">1</Badge>
          :<Badge pill variant="secondary" className="badge">1</Badge>
        }
      </>
    }
    if (newMark.type2===4){ //imageFiresoul
      return <>
        {newMark.nextPart===6
          ?<Badge pill variant="primary" className="badge">5</Badge>
          :<Badge pill variant="secondary" className="badge">5</Badge>
        }
        {newMark.nextPart===5
          ?<Badge pill variant="primary" className="badge">4</Badge>
          :<Badge pill variant="secondary" className="badge">4</Badge>
        }
        {newMark.nextPart===3
          ?<Badge pill variant="primary" className="badge">3</Badge>
          :<Badge pill variant="secondary" className="badge">3</Badge>
        }
        {newMark.nextPart===2
          ?<Badge pill variant="primary" className="badge">2</Badge>
          :<Badge pill variant="secondary" className="badge">2</Badge>
        }      
        {newMark.nextPart===1
          ?<Badge pill variant="primary" className="badge">1</Badge>
          :<Badge pill variant="secondary" className="badge">1</Badge>
        }
      </>
    }
    if (type===1){ //event
      return <>
        {newMark.nextPart===6
          ?<Badge pill variant="primary" className="badge">7</Badge>
          :<Badge pill variant="secondary" className="badge">7</Badge>
        }
        {newMark.nextPart===5
          ?<Badge pill variant="primary" className="badge">6</Badge>
          :<Badge pill variant="secondary" className="badge">6</Badge>
        }
        {newMark.nextPart===4
          ?<Badge pill variant="primary" className="badge">5</Badge>
          :<Badge pill variant="secondary" className="badge">5</Badge>
        }
        {newMark.nextPart===3
          ?<Badge pill variant="primary" className="badge">4</Badge>
          :<Badge pill variant="secondary" className="badge">4</Badge>
        }
        {newMark.nextPart===2
          ?<Badge pill variant="primary" className="badge">3</Badge>
          :<Badge pill variant="secondary" className="badge">3</Badge>
        }      
        {newMark.nextPart===7
          ?<Badge pill variant="primary" className="badge">2</Badge>
          :<Badge pill variant="secondary" className="badge">2</Badge>
        }
        {newMark.nextPart===1
          ?<Badge pill variant="primary" className="badge">1</Badge>
          :<Badge pill variant="secondary" className="badge">1</Badge>
        }
      </>
    }
    if (type===0){ //idea
      return <>
        {newMark.nextPart===6
          ?<Badge pill variant="primary" className="badge">6</Badge>
          :<Badge pill variant="secondary" className="badge">6</Badge>
        }
        {newMark.nextPart===5
          ?<Badge pill variant="primary" className="badge">5</Badge>
          :<Badge pill variant="secondary" className="badge">5</Badge>
        }
        {newMark.nextPart===4
          ?<Badge pill variant="primary" className="badge">4</Badge>
          :<Badge pill variant="secondary" className="badge">4</Badge>
        }
        {newMark.nextPart===3
          ?<Badge pill variant="primary" className="badge">3</Badge>
          :<Badge pill variant="secondary" className="badge">3</Badge>
        }
        {newMark.nextPart===2
          ?<Badge pill variant="primary" className="badge">2</Badge>
          :<Badge pill variant="secondary" className="badge">2</Badge>
        }      
        {newMark.nextPart===1
          ?<Badge pill variant="primary" className="badge">1</Badge>
          :<Badge pill variant="secondary" className="badge">1</Badge>
        }
      </>
    }
  }

  return (
      <div className="CrudBox mt-2">
        {newMark.loading!==null
        ? <Spinner title={newMark.loading} />
        :<>
          {showPills()}
          <br/>

          {(newMark.error!==null&&newMark.info===null) &&   
            <Alert variant='danger' className='m-4'>
                {newMark.error}
            </Alert>
          }

          {showPart(newMark.nextPart)}
          </>
        }

        {dialog.show &&(        
          <Dialog 
            show={dialog.show} 
            onHide={() => setDialog({show:false,title:'',text1:'',text2:'',btext:'',id:null})} 
            onYes={() => dialogClose(false)} 
            title={dialog.title} 
            message={dialog.text1}
            style='dialogUnsaved' 
            btext={dialog.btext}
            message2={dialog.text2} />
          )}

        {(newMark.info!==null&&newMark.error===null) &&(        
          <Dialog 
            show={true} 
            onHide={() => setDialog({show:false,title:'',text1:'',text2:'',btext:'',id:null})} 
            onYes={() => dialogClose(true)} 
            title={'Publishing succeed'} 
            message={newMark.info}
            style='dialogSaved' 
            btext={''}
            message2={''} />
          )}

          {(newMark.info!==null&&newMark.error!==null) &&(        
          <Dialog 
            show={true} 
            onHide={() => setDialog({show:false,title:'',text1:'',text2:'',btext:'',id:null})} 
            onYes={() => dialogClose(true)} 
            title={'Publishing had problem'} 
            message={newMark.info}
            style='dialogWarning' 
            btext={''}
            message2={''} />
          )}

      </div> 
    );
  }