//this context is only for search!!!
import React, {useState, createContext} from 'react';

export const MapContext = createContext();

export const MapProvider = props => {
    const [mapData, setMapData] = useState({
                            type: 1,                // 1 = events, 0 = ideas, 2 = firesouls, 3 = management
                            searchGroups: [],       //(id, groupname, icon)
                            searchMarks:[],
                            startDate:null,         //by search
                            endDate:null,           //by search
                            loading: null,
                            error:null,
                            changeRoute : (type) => {changeRoute(type)}
                            });

    const changeRoute = (type) => {
        setMapData({...mapData,
                    type: type,             // 1 = events, 0 = ideas, 2 = firesouls, 3 = management
                    searchGroups: [],       //(id, groupname, icon)
                    searchMarks:[],
                    startDate:null,         //by search
                    endDate:null,           //by search
                    loading: null,
                    error: null
                    })
    }

    return(
        <MapContext.Provider value={[mapData,setMapData]}>
            {props.children}
        </MapContext.Provider>
    )

};