import React, {useState, useEffect} from 'react';
import {
    Row,
    Col
  } from 'react-bootstrap';
import DatePicker from 'react-datepicker'; //https://www.npmjs.com/package/react-datepicker  https://reactdatepicker.com/

import "react-datepicker/dist/react-datepicker.css";

export default function DateRange({setDateData,start,end}) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (start!=null){
            let s = new Date(start);
            setStartDate(s)
            console.log('start init: ',s)
        }
    
        if (end!=null){
            let e = new Date(end);
            setEndDate(e)
            console.log('end init: ',e)
        }
    },[])

    function changeStartDate(d){
        let data = {date: d, type: 'start'}
        setStartDate(d)
        setDateData(data)
    }

    function changeEndDate(d){
        let data = {date: d, type: 'end'}
        setEndDate(d)
        setDateData(data)
    }

    function disablePicker(show){
        if (show===null) return true
        return false
    }

    function placeholder(show){
        if (show===null) return "Select first From"
        return "Click to select a date"
    }

    return (
        <>
        <Row className='ml-2'>
            <Col sm={2}>
                From:
            </Col>
            <Col sm={10}>
                <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                onChange={date => changeStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                isClearable
                placeholderText="Click to select a date"
                />
            </Col>
        </Row>

        <Row  className='ml-2 pt-2'>
            <Col sm={2}>
                To:
            </Col>
            <Col sm={10}>
                <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={endDate}
                disabled={disablePicker(startDate)}
                onChange={date => changeEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isClearable
                placeholderText={placeholder(startDate)}
                />
            </Col>
        </Row>
        </>
    )
}