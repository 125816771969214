import React from 'react'
import {
  Image,
  Form
} from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {eventGroups} from './ChecksData'
import {urlImages} from '../../components/checks/ChecksData';

export default function CheckEvent({type, checked, onChange,newEvent}) {

    function groupName(name){
      if (newEvent===true && (name==='Price' || name==='Frequency')) return name+'*'
      return name
    }

    return (
        <>
         <div className="checks">
          {eventGroups.map((group,idx) => (
            <div key={idx}>
            {(newEvent!==true || group.groupname!=='Frequency') &&
              <>
              <h6 className='checkH6'>
                {groupName(group.groupname)}
              </h6>
              <div className="searchGroup">
                <FormCheckLabel >
                    <Image src={urlImages+group.group1.icon} className="imgSearch" />
                    <Form.Check 
                      inline 
                      custom 
                      label={group.group1.name} 
                      type={type} id={idx+10} 
                      className="searchCheck" 
                      value={group.group1.value} 
                      name={group.groupname} 
                      defaultChecked={checked[group.group1.value]} 
                      onChange={onChange}/>
                </FormCheckLabel>
                <FormCheckLabel >
                    <Image src={urlImages+group.group2.icon} className="imgSearch" />
                    <Form.Check 
                      inline 
                      custom 
                      label={group.group2.name} 
                      type={type} id={idx+20} 
                      className="searchCheck" 
                      value={group.group2.value} 
                      name={group.groupname} 
                      defaultChecked={checked[group.group2.value]} 
                      onChange={onChange}/>
                </FormCheckLabel>
                {group.groupname==='Skills level' &&
                  <FormCheckLabel >
                  <Image src={urlImages+group.group3.icon} className="imgSearch" />
                  <Form.Check 
                    inline 
                    custom 
                    label={group.group3.name} 
                    type={type} id={idx+40} 
                    className="searchCheck" 
                    value={group.group3.value} 
                    name={group.groupname} 
                    defaultChecked={checked[group.group3.value]} 
                    onChange={onChange}/>
                  </FormCheckLabel>              
                }
                  
              </div>
              </>
            }
            </div>
            ))}
        </div>
        </>
    )
}
