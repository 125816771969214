import React, {useContext} from 'react';
import {
  Image,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import {MapContext} from '../../contexts/MapContext';
import {allGroups, urlImages} from '../checks/ChecksData';

export default function SearchInfo() {
  const [mapData] = useContext(MapContext);

  let groups = makeGroups()

  function makeGroups(){
    let searchgroups = []
    let allgroups = allGroups()
    mapData.searchGroups.forEach(g => {
      searchgroups.push({name:allgroups[g].groupname,icon:allgroups[g].icon})
    });
    return searchgroups
  }

  function renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {props}
      </Tooltip>
    );
  }

  function eventTime (){
    console.log('eventTime start: ', mapData.startDate,' end: ', mapData.endDate)

    let startDate = new Date(mapData.startDate)
    let start = startDate.toLocaleDateString()

    if (mapData.endDate!==null){
      let endDate = new Date(mapData.endDate)
      let end = endDate.toLocaleDateString()
      if (start===end){
        if (start!=='Invalid Date') return start
      } else {
        return start + '-' + end
      }
    }else{
      if (start!=='Invalid Date') return '>'+start
    }
  }

  function searchTitle(type){
    switch (type) {
      case 0:
        return 'Ideas with: '; 
        break;
      case 1:
        return 'Events with: '; 
        break;    
      default:
        break;
    }
  }

  return (
    <>
    <div className="mapInfo2">
      {mapData.error===null &&
        <>
        {mapData.searchMarks.length!==0 
          ?<span >{searchTitle(mapData.type)}</span>
          :<span>{'No result with: '}</span>
        }

        { groups.map((group, i) => 
            <OverlayTrigger
            key={i} 
            placement="bottom"
            delay={{ show: 100, hide: 100 }}
            overlay={renderTooltip(group.name)}
            >
              <Image 
                key={i} 
                src={`${urlImages}${group.icon}`} 
                className='groupIcon hoverinfo'
                alt={'icon'}/>
            </OverlayTrigger>
        )}

        {(mapData.type===1 && mapData.startDate!==null) && 
            eventTime()
        }
        </>
      }

      {mapData.error!==null &&
        <span>{`Search failed: ${mapData.error}`}</span>
      }

    </div>
    </>
  )
  }