import React, {useState, useContext} from 'react';
import {
    Button,
    Dropdown,
    DropdownButton,
    ButtonGroup,
    Row,
    Col
} from 'react-bootstrap';
import MarkModal from '../../mark/MarkModal';
import EditModal from './editModals/EditModal';
import {CrudContext} from '../../../contexts/CrudContext';


export default function MarkList({userId, marks, status, showDialog}) {
    const [crudData] = useContext(CrudContext);
    const [markModalShow, setMarkModalShow] = useState({show:false, mark:null});
    const [editModalShow, setEditModalShow] = useState({show:false, type:null, mark:null});

    function hideMarkModal(){
      setMarkModalShow({show:false,mark:null});
    }
  
    function showMarkModal(mark){
      setMarkModalShow({show:true,mark:mark});
    }

    function hideEditModal(saved){
      let update = saved
      console.log('Marklist saved: ',saved)
      if (update==='noChanges') update=false
      if ((update) && ((editModalShow.type==='Contacts')||(editModalShow.type==='Location')||(editModalShow.type==='Groups')||(editModalShow.type==='Frequency'))){
        crudData.fetchMarks(userId,crudData.type,crudData.status,crudData.keyword,'get')
      }
      setEditModalShow({show:false,type:null,mark:null});
    }

    function showEditModal(mark,type){
        setEditModalShow({show:true,type:type,mark:mark});
    }

    function authorText(mark){
        if (mark.author!=='') return mark.author;
        if (mark.orgname!=='') return mark.orgname;
        if (mark.contact!==''){
          if (mark.contact.length>24){
            return mark.contact.slice(0,24)+'...'
          }else{
            return mark.contact;
          } 
        }
        return 'unknow';
    }

    function modal(type) {
      let title = ''

      if (type === 'Contacts')  title='Title and Contact Information';
      if (type === 'Images')    title='Images';
      if (type === 'Groups')    title='Classification';
      if (type === 'Location')  title='Location';
      if (type === 'Texts')     title='Texts';
      if (type === 'Frequency') title='Frequency';
    
      return <EditModal show={editModalShow.show} onHide={(saved) => hideEditModal(saved)}  mark={editModalShow.mark} title={title}/>
    } 

    return (
        <>
            {marks.map((mark, i) => 
            <div key={i}>            
              <Row className='mb-2'>
                <Col md="auto">
                  <strong>{authorText(mark)}</strong> :  <i>" {mark.title}"</i>
                </Col>
              </Row>
              <Row>
                <Col className='mb-2'>
                  <DropdownButton id="dropdown-item-button" title="Edit" className='dropdown'>
                    <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Contacts')}className='dropButton'>Title and contacts</Dropdown.Item>

                    {mark.type===1 && //only event
                      <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Frequency')} className='dropButton'>Frequency</Dropdown.Item>
                    }

                    <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Texts')} className='dropButton'>Descriptions</Dropdown.Item>

                    {mark.type!==2 && //not firesoul
                      <>
                      <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Images')} className='dropButton'>Images</Dropdown.Item>
                      <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Groups')} className='dropButton'>Classification</Dropdown.Item>
                      </>
                    }

                    {(mark.type===2&&mark.link==='') && //imageFiresoul
                      <>
                      <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Images')} className='dropButton'>Images</Dropdown.Item>
                      </>
                    }
                    
                    <Dropdown.Item as="button" onClick={() => showEditModal(mark,'Location')} className='dropButton'>Location</Dropdown.Item>
                  </DropdownButton>
                </Col>
                <Col>
                  <ButtonGroup aria-label="Action group" style={{float: "right"}}>
                    <Button variant="success" onClick={() => showMarkModal(mark)}>Show</Button>
                    {(status===1) && (  //published
                      <Button variant="warning" onClick={() => showDialog(mark.id,mark.title,'archive')}>Archive</Button>
                    )}
                    {(status===0) && (  //archived
                      <Button variant="primary" onClick={() => showDialog(mark.id,mark.title,'publish')}>Publish</Button>
                    )}
                    <Button variant="danger" onClick={() => showDialog(mark.id,mark.title,'delete')}>Delete</Button>
                  </ButtonGroup>
                  <div style={{clear: "both"}}></div>  
                </Col>
              </Row>
              <hr/>
              </div>
            )}


          { markModalShow.show && (
            <MarkModal show={true} onHide={() => hideMarkModal()} mark={markModalShow.mark} />
          )}

          { editModalShow.show && (
            modal(editModalShow.type)

          )}
        </>
);
}
