import React, {useEffect,useState} from 'react'
import {
  Button,
  ListGroup,
  ButtonGroup
} from 'react-bootstrap';
import dbService from '../../../services/db';
import MarkModal from '../../mark/MarkModal';
import Dialog from '../DialogModal';
import Spinner from '../../LoadSpinner';

//accept new targets
export default function WaitingMarks() {
    const [marks, setMarks] = useState({marks:null,
                                        error:null,
                                        loading:'Loading waiting targets'})
    const [modals, setModals] = useState({markModal:false,
                                          mark: null,
                                          dialogModal:false,
                                          title:'',
                                          text1:'',
                                          text2:'',
                                          btext:'',
                                          id: null,
                                          type:'',
                                          operation: ''})                                         

    //fetch marks
    useEffect(() => {  
        getWaitingMarksDB()
    }, [])  

    function getWaitingMarksDB(){
      setMarks({...marks,
                  error: null,
                  loading: 'Loading waiting targets'}); 

      //setTimeout(() => {
      dbService
        .getWaitingMarks() 
        .then(response => {
          console.log('waiting marks DB: ',response.data);
          let data = response.data
          if (data.length===0) data=null
          setMarks({marks:data,
                          error:null,
                          loading:null})
      })
      .catch(err => {
        /*let message = 'Get waiting marks from DB: ';
        if (err.response) {
          message=message+'client received an error response '+err;
        } else if (err.request) {
          message=message+'client never received a response, or request never left';
        } else {
          message=message+'unexpected error '+err;
        }
        console.log(message);*/
        setMarks({...marks,
                      error: 'Oh no ... problems loading old events. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com',
                      loading: null}); 
      })  
      //}, 1500)
    }

    function deleteMark (markId){
      console.log('try delete mark: ',markId)

      setMarks({...marks,
                  error: null,
                  loading: 'Deleting in progress'}); 

      //setTimeout(() => {
        dbService
        .deleteMark(markId)
        .then(response => {
          //console.log('mark delete res:',response)
          getWaitingMarksDB()
        })
        .catch(err => {
            /*let message = 'Mark deleting failed: ';
            if (err.response) {
                message=message+'client received an error response '+err;
            } else if (err.request) {
                message=message+'client never received a response, or request never left';
            } else {
                message=message+'unexpected error '+err;
            }
            console.log(message);*/
            let errorMessage= 'Oh no.. deleting failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
            setMarks({...marks,
                        error: errorMessage,
                        loading: null}); 
        })
      //}, 2000)
    }

    function setMarkPublished (id) {   
      setMarks({...marks,
                  error: null,
                  loading: 'Publishing in progress'}); 

      //setTimeout(() => {
        dbService
        .updateMarkStatus(id,1) // 1 = published
        .then(response => {
          //console.log('mark setMarkStatus res:',response)
          getWaitingMarksDB()
        })
        .catch(err => {
            /*let message = 'Set mark status failed: ';
            if (err.response) {
            message=message+'client received an error response '+err;
            } else if (err.request) {
            message=message+'client never received a response, or request never left';
            } else {
            message=message+'unexpected error '+err;
            }
            console.log(message);*/
            let errorMessage= 'Oh no.. publishing failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
            setMarks({...marks,
                         error: errorMessage,
                         loading: null}); 
        }) 
      //}, 1500)
    } 

    function hideMarkModal(){
      setModals({...modals,markModal:false,mark:null});
    }
  
    function showMarkModal(mark){
      setModals({...modals,markModal:true,mark:mark});
    }

    const showDialog = (markId, markTitle, markType, operation) => {
      let dialogTitle = ""
      let dialogText1 = ""
      let dialogText2 = ""
      let buttonText = ''

      switch (operation) {
        case 'publish':
          dialogTitle = `Publish target`
          dialogText1 = `Are you sure that you want to publish: ${markTitle} ?`
          dialogText2 = ''
          buttonText = 'Publish'        
          break;
        case 'delete':
          dialogTitle = `Delete target`
          dialogText1 = `Are you sure that you want to delete: ${markTitle} ?`
          buttonText = 'Delete'      
          break;
        default:
          break;
      }

      setModals({...modals,
                 dialogModal:true,
                 title:dialogTitle,
                 text1:dialogText1,
                 text2:dialogText2,
                 btext:buttonText,
                 id: markId,
                 type: markType,
                 operation: operation})
    }

    const dialogClose = async () => {
      if (modals.operation==='delete'){
         deleteMark(modals.id,modals.type)
      }
      if (modals.operation==='publish'){
        setMarkPublished(modals.id)
      }

      setModals({...modals,
                 dialogModal:false,
                 title:'',
                 text1:'',
                 text2:'',
                 btext:'',
                 id:null,
                 type:'',
                 operation: ''})
    }

    function authorText(mark){
      if (mark.author!=='') return mark.author;
      if (mark.orgname!=='') return mark.orgname;
      if (mark.contact!=='') return mark.contact;
      return 'unknow';
    }

    return (
      <>
        {marks.loading!==null
          ?<Spinner title={marks.loading} />
          :<>
            {marks.error===null
              ?<>
                <div className="CrudBox mt-2">
                  <h4>Waiting for approval</h4>
                  <hr/>
                  {(marks.marks!==null)
                    ?<ListGroup variant="flush">
                    {marks.marks.map((mark, i) =>            
                      <ListGroup.Item key={i}>
                        <strong>{authorText(mark)}</strong> : 
                        <i>" {mark.title}"</i>
                        <br/>
          
                        <div className='mt-2 mb-4'>
                          <ButtonGroup aria-label="Basic example" style={{float: "right"}}>
                            <Button variant="success" onClick={() => showMarkModal(mark)}>Show</Button>
                            <Button variant="primary" onClick={() => showDialog(mark.id,mark.title,mark.type,'publish')}>Publish</Button>
                            <Button variant="danger" onClick={() => showDialog(mark.id,mark.title,mark.type,'delete')}>Delete</Button>
                          </ButtonGroup>
                        </div>
          
                      </ListGroup.Item>
                    )}
                    </ListGroup>
                    :<p>Empty</p>
                  }
                </div>
              </>
              :<>
                <div className="CrudBox">
                  <p className="mb-3">{marks.error}</p>
                </div>
              </>
            }
          </>
        }

        {(modals.markModal===true) && (
            <MarkModal show={true} onHide={() => hideMarkModal()} mark={modals.mark} />
        )}

        {(modals.dialogModal===true) &&        
          <Dialog 
            show={modals.dialogModal} 
            onHide={() => setModals({...modals,dialogModal:false,title:'',text1:'',text2:'',btext:'',id:null})} 
            onYes={() => dialogClose()} 
            title={modals.title} 
            message={modals.text1}
            style='dialogUnsaved' 
            btext={modals.btext}
            message2={modals.text2} />
        }

      </>
    );
}