import React from 'react'
import {
  Dropdown,
  DropdownButton
} from 'react-bootstrap';
import {suitableGroups} from '../checks/ChecksData'

export default function PrimaryGroup({onSelect,primaryId}) {

    let groups = suitableGroups

    let title = ''
    primaryId==null ? title='Select' : title='Change'

    return (
        <>
        <DropdownButton id="dropdown-item-button2" title={title} className='dropdown shadow-none'>
            {groups.map((group,idx) => (
                <>
                {group.value!==17 &&                // no multiple
                    <Dropdown.Item as="button" onClick={() => onSelect(group)} className='dropButton' key={idx}>{group.name}</Dropdown.Item>
                }
            </>
            ))}
        </DropdownButton>
        
        </>
    )
}
