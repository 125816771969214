import React from 'react';
import {
    Navbar,
    Nav,
    NavDropdown
  } from 'react-bootstrap';
  import { Link } from "react-router-dom";

export default function ManagementNav({backMap,logout,editProfile,changeIds,userLevel,resetPassword}) {
 
    return (
      <>
      <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
      <Nav className="mr-auto"> 
        {userLevel===1  //partner
        ?
        <Nav.Item>
          <Nav.Link style={{color: 'red'}} href="/Events" onClick={() => logout(1)}>Logout</Nav.Link>
        </Nav.Item>
        :
        <NavDropdown title="Settings" id="nav-dropdown" className='shadow-none settings'>
          {userLevel===0 && //admin
          <>
            <NavDropdown.Item disabled>EDIT PROFILE:</NavDropdown.Item>
            <NavDropdown.Item onClick={() => editProfile(0)}>- Admin</NavDropdown.Item>
            <NavDropdown.Item onClick={() => editProfile(1)}>- Partner</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item disabled>EDIT PASSWORD:</NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeIds(0)}>- Admin</NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeIds(1)}>- Partner</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => resetPassword()}>Reset password</NavDropdown.Item>
          </>
          }
          {userLevel===2 && //private user
          <>
            <NavDropdown.Item disabled>EDIT:</NavDropdown.Item>
            <NavDropdown.Item onClick={() => editProfile(2)}>Your profile</NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeIds(2)}>Your password</NavDropdown.Item>
          </>
          }
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={() => logout(2)} style={{color: 'red'}}>Logout</NavDropdown.Item>
        </NavDropdown>
        }

      </Nav>
      <Nav>
        <Nav.Item>
          <Nav.Link className="navLink" as={Link} to="/Events" onClick={() => backMap()} style={{color:"black"}}>Back to map</Nav.Link>
        </Nav.Item>
      </Nav>
      </Navbar.Collapse>
      </>
    )
}
//<Nav.Link style={{color: 'white'}} className="backMapLink" href="/Events" onClick={() => backMap()}>Back to map</Nav.Link>