import React from 'react'
import {
  Form
} from 'react-bootstrap';

export default function TypeFormEdit({type,status,onTypeChange}) {

    function isDefault(checkType){
      //console.log('checkType: ',checkType,' status: ',status)
        if (checkType===status) return true
        return false
    }

    return (
      <>
        <Form>
            <div className="mb-3">
                <div className="searchGroup">
                {type===1 &&
                  <>
                  <Form.Check inline custom label="Active Events" type='radio' id='1' className="typeRadio" name="type1" value="activeEvents" onChange={onTypeChange} defaultChecked={isDefault(1)} />
                  <Form.Check inline custom label="Archived Events" type='radio' id='2' className="typeRadio" name="type1"  value="archivedEvents" onChange={onTypeChange} defaultChecked={isDefault(0)}/>
                  </>
                }
                {type===0 &&
                  <>
                  <Form.Check inline custom label="Active Ideas" type='radio' id='4' className="typeRadio" name="type0"  value="activeIdeas" onChange={onTypeChange} defaultChecked={isDefault(1)} /> 
                  <Form.Check inline custom label="Archived Ideas" type='radio' id='3' className="typeRadio" name="type0"  value="archivedIdeas" onChange={onTypeChange} defaultChecked={isDefault(0)}/> 
                  </>
                }
                {type===2 &&
                  <>
                  <Form.Check inline custom label="Active Firesouls" type='radio' id='5' className="typeRadio" name="type2"  value="activeFiresouls" onChange={onTypeChange} defaultChecked={isDefault(1)} /> 
                  <Form.Check inline custom label="Archived Firesouls" type='radio' id='6' className="typeRadio" name="type2"  value="archivedFiresouls" onChange={onTypeChange} defaultChecked={isDefault(0)}/> 
                  </>
                }
                </div>
            </div>
        </Form>
      </>
    );
  }
