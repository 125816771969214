import React, {useState} from 'react'
import {
    Card,
    Form,
    Button,
    Alert
} from 'react-bootstrap';
import dbService from '../../services/db';
import Spinner from '../LoadSpinner';


export default function ResetCard() {

  const [data, setData] = useState({
        email: '',
        error:null,
        newPassword: null,
        loading: false
  });

  const serverError = 'Failed to reset password: Server error. Please try again later.'
  const notFoundError = 'Failed to reset password: Email not found.'

  const handleOnChange = (event) => {
    setData({...data,
        email:event.target.value,
        error: null,
        newPassword:null,
        loading:false})
  }

  const onFormSubmit = (event) => {
    event.preventDefault();
    resetPasswordDB()
  }

  function resetPasswordDB(){
    setData({...data,
              error: null,
              newPassword:null,
              loading:true})
    //setTimeout(() => {
      dbService
        .resetPassword(data.email)
        .then(res => {
          //console.log('Update user password: ', res.data)
          if (res.data==='notFound'){
            setData({...data,error:notFoundError,newPassword:null,loading:false})
          }else{
            setData({...data,error:null,newPassword:res.data,loading:false})
          }
        })  
        .catch(err => {
          /* let message = 'Reset user password DB: ';
          if (err.response) {
              message=message+'client received an error response (5xx, 4xx) '+err;
          } else if (err.request) {
              message=message+'client never received a response, or request never left '+err;
          } else {
              message=message+'unexpected error: '+err;
          }
          console.log('error: '+message) */
          setData({...data,error:serverError,newPassword:null,loading:false})
        }) 
    //}, 1000);
  }
  
    return (
      <>
      <Card>
        <Card.Body>
          {data.loading===true
          ?<Spinner title={'Password reset in progress.'} />
          :<>
            <h3 style={{textAlign: "center"}}>Reset password</h3>
            <hr/>

            <Form noValidate className="loginForm mb-4" onSubmit={onFormSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name='email' placeholder="Enter email" onChange={handleOnChange}/>
              </Form.Group>

              {data.error!==null &&
                <Alert variant='danger'>
                  {data.error}
                </Alert>
              }

              {data.error === notFoundError &&
                <Alert>
                  <strong>Please, send this message to {data.email}:</strong><br/><br/>

                  Dear recipient,<br/>
                  You sent us a request to reset your password. 
                  Unfortunately, Your email address {data.email} could not be found in the user data for our map program.
                  So we can't reset your password.
                  If you want, you can log in map.villagesonmove.com as a new user and create a new user account. Choose the Cooperate button in the upper menu bar. 
                  Welcome to share creative and inspiring ideas of physical activity.
                  <br/><br/>
                                    
                  Kind regards 
                  Vomgomap Team
                </Alert>
              }

              {data.newPassword!==null &&
                <Alert variant='succees'>
                  <strong>Password is reset. Please, send this message to {data.email}:</strong><br/><br/>

                  Dear User of Vomgomap,<br/>
                  Your password for the map application has been reset. Your new password is: {data.email} .
                  Please replace it with a new one in yours settings after first login. Go to www.vomgomap.com and login. 
                  Choose the cooperate button in the upper menu bar. After log in change this password in your settings. 
                  Choose the Settings button in the upper menu bar. <br/><br/>

                  Kind regards
                  Vomgomap Team
                </Alert>
              }

              {(data.newPassword===null && data.error !== notFoundError) &&
                <div className='buttonRight'>
                  <Button variant="success" type="submit">Reset</Button>
                </div>
              }
              
            </Form>
            </>
          }
        </Card.Body>
      </Card>
      </>
    );
  }
