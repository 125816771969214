import React,{useEffect, useState} from 'react';
import './App.css';
import {
  Container
} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom"
import { createBrowserHistory } from "history";
import { MapProvider } from './contexts/MapContext'
import { CrudProvider } from './contexts/CrudContext';
import dbService from './services/db';
import MapView from './components/map/MapView';
import Navigation from './components/navs/Navigation';
import Management from './components/cooperate/management/Management';
import ShowError from './components/ShowError';
import Spinner from './components/LoadSpinner';


export default function App() {
  const history = createBrowserHistory()
  //console.log('APP.JS')

  const [marks,setMarks] = useState({
                                    ideas:[],
                                    events:[],
                                    firesouls:[],
                                    loading:null,
                                    error:null
                                    })

  let currentPathname = null
  let currentSearch = null

  useEffect(() => {  
    //console.log('App.js useEffect')
    getMarks()

    history.listen((newLocation, action) => {
      console.log('action: ',action)
      console.log('newLocation: ',newLocation)
      if (action === "PUSH") {
        if (
          newLocation.pathname !== currentPathname ||
          newLocation.search !== currentSearch
        ) {
          // Save new location
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });

  }, [])  

  function openSpinner(){
    setMarks({...marks,
                loading:'Loading data in progress',
                error: null})
  }

  function getMarks(){
 
   //let timer = setTimeout(()=>{setMarks({...marks,loading:'Loading data in progress',error: null})}, 50);
   setMarks({...marks,
                 loading:'Loading data in progress',
                 error: null})
   //setTimeout(() => {
    dbService
    .getMarks()      
    .then(response => {
        //console.log('All marks', response.data);
        //clearTimeout(timer);

        let ideas = []
        let events = []
        let firesouls = []
        response.data.forEach(mark => {
          switch (mark.type) {
            case 0:
              ideas.push(mark)
              break;
            case 1:
              events.push(mark)
              break;
            case 2:
              firesouls.push(mark)
              break;          
            default:
              break;
          }
        });
        setMarks({
              ideas: ideas,
              events: events,
              firesouls: firesouls,
              loading:null,
              error: null
        });     
    })  
    .catch(err => {
      /*let message = 'App, marks from DB: ';
      if (err.response) {
          message=message + 'client received an error response: '+err;
      } else if (err.request) {
          message=message + 'client never received a response, or request never left: '+err;
      } else {
          message=message + 'unexpected error: '+err;
      }

      console.log(message);*/

      let errorMessage = 'Oh no.. Just now the application is not working properly. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
      setMarks({
          ideas:[],
          events:[],
          firesouls:[],
          loading:null,
          error: errorMessage
          });  
    }) 
   //}, 1000);
  }

  return (
    <>{marks.loading!==null
    ?<Spinner title={marks.loading} />
    :<>
      {marks.error===null
        ?<Router>
          <Container fluid>

              <MapProvider>
                <Navigation path={history.location.pathname} getMarks={() => getMarks()}/>
                <Route exact path="/" render={() => <MapView type={1} marks={marks.events} />}></Route>
                <Route path="/Events" render={() => <MapView type={1} marks={marks.events}/>}></Route>
                <Route path="/Ideas" render={() => <MapView type={0} marks={marks.ideas}/>}></Route>
                <Route path="/FireSouls" render={() => <MapView type={2} marks={marks.firesouls}/>}></Route>
              </MapProvider> 

              <CrudProvider>
                <Route path="/Management" render={() => <Management/>}></Route>
              </CrudProvider>
              
          </Container>
        </Router>
        :<ShowError error={marks.error}/>
      }
      </>
    }
    </>
  )
}