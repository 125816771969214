import React, {useState} from 'react';
import {
    Modal,
    Button
} from 'react-bootstrap';

export default function ShowError({error}) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="markHeader" closeButton>
          <img
            src="/images/logo.png"
            height="60"
            className="d-inline-block align-top rounded mr-2"
            alt="logo"
            style={{backgroundColor:'ghostwhite', padding: '4px'}}
          />
        </Modal.Header>
        <Modal.Body>
          <h5>VOMGO mapapp</h5>
          {error}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}