import React, { useState } from 'react';
import {
    Button,
    Form
} from 'react-bootstrap';

//new mark and edit new mark in preview
export default function NewContacts({mark,onHide,showDialog,edit}) {

  const [data, setData] = useState({
      title: mark.title,
      author: mark.author,
      orgname: mark.orgname,
      contact: mark.contact,
      link: mark.link,
      changed: false
    });

  const [validated, setValidated] = useState(false);

  const onFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
      onHide(data);
    }
    setValidated(true);   
  }

  const handleOnChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setData({...data,
        [name]:value,
        changed: true})
    setValidated(false); 
  }

  const closeOnClick= () => {
    if (data.changed===false){
      onHide('noChanges')
    }else{
      showDialog('unsaved')
    }
  }

  function returnLinkGroup(){
    //firesoul with video
    if (mark.type2===2) return<>
        <Form.Label>UrlLink of YouTube video *</Form.Label>
        <Form.Control type="text" name="link" value={data.link} onChange={handleOnChange} required/>
        <Form.Text className="text-muted">
          E.g https://youtu.be/lgtxKfI2MV8
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          This is required information, please fill it in.
        </Form.Control.Feedback>
      </>
    //not firesoul
    if (mark.type!==2) return<>
        <Form.Label>Link</Form.Label>
        <Form.Control type="text" name="link" value={data.link} onChange={handleOnChange}/>
      </>
  }

  return (
    <>
    {!edit &&(
      <h4 className="mb-4">Title and Contact Information</h4>
    )}

    <Form noValidate validated={validated} onSubmit={onFormSubmit}>
        <Form.Group controlId="formTitle">
            <Form.Label>Title *</Form.Label>
            <Form.Control type="text" name="title" value={data.title} onChange={handleOnChange} required/>
            <Form.Control.Feedback type="invalid">
                This is required information, please fill it in.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formAuthor">
            <Form.Label>Author name *</Form.Label>
            <Form.Control type="text" name="author" value={data.author} onChange={handleOnChange} required/>
            <Form.Control.Feedback type="invalid">
              This is required information, please fill it in.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formLink">
          {mark.type===2
          ?<Form.Label>Organization of author</Form.Label>
          :<Form.Label>Organization / Institution</Form.Label>
          }  
          <Form.Control type="text" name="orgname" value={data.orgname} onChange={handleOnChange} />
        </Form.Group>

        <Form.Group controlId="formEmail">
          {mark.type===2
          ?<Form.Label>Contact information of author *</Form.Label>
          :<Form.Label>Contact information *</Form.Label>
          }    
          <Form.Control type="text" name="contact" value={data.contact} onChange={handleOnChange} required/>
          <Form.Control.Feedback type="invalid">
            This is required information, please fill it in.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formLink">
          {returnLinkGroup()}
        </Form.Group>
        
        {(edit && !data.changed) &&(
            <div className='mt-2 buttonRight'>
                <Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button>
                <Button variant="secondary" onClick={() => closeOnClick()} className='disabledButton mr-4'>Close</Button>
            </div>
        )}

        {(edit && data.changed) &&(
            <div className='mt-2 buttonRight'>
                <Button variant="success" type="submit" className='mr-2'>Save</Button>
                <Button variant="secondary" onClick={() => closeOnClick()} className='mr-4'>Close</Button>
            </div>
        )}

        {!edit &&(
          <div className='mt-2 buttonRight'>
            <Button variant="primary" type="submit">Next</Button>
          </div>
        )}
    </Form>

    </>
  );
}