import React, { useState } from 'react';
import {
    Button,
    Form,
    Alert
} from 'react-bootstrap';
import dbService from '../../../../services/db';
import Spinner from '../../../LoadSpinner'

//edit existing mark
export default function EditContacts({mark,onHide,showDialog}) {

  //console.log('editContacts',mark)

  const [data, setData] = useState({
      title: mark.title,
      author: mark.author,
      orgname: mark.orgname,
      contact: mark.contact,
      link: mark.link,
      changed: false,
      loading: false,
      error:false,
      info:false,
      saved:false
    });

  const [validated, setValidated] = useState(false);

  const onFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
      addDataDB();
    }
    setValidated(true);   
  }

  const handleOnChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setData({...data,
              [name]:value,
              changed: true,
              info:false,
              error:false})
    setValidated(false); 
  }

  function addDataDB(){
    setData({...data,loading:true,error:false,info:false})
    //setTimeout(() => {
      dbService
        .updateContactTitle(mark.id,data)
        .then(response => {
          console.log('ContactTitle DB res: ',response);
          setData({...data,changed:false,loading:false,info:true,saved:true})
          //showDialog('saved')
      })
      .catch(err => {
        /*let message = 'Contact DB: ';
        if (err.response) {
          message=message+'client received an error response '+err;
        } else if (err.request) {
          message=message+'client never received a response, or request never left';
        } else {
          message=message+'unexpected error '+err;
        }
        console.log(message);
        */
        setData({...data,loading:false,error:true,info:false})
      }) 
    //}, 1000);
  }

  const closeOnClick = () => {
    if (data.changed===false){
      onHide(data.saved)
    }else{
      showDialog('unsaved')
    }
  }

  function returnLinkGroup(){
    //firesoul with video
    if (mark.type===2&&mark.link!=='') return<>
        <Form.Label>UrlLink of YouTube video *</Form.Label>
        <Form.Control type="text" name="link" value={data.link} onChange={handleOnChange} required/>
        <Form.Text className="text-muted">
          E.g https://youtu.be/lgtxKfI2MV8
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          This is required information, please fill it in.
        </Form.Control.Feedback>
      </>
    //not firesoul
    if (mark.type!==2) return<>
        <Form.Label>Link</Form.Label>
        <Form.Control type="text" name="link" value={data.link} onChange={handleOnChange}/>
      </>
  }

  return (
    <>
    {data.loading===true
    ?<Spinner title={'Data update in progress'} />
    :<>
      {data.error===true &&
        <Alert variant='danger'>
          Database error: failed to update data
        </Alert>
      }

      {data.info===true &&
        <Alert variant='success'>
          Data updated successfully
        </Alert>
      }

      <Form noValidate validated={validated} onSubmit={onFormSubmit}>
          <Form.Group controlId="formTitle">
              <Form.Label>Title *</Form.Label>
              <Form.Control type="text" name="title" value={data.title} onChange={handleOnChange} required/>
              <Form.Control.Feedback type="invalid">
                This is required information, please fill it in.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formAuthor">
              <Form.Label>Author name *</Form.Label>
              <Form.Control type="text" name="author" value={data.author} onChange={handleOnChange} required/>
              <Form.Control.Feedback type="invalid">
                This is required information, please fill it in.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLink">
              {mark.type===2
              ?<Form.Label>Organization of author</Form.Label>
              :<Form.Label>Organization / Institution</Form.Label>
              }
              <Form.Control type="text" name="orgname" value={data.orgname} onChange={handleOnChange} />
          </Form.Group>

          <Form.Group controlId="formEmail">
              {mark.type===2
              ?<Form.Label>Contact information of author *</Form.Label>
              :<Form.Label>Contact information *</Form.Label>
              }  
              <Form.Control type="text" name="contact" value={data.contact} onChange={handleOnChange} required/>
              <Form.Control.Feedback type="invalid">
                This is required information, please fill it in.
              </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formLink">
              {returnLinkGroup()}
          </Form.Group>
          
          {(!data.changed)
              ?<div className='mt-2 buttonRight'>
                  <Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button>
                  <Button variant="primary" onClick={() => closeOnClick()}>Close</Button>
              </div>
              :<div className='mt-2 buttonRight'>
              <Button variant="success" type="submit" className='mr-2'>Save</Button>
              <Button variant="primary" onClick={() => closeOnClick()}>Close</Button>
              </div>
          }
      </Form>
      </>
    }
    </>
  );
}