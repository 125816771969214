import React, { useState, useEffect, createRef } from 'react'
import {
    Button,
    Form,
    Col,
    Row,
    Alert
} from 'react-bootstrap';
import { 
  Map,
  TileLayer,
  Marker
 } from "react-leaflet"
import dbService from '../../../../services/db';
import Search from "react-leaflet-search";
import Spinner from '../../../LoadSpinner'

export default function EditLocation({id,lat,lng,onHide,showDialog,edit,newEdit}) {
  const [marker, setMarker] = useState({position:[lat,lng],
                                        changed: false,
                                        loading:false,
                                        error:false,
                                        info:false,
                                        saved: false})

  let zoomValue = 4
  if (edit) zoomValue = 13

  let openSearch = true
  //if (edit) openSearch = false

  const refmarker = createRef()

  const updatePosition = () => {
     const m = refmarker.current
     let pos = m.leafletElement.getLatLng();
     setMarker({
              position: [pos.lat,pos.lng],
              changed: true
     })
  }

  const movePosition = (pos) => {
    const m = refmarker.current
    m.leafletElement.setLatLng({lat:pos.lat,lng:pos.lng})
  }

  const [map, setMap] = useState("")

  const getMap = React.useCallback((position) => {
    //console.log('getMap')
    return <Map center={position} zoom={zoomValue} id='crudMap'>
            <TileLayer
              noWrap={true}
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
            />

            <Search 
              onChange={(info) => {
                setMarker({
                  position: [info.latLng.lat,info.latLng.lng],
                  changed: true
                })
                movePosition(info.latLng)
              }}
              position="topright"
              inputPlaceholder="Write place.."
              showMarker={false}
              closeResultsOnClick={true}
              openSearchOnLoad={openSearch}
              zoom={13}
            />
              
            <Marker position={position}
              draggable
              ondragend={updatePosition}
              autoPan
              ref={refmarker}>
            </Marker>
          </Map>
  }, [])

  useEffect(() => {
    setMap(getMap(marker.position))
  }, [getMap])

  const onFormSubmit = (event) => {
    event.preventDefault();
    if ((edit===true)&&(newEdit===false)){
      console.log('save location lat: ',marker.position[0],' lng: ', marker.position[1])
      saveLocationDB()
    }else{ //new mark
      onHide(marker.position)
    }
  }

  function saveLocationDB(){
    setMarker({
      ...marker,
      loading:true,
      info:false,
      error:false
    })
    //setTimeout(() => {
      dbService
        .updateLocation(id,marker.position[0],marker.position[1])
        .then(response => {
          console.log('UpdateLocation DB res: ',response);
          setMap(getMap(marker.position))
          setMarker({
            ...marker,
            changed: false,
            loading:false,
            info:true,
            error:false,
            saved:true
          })
      })
      .catch(err => {
        /*let message = 'Location DB: ';
        if (err.response) {
          message=message+'client received an error response '+err;
        } else if (err.request) {
          message=message+'client never received a response, or request never left';
        } else {
          message=message+'unexpected error '+err;
        }
        console.log(message);*/
        setMarker({
          ...marker,
          changed: false,
          loading:false,
          info:false,
          error:true
        })
      }) 
    //}, 1000);  
  }

  const closeOnClick = () => {
    console.log('closeonclick saved:',marker.saved)  //MIKSI error tilanteen jälkeen saved on undefination???????
    if (marker.changed===false){
      if (newEdit){
        onHide(false)
      }else{
        onHide(marker.saved)
      }
    }else{
      showDialog('unsaved')
    }
  }

  return (
    <>
    {(marker.loading===true)&&(edit)
    ?<Spinner title={'Location update in progress'} />
    :<>
      {marker.error===true &&
        <Alert variant='danger'>
          Database error: failed to update location
        </Alert>
      }

      {marker.info===true &&
        <Alert variant='success'>
          Location updated successfully
        </Alert>
      }

      {!edit &&
        <h4 className="mb-4">Add location</h4>
      }

      <p>Please write the place name or coordinates (e.g 41.076602,30.052495) in the search box.<br/>
        You can also drag the marker to the correct location. 
      </p>

      {map}

      <Form onSubmit={onFormSubmit}>
        <Row className='mt-2'>
          <Col>
            <Form.Control placeholder={marker.position[0]} readOnly/>
          </Col>
          <Col>
            <Form.Control placeholder={marker.position[1]} readOnly/>
          </Col>
        </Row>

        {((!marker.changed)&&(edit)) &&(
          <div className='mt-2 buttonRight'>
            <Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save Location</Button>
            <Button onClick={() => closeOnClick()}>Close</Button>
          </div>
        )}

        {((marker.changed)&&(edit)) &&(
          <div className='mt-2 buttonRight'>
            <Button variant="success" type="submit" className='mr-2'>Save Location</Button>
            <Button onClick={() => closeOnClick()}>Close</Button>
          </div>
        )}

        {((!marker.changed)&&(!edit)) &&(     
          <div className='mt-4 buttonRight'>
              <Button variant="success" type="submit" className='mr-2' disabled>Next</Button>
          </div>
        )}

        {((marker.changed)&&(!edit)) &&(     
          <div className='mt-4 buttonRight'>
              <Button variant="success" type="submit" className='mr-2'>Next</Button>
          </div>
        )}
        
      </Form>
      </>
    }
    </>
  );
  }