import React, { useState,useEffect } from 'react';
import {
    Image,
    Form,
    Button,
    Alert
  } from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {once,regular,urlImages} from '../../../checks/ChecksData'
import DateRange from '../../../DateRange';
import dbService from '../../../../services/db';
import Spinner from '../../../LoadSpinner'

//update mark time
export default function EditTime({onHide,timedata,markId,showDialog}) {    

    const [time, setTime] = useState({timegroup:null,
                                      defaultTimegroup: null,
                                      start:timedata.start===null?null:parseDate(new Date(timedata.start)),
                                      end:timedata.end===null?null:parseDate(new Date (timedata.end)),
                                      changed:false,
                                      loading:'Loading timedata',
                                      error:false,
                                      info:false,
                                      saved:false});
  
    useEffect(() =>{
        getTimegroup(markId)
    },[])

    function getTimegroup(id){
      setTime({...time,changed:false,loading:'Loading timedata',error:false,info:false})
      //setTimeout(() => {
        dbService
            .getMarkGroups(id)      
            .then(response => {   
            let groupsData = response.data
            
            let groups = []
            groupsData.forEach(group => {
                groups.push(group.groupname)
            });
            //console.log('DB time groups: ',response.data)

            let timegroup = null
            if (groups.includes(regular.groupname)) timegroup = regular
            if (groups.includes(once.groupname)) timegroup = once
            //console.log('setTimegroup: ',timegroup)
            setTime({...time,
                        timegroup:timegroup,
                        defaultTimegroup:timegroup,
                        changed:false,
                        loading:null,
                        error: false,
                        saved:false
                        })
        })  
        .catch(err => {
            /*let message = 'Time Groups DB: ';
            if (err.response) {
                message='client received an error response (5xx, 4xx)';
            } else if (err.request) {
                message='client never received a response, or request never left';
            } else {
                message='unexpected error';
            }
            console.log(message)*/
            setTime({...time,loading:null,error:true,info:false})
        })  
      //}, 1000);
    }

    function putTimegroupDB(oldgroup,newgroup){
        if (oldgroup===null){
            newTimegroupDB(newgroup)
        }else{
            updateTimegroupDB(oldgroup,newgroup)
        }
    } 

    function updateTimeDateDB(start,end){
      setTime({...time,loading:'Time update in progress',error:false,info:false})
      //setTimeout(() => {
        dbService
          .updateTimeDate(markId,start,end) 
          .then(response => {
            //console.log('Update timedates DB res: ',response);
            setTime({...time,changed:false,loading:null,error:false,info:true,saved:true})
        })
        .catch(err => {
          /*let message = 'Timedates update DB: ';
          if (err.response) {
            message=message+'client received an error response '+err;
          } else if (err.request) {
            message=message+'client never received a response, or request never left';
          } else {
            message=message+'unexpected error '+err;
          }
          console.log(message);*/
          setTime({...time,loading:null,error:true,info:false})
        })   
      //}, 1000);
    }

    function updateTimegroupDB(oldGroup,newGroup){
      setTime({...time,loading:'Time update in progress',error:false,info:false})
      //setTimeout(() => {
        dbService
          .updateTimegroup(markId,oldGroup,newGroup)
          .then(response => {
            //console.log('Update timegroup DB res: ',response);
            if ((time.start!==timedata.start)||(time.end!==timedata.end)){
                updateTimeDateDB(time.start,time.end)
            }else{
                setTime({...time,changed:false,loading:null,error:false,info:true,saved:true})
            } 
        })
        .catch(err => {
          /*let message = 'Timegroup update DB: ';
          if (err.response) {
            message=message+'client received an error response '+err;
          } else if (err.request) {
            message=message+'client never received a response, or request never left';
          } else {
            message=message+'unexpected error '+err;
          }
          console.log(message);*/
          setTime({...time,loading:null,error:true,info:false})
        })   
      //}, 1000);
    }

    function newTimegroupDB(newGroup){
      setTime({...time,loading:'Time update in progress',error:false,info:false})
      //setTimeout(() => {
        dbService
          .postTimegroup(markId,newGroup)
          .then(response => {
            //console.log('New timegroup DB res: ',response);
            if ((time.start!==timedata.start)||(time.end!==timedata.end)){
                updateTimeDateDB(time.start,time.end)
            }else{
                setTime({...time,changed:false,loading:null,error:false,info:true,saved:true})
            }
        })
        .catch(err => {
          /*let message = 'Add new timegroup DB: ';
          if (err.response) {
            message=message+'client received an error response '+err;
          } else if (err.request) {
            message=message+'client never received a response, or request never left';
          } else {
            message=message+'unexpected error '+err;
          }
          console.log(message);*/
          setTime({...time,loading:null,error:true,info:false})
        })   
      //}, 1000);
    }

    function setDates(data){
      console.log('setDates data:',data)  
      if (data.type==='start'){
            data.date===null ? setTime({...time,start:null,changed:true}) : setTime({...time,start:parseDate(data.date),changed:true})
        }
        if (data.type==='end'){
            data.date===null ? setTime({...time,end:null,changed:true}) : setTime({...time,end:parseDate(data.date),changed:true})
        }
      }
  
    function parseDate(d){
      //console.log('before parse date: ',d)
      let dd = d.getDate()
      let mm = d.getMonth()+1
      let yyyy = d.getFullYear()
      return yyyy+'-'+mm+'-'+dd
    }

    function onFormSubmit(event) {
        event.preventDefault();
        let defaultGroup = null
        if (time.defaultTimegroup!==null) defaultGroup=time.defaultTimegroup.value
        let timegroup = null
        if (time.timegroup!==null) timegroup=time.timegroup.value

        if ((defaultGroup===timegroup)&&(time.start===timedata.start)&&(time.end===timedata.end)){
            onHide(false)
        }else{
            if (defaultGroup!==timegroup){
                putTimegroupDB(defaultGroup,timegroup)
            }else{
                if ((time.start!==timedata.start)||(time.end!==timedata.end)) updateTimeDateDB(time.start,time.end)
            }
        }
    }

    const handleOnChange = (event) => {
        let value = parseInt(event.target.value);
        //console.log('time changed value: ',value)
        if (value===once.value){
            setTime({...time,timegroup:once,changed:true})
        }else{
            setTime({...time,timegroup:regular,changed:true})
        }
        
    }

    function showTime(group){
        return <>
            <h6 className='checkH6 mt-2 mb-2'>Time</h6>
            <DateRange setDateData={(data) =>setDates(data)} start={time.start} end={time.end}/>

            {group===regular 
                ?<p className='infoBox mb-4 mt-4 ml-2'>
                    Select the time-period, when regular events are held.<br/>
                </p>
                :<p className='infoBox mb-4 mt-4 ml-2'>
                    For one-day event you can select only the starting day (from).
                </p>
            } 
        </>
    }

    function checkData(){
        if (time.changed===false) return false
        if (time.start===null) return false
        if ((time.timegroup===once)&&(time.start!==null)) return true
        if ((time.timegroup===regular)&&(time.end!==null)) return true
    }

    function closeOnClick(){
        if (time.changed===false){
          onHide(time.saved)
        }else{
          showDialog('unsaved')
        }
    }

    function radioChecked(value){
        //console.log('radicheck value: ',value)
        //console.log('radiochecked timegroup: ',time.timegroup)
        if (time.timegroup===null) return false
        if (value===time.timegroup.value){
            return true
        }else{
            return false
        } 
    }

    return (
      <>
        {time.loading!==null
        ?<Spinner title={time.loading} />
        :<>
            {time.error===true &&
                <Alert variant='danger'>
                Database error: failed to update time data
                </Alert>
            }

            {time.info===true &&
                <Alert variant='success'>
                Time data updated successfully
                </Alert>
            }

            {time.timegroup===null &&
                <Alert variant="danger">
                    <Alert.Heading>Missing time classification!</Alert.Heading>
                    <p>Please, select Once or Regular.</p>
                </Alert>
            }
            <Form onSubmit={onFormSubmit}>
                <div className="checks">
                    <div className="searchGroup">
                        <FormCheckLabel >
                            <Image src={urlImages+once.icon} className="imgSearch" />
                            <Form.Check 
                                inline 
                                custom 
                                label={once.groupname} 
                                type={'radio'} 
                                id={11} 
                                className="searchCheck" 
                                value={once.value} 
                                name={'time'} 
                                checked={radioChecked(once.value)} 
                                onChange={handleOnChange}/>
                        </FormCheckLabel>

                        <FormCheckLabel >
                            <Image src={urlImages+regular.icon} className="imgSearch" />
                            <Form.Check 
                                inline 
                                custom 
                                label={regular.groupname} 
                                type={'radio'} 
                                id={12} 
                                className="searchCheck" 
                                value={regular.value} 
                                name={'time'} 
                                checked={radioChecked(regular.value)} 
                                onChange={handleOnChange}/>
                        </FormCheckLabel>

                        {showTime(time.timegroup)} 

                    </div>
                </div>

                <div className='mt-2 buttonRight'>
                    {checkData()
                        ?<Button variant="success" type="submit" className='mr-2'>Save</Button>
                        :<Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button>
                    } 
                    <Button onClick={() => closeOnClick()}>Close</Button>
                </div>

            </Form>
        </>
        }
      </>
    );
}