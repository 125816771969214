import axios from 'axios'
//const baseUrl = 'http://localhost:5000/apiVOMGO'
//const baseUrl = 'https://vomgomapapp.azurewebsites.net/apiVOMGO'
const baseUrl = 'https://map.villagesonmove.com/apiVOMGO'

let apiKey = '9c5758576cb48fc68a7539946b24e206bac94c2b89568afe84b3b74fb4c4aa807db98f8e680ecbf7e3e757d1589348310f6f644b445ce9f8180f29b0da5601ed' //created in main.js
let apiHeaders = {
    headers: {
      Authorization: apiKey,
      Token: null
    }
   }

//GETS----------------------------------------------------------------------------

const getMarks = () => { //ok
    return axios.get(baseUrl+'/marks/', apiHeaders)
}

const getSearchMarks = (type, groupIds, start, end) => { //OK
    return axios.get(baseUrl+'/search/'+type+'/'+groupIds+'/'+start+'/'+end, apiHeaders) 
}

const getMarkGroups = (id) => {
    return axios.get(baseUrl+'/groups/'+id, apiHeaders)
}

const getMarkImages = (id) => { //ok
    return axios.get(baseUrl+'/images/'+id, apiHeaders)
}

const getMarkTexts = (id,empty) => {
    return axios.get(baseUrl+'/texts/'+id+'/'+empty, apiHeaders)
}

const getMapGroups = (type,groupIds) => { //OK (not used?)
    return axios.get(baseUrl+'/mapGroups/'+type+'/'+groupIds, apiHeaders)
}

const getMarkData = (id) => { //OK
    return axios.get(baseUrl+'/markData/'+id, apiHeaders)
}

const getMarksCrud = (userId,type,archived,keyword) => {
    if (keyword.length===0){
        return axios.get(baseUrl+'/marksCrud/'+type+'/'+archived+'/'+userId, apiHeaders)  
    } else {
        return axios.get(baseUrl+'/marksKeyword/'+type+'/'+archived+'/'+keyword+'/'+userId, apiHeaders)  
    }
}

const getSummaryData = () => {
    return axios.get(baseUrl+'/summaryData/', apiHeaders)
}

const getExpiredEvents = () => {
    return axios.get(baseUrl+'/getExpiredEvents/', apiHeaders)
}

const getUserValidation = (ids) => {
    return axios.post(baseUrl+'/getUserValidation/',ids, apiHeaders)
}

const getUserData = (id) => {
    return axios.get(baseUrl+'/getUserData/'+id, apiHeaders)
}

const getUsername = (username) => {
    return axios.get(baseUrl+'/getUsername/'+username, apiHeaders)
}

const getWaitingMarks = () => {
    return axios.get(baseUrl+'/getWaitingMarks/', apiHeaders)
}

const getUsers = (level) => {
    return axios.get(baseUrl+'/getUsers/'+level, apiHeaders)
}

const getUserMarks = (userId) => {
    return axios.get(baseUrl+'/getUserMarks/'+userId, apiHeaders)
}

//POSTS----------------------------------------------------------------------------

const postMarkImage = (img,id) => { //ok
    const fd = new FormData();
    fd.append('imgFile',img);
    return axios.post(baseUrl+'/uploadImage/'+id, fd, apiHeaders);
}

const postMark = (markData, groupIds) => {
    let groupPcs = 0
    if (groupIds!==null) groupPcs = groupIds.length
    return axios.post(baseUrl+'/postMark/'+groupIds+'/'+groupPcs, markData, apiHeaders);
}

const postText = (markId,text) => {
    let textData = {text : text}
    return axios.put(baseUrl+'/postText/'+markId, textData, apiHeaders);
}

const postTimegroup = (markId, newgroup) => {
    return axios.post(baseUrl+'/postTimegroup/'+markId+'/'+newgroup, null, apiHeaders);   
}

const postUser = (userData) => {
    return axios.post(baseUrl+'/postUser/',userData, apiHeaders);
}

//UPDATES----------------------------------------------------------------------------

const updateTexts = (id1,text1,id2,text2,markId) => {
    let textData = {id1:id1,text1:text1,id2:id2,text2:text2,markId:markId}
    return axios.put(baseUrl+'/updateTexts/', textData, apiHeaders);
}

const updateContactTitle = (id,data) => {
    return axios.put(baseUrl+'/updateContactTitle/'+id, data, apiHeaders);
}

const updateLocation = (id,lat,lng) => {
    return axios.put(baseUrl+'/updateLocation/'+id+'/'+lat+'/'+lng, null, apiHeaders);
}

const updateMarkStatus = (markId, status) => {
    return axios.put(baseUrl+'/updateMarkStatus/'+markId+'/'+status, null, apiHeaders);   
}

const updateGroups = (groupsData) =>{
    return axios.put(baseUrl+'/updateGroups/', groupsData, apiHeaders);
}

const updatePrimaryGroup = (markId,primValue) => {
    return axios.put(baseUrl+'/updatePrimaryGroup/'+markId+'/'+primValue, null, apiHeaders);
}

const updateTimeDate = (markId, start, end) => {
    return axios.put(baseUrl+'/updateTimeDate/'+markId+'/'+start+'/'+end, null, apiHeaders);   
}

const updateTimegroup = (markId, oldgroup, newgroup) => {
    return axios.put(baseUrl+'/updateTimegroup/'+markId+'/'+oldgroup+'/'+newgroup, null, apiHeaders);   
}

const updateUserProfile = (userData) => {
    return axios.put(baseUrl+'/updateUserProfile/',userData, apiHeaders);
}

const updatePassword = (data) => {
    return axios.put(baseUrl+'/updatePassword/',data, apiHeaders)
}

const resetPassword = (email) => {
    return axios.put(baseUrl+'/resetPassword/',{email:email}, apiHeaders)
}

//DELETES----------------------------------------------------------------------------

const deleteImage = (id,name) => { //ok
    return axios.delete(baseUrl+'/deleteImage/'+id+'/'+name, apiHeaders)    
}

const deleteGroups = (markId,groupIds) => {
    return axios.delete(baseUrl+'/deleteGroups/'+markId+'/'+groupIds, apiHeaders);    
}

const deleteText = (textId) => {
    return axios.delete(baseUrl+'/deleteText/'+textId, apiHeaders);    
}

const deleteMark = (markId) => { //ok
    return axios.delete(baseUrl+'/deleteMark/'+markId, apiHeaders);    
}

const deleteUser = (userId,images) => { //ok
    return axios.delete(baseUrl+'/deleteUser/'+userId+'/'+images, apiHeaders);    
}

export default {
    getMarks,
    getSearchMarks,
    getMarkGroups,
    getMarkImages,
    getMarkTexts,
    getMapGroups,
    getMarkData,
    getMarksCrud,
    getSummaryData,
    getExpiredEvents,
    getUserValidation,
    getUserData,
    getUsername,
    getWaitingMarks,
    getUsers,
    getUserMarks,
    postMarkImage,
    postMark,
    postText,
    postTimegroup,
    postUser,
    updateTexts,
    updateContactTitle,
    updateLocation,
    updateMarkStatus,
    updateGroups,
    updatePrimaryGroup,
    updateTimeDate,
    updateTimegroup,
    updateUserProfile,
    updatePassword,
    resetPassword,
    deleteImage,
    deleteGroups,
    deleteText,
    deleteMark,
    deleteUser
}