import React, {useEffect, useState, useContext} from 'react'
import {
  Alert
} from 'react-bootstrap';
import {CrudContext} from '../../../contexts/CrudContext';
import TypeForm from './TypeFormEdit';
import MarkList from './MarkList';
import KeywordForm from './KeywordForm';
import Dialog from '../DialogModal';
import Spinner from '../../LoadSpinner'

export default function Edit({type,userId}) {

    const [crudData] = useContext(CrudContext);

    const [dialog, setDialog] = useState({show:false,
                                          title:'',
                                          text1:'',
                                          text2:'',
                                          btext:'',
                                          id: null,
                                          operation: ''})
    
    //fetch marks
    useEffect(() => {  
      crudData.fetchMarks(userId,type,1,'','get'); 
    }, [])                                      

    const dialogShow = (markId, markTitle,operation) => {
      let dialogTitle = ""
      let dialogText1 = ""
      let dialogText2 = ""
      let buttonText = ''

      let markName = ''
      switch (type) {
        case 0:
          markName='idea'
          break;
        case 1:
          markName='event'
          break;
        case 2:
          markName='firesoul'
          break;    
        default:
          break;
      }

      switch (operation) {
        case 'publish':
          dialogTitle = `Publish ${markName}`
          dialogText1 = `Are you sure that you want to publish: ${markTitle} ?`
          dialogText2 = `After publishing you can look at it on the map and also edit it on the Edit existing: ${markName}s.`
          buttonText = 'Publish'          
          break;
        case 'archive':
          dialogTitle = `Archive ${markName}`
          dialogText1 = `Are you sure that you want to archive: ${markTitle} ?`
          dialogText2 = `After archiving you can edit it on the Edit existing: ${markName}s.`
          buttonText = 'Archive'        
          break;
        case 'delete':
          dialogTitle = `Delete ${markName}`
          dialogText1 = `Are you sure that you want to delete: ${markTitle} ?`
          buttonText = 'Delete'      
          break;
        default:
          break;
      }

      setDialog({show:true,
                 title:dialogTitle,
                 text1:dialogText1,
                 text2:dialogText2,
                 btext:buttonText,
                 id: markId,
                 operation: operation})
    }

    const dialogClose = async () => {
      if (dialog.operation==='delete'){
        await crudData.deleteMark(dialog.id,userId,crudData.type,crudData.status,crudData.keyword)
      }else{
        let status = 0
        if (crudData.status===status) status = 1
        await crudData.setMarkStatus(dialog.id,status,userId,crudData.type,crudData.keyword)
      }

      setDialog({show:false,
                 title:'',
                 text1:'',
                 text2:'',
                 btext:'',
                 id:null,
                 operation: ''})
    }

    const onTypeChange = (event) => {
      let type = null;
      let status = null;
      switch (event.currentTarget.value) {
        case "activeEvents":
          type = 1;
          status = 1;
          break;
        case "activeIdeas":
          type = 0;
          status = 1;
          break;
        case "activeFiresouls":
          type = 2;
          status = 1;           
          break;
        case "archivedEvents":
          type = 1;
          status = 0;           
          break;
        case "archivedIdeas":
          type = 0;
          status = 0;          
          break;
        case "archivedFiresouls":
          type = 2;
          status = 0;          
          break;
        default:
          break;
      }
      crudData.fetchMarks(userId, type, status, crudData.keyword,'get');
    }

    const onKeywordSubmit = (event) => {
      event.preventDefault();
      let keyword = event.target[0].value;
      console.log('keyword: ',keyword)
      crudData.fetchMarks(userId,crudData.type,crudData.status,keyword,'get');
    }

    const onClearClick = () => {
      crudData.fetchMarks(userId,crudData.type,crudData.status,'','get');
      console.log('keyword clear: ',crudData.keyword)
    }

    function showMarkList(marks,status) {
      if (marks.length === 0)  return <p>Empty</p>;
      return <MarkList userId={userId} marks={marks} status={status} showDialog={dialogShow}/>;
    } 

    function showTitle(type, status, keyword, pcs) {
      let title = '';
      (status===0)? title = 'ARCHIVED ': title = 'ACTIVE';

      switch (type) {
        case 0:
          title = title + ' IDEAS';
          break;
        case 1:
          title = title + ' EVENTS';
          break;
        case 2:
          title = title + ' FIRESOULS';
          break;    
        default:
          break;
      }

      if (keyword===''){
        title = 'ALL '+title
      }

      title = title + ` (${pcs} pcs)`

      return title;
    } 

    return (
      <>
      {crudData.loading!==null
      ?<Spinner title={crudData.loading} />
      :<>
        {crudData.error===null
          ?<>
            <div className="CrudBox mt-2">

              <h4>Search</h4>
              <TypeForm type={type} status={crudData.status} onTypeChange={onTypeChange} />
              <KeywordForm onKeywordSubmit={onKeywordSubmit} onClearClick={onClearClick} value={crudData.keyword} />
            </div>

            {crudData.info!==null &&
              <div className="CrudBox2">
                <Alert variant='success' className="mb-3 mt-3 ml-4 mr-4">
                    {crudData.info}
                </Alert>
              </div>
            }

            <div className="CrudBox">
              <p className="mb-3">{showTitle(crudData.type, crudData.status, crudData.keyword, crudData.marks.length)}</p>
              {showMarkList(crudData.marks, crudData.status)}
            </div>
          </>
          :<>
            <div className="CrudBox">
              <p className="mb-3">{crudData.error}</p>
            </div>
          </>
          }
      </>
      }


      {dialog &&        
        <Dialog 
          show={dialog.show} 
          onHide={() => setDialog({show:false,title:'',text1:'',text2:'',btext:'',id:null})} 
          onYes={() => dialogClose()} 
          title={dialog.title} 
          message={dialog.text1}
          style='dialogUnsaved' 
          btext={dialog.btext}
          message2={dialog.text2} />
      }

      </>
    );
  }

