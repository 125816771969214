import React, {useState, createContext} from 'react';
import dbService from '../services/db';

export const CrudContext = createContext();

export const CrudProvider = props => {
    const [crudData, setCrudData] = useState({
                            type : 1,  //0=ideas, 1=events, 2=firesouls
                            marks : [],
                            status: 1,  //archived = 0, active = 1, waiting = 2 
                            keyword:'',
                            error: null,
                            loading: null,
                            info:null,
                            fetchMarks : (userId,type,status,keyword,operation) => {dbMarks(userId,type,status,keyword,operation)}, //Edit.js, MarkList.js. Operation = 'get','delete','status'
                            deleteMark : (id,userId,type,status,keyword) => {deleteMark(id,userId,type,status,keyword)},                                //Edit.js, OldEvents.js, WaitingMarks.js
                            setMarkStatus : (id,status,userId,type,keyword) => {setMarkStatus(id,status,userId,type,keyword)}             //Edit.js, OldEvents.js, WaitingMarks.js
                            });
    
    //fetch marks from db
    const dbMarks = (userId,type,status,keyword,operation) => {
        //console.log('Context fetch marks')
        let title = 'Loading..'
        switch (type) {
            case 0:
                status==1?title = 'Loading active ideas':title = 'Loading archived ideas'
                break;
            case 1:
                status==1?title = 'Loading active events':title = 'Loading archived events'
                break;
            case 2:
                status==1?title = 'Loading active firesouls':title = 'Loading archived firesouls'
                break;
            default:
                break;
        }
        setCrudData({
            ...crudData,
            type:type,
            status: status,
            keyword: keyword,
            loading:title,
            info:null
        })
        dbService
            .getMarksCrud(userId,type,status,keyword)      
            .then(response => {    
                let markName = ''
                switch (type) {
                  case 0:
                    markName='Idea'
                    break;
                  case 1:
                    markName='Event'
                    break;
                  case 2:
                    markName='Firesoul'
                    break;    
                  default:
                    break;
                }
                let infotext = null
                if (operation!=='get') infotext=`${markName} successfully ${operation}`

                //setTimeout(() => {  
                setCrudData({
                    ...crudData,
                    type: type,
                    marks: response.data,
                    status: status,
                    keyword: keyword,
                    error:null,
                    loading: null,
                    info: infotext});  
                    console.log(response.data)
                //}, 1000);
            })  
            .catch(err => {
                /*let message = null;
                if (err.response) {
                    message=`Crud DB: client received an error response (5xx, 4xx) ${err}`;
                } else if (err.request) {
                    message=`Crud DB: client never received a response, or request never left`;
                } else {
                    message=`Crud DB: unexpected error  ${err}`;
                }
                console.log('Crud DB error: ',message)
                */

                let errorMessage = 'Oh no ... data loading failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
                setCrudData({...crudData,
                            error: errorMessage,
                            info:null,
                            loading: null}); 
            }) 
    }

    const deleteMark = (id,userId,type,status,keyword) => {
        console.log('try delete id: ',id)
        setCrudData({
            ...crudData,
            loading:'Deleting in progress..'
        })
        //setTimeout(() => {
            dbService
            .deleteMark(id)
            .then(response => {
                dbMarks(userId,type,status,keyword,'deleted')
            })
            .catch(err => {
                let message = 'Mark deleted failed: ';
                if (err.response) {
                    message=message+'client received an error response '+err;
                } else if (err.request) {
                    message=message+'client never received a response, or request never left';
                } else {
                    message=message+'unexpected error '+err;
                }
                console.log(message);
                let errorMessage= 'Oh no ... data deleting failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
                setCrudData({...crudData,
                                error: errorMessage,
                                info:null,
                                loading: null}); 
            }) 
        //}, 1500)
    }

    const setMarkStatus = (id,status,userId,type,keyword) => { // STATUS: 0 = archived, 1 = published
        let title = 'in progress..'
        status===0
            ?title='Archiving '+title
            :title='Publishing '+title
        
        setCrudData({
            ...crudData,
            loading:title
        })
        
        //setTimeout(() => {
            dbService
            .updateMarkStatus(id,status)
            .then(response => {
              let operation = 'published'
              if (status===0) operation = 'archived'
              dbMarks(userId,type,status,keyword,operation)
            })
            .catch(err => {
                let message = 'Set mark status failed: ';
                if (err.response) {
                message=message+'client received an error response '+err;
                } else if (err.request) {
                message=message+'client never received a response, or request never left';
                } else {
                message=message+'unexpected error '+err;
                }
                console.log(message);
                let errorMessage= 'Oh no.. failed to set new status. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
                setCrudData({...crudData,
                                error: errorMessage,
                                info:null,
                                loading: null}); 
            }) 
        //}, 1500)

    } 

    return(
        <CrudContext.Provider value={[crudData,setCrudData]}>
            {props.children}
        </CrudContext.Provider>
    )
};