import React from 'react'
import {
    Modal,
    Button
} from 'react-bootstrap';

export default function DialogModal({show,onHide,onYes,title,message,style,btext,message2}) {

  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header className={style} >
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Body>
        {message2}
      </Modal.Body>

      <Modal.Footer>
        {btext!==''
         ?<>
          <Button variant="danger" onClick={onYes}>{btext}</Button>
          <Button variant="primary" onClick={onHide}>Cancel</Button>
          </>
         :<Button variant="primary" onClick={onYes}>Ok</Button>
        }
      </Modal.Footer>
    </Modal>
    </>
  );
  }