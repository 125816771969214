import React from 'react'
import {
  Form,
  ButtonGroup,
  Button,
  Row,
  Col
} from 'react-bootstrap';

export default function KeywordForm({onKeywordSubmit,onClearClick,value}) {
  
    return (
      <>
        <Form onSubmit={onKeywordSubmit} className='ml-2'>
          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Col sm="9">
              <Form.Control key={value} type="text" placeholder="Write keyword.." defaultValue = {value} />
              <Form.Text className="text-muted">
                You can use keyword to refine result. 
                The search is made for authors, organisation names, contacts and titles. 
                You can write only part of yours keyword.
              </Form.Text>
            </Col>
            <Col sm="3" lg="2">
              <ButtonGroup>
                <Button variant="primary" type="submit">Search</Button>
                <Button variant="secondary" onClick={onClearClick}>Clear</Button>
              </ButtonGroup>
            </Col>
          </Form.Group>
        </Form>
      </>
    );
  }