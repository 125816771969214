import React, {useState,useEffect} from 'react'
import {
  Form,
  Button,
  Card,
  Alert
} from 'react-bootstrap';
import dbService from '../../services/db';
import {useSessionStorage} from '../../services/sessionStorageHook';
import Spinner from '../LoadSpinner';

export default function SignUpForm({nextShow,showLevel}) {

  //console.log('SignUp showLevel: ',showLevel)
  //console.log('SignUp nextShow: ',nextShow)

  let userInit = ({
    id:0,
    firstname: '',
    lastname:'',
    organization:'',
    orgtype:'',
    email:'',
    username:'',
    level:null,    //admin=0, partner=1, private=2
    mapType: 1     // 0 = ideas, 1= events, 2 = firesouls, 3 = management
    });

  const [sessionData, setSessionData] = useSessionStorage("vomgoUser",userInit)

  const [data, setData] = useState({
    id:0,
    firstname: '',
    lastname:'',
    organization:'',
    orgtype:'',
    email:'',
    username:'',
    password: '',
    confirmPass:'',
    changed: false,
    error:'',
    saved: false,
    loading:false,
    loadingError:null,
    originalUserName:null,
    originalEmail:null
  });

  useEffect(() => {
    if ((sessionData.id!==0) && (sessionData.level!==showLevel)){ //if admin edit partners profile
      getPartnerProfile()
    }else{
      setData({
        id:sessionData.id,
        firstname: sessionData.firstname===null?'':sessionData.firstname,
        lastname:sessionData.lastname===null?'':sessionData.lastname,
        organization:sessionData.organization===null?'':sessionData.organization,
        orgtype:sessionData.orgtype===null?'':sessionData.orgtype,
        email:sessionData.email===null?'':sessionData.email,
        username:sessionData.username===null?'':sessionData.username,
        password: '',
        confirmPass:'',
        changed: false,
        error:'',
        saved: false,
        loading:false,
        loadingError:null,
        originalUserName:sessionData.username===null?'':sessionData.username,
        originalEmail:sessionData.email===null?'':sessionData.email
      });
    }
  }, [])

  const [validated, setValidated] = useState(false);

  const errorUsername = 'Error: Username is reserved. Please change your username.'
  const errorEmail = 'Error: Email address is already in use. Each user must have a unique email. Please change your email address.'
  const errorServer = 'Failed to update profile: server error. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'

  const onFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) { 
      event.stopPropagation();
    }else{
      if (sessionData.id===0){   //new user (from Register)
        addNewUser()
      }else{                     //change profiledata (from Edit your profile)
        updateUserProfile()
      }
    }
    setValidated(true)
  }

  const handleOnChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setData({...data,
        [name]:value,
        changed: true,
        error: '',
        saved:false})
    setValidated(false); 
  }

  function getPartnerProfile(){    
    setData({...data,loading:true,loadingError:null})
    //setTimeout(() => {
      dbService
      .getUserData(2) //only partner
      .then(res => {
        let data = res.data[0]
        //console.log('getPartnerProfile data DB: ',data)
        setData({
          id:data.id,
          firstname: data.firstname===null?'':data.firstname,
          lastname:data.lastname===null?'':data.lastname,
          organization:data.organization===null?'':data.organization,
          orgtype:data.orgtype===null?'':data.orgtype,
          email:data.email===null?'':data.email,
          username:data.username===null?'':data.username,
          password: '',
          confirmPass:'',
          changed: false,
          error:'',
          saved: false,
          loading:false,
          loadingError:null,
          originalUserName:data.username===null?'':data.username,
          originalEmail:data.email===null?'':data.email
        });  
      })  
      .catch(err => {
        /*let message = 'Get partner profile DB: ';
        if (err.response) {
            message=message + 'client received an error response: '+err;
        } else if (err.request) {
            message=message + 'client never received a response, or request never left: '+err;
        } else {
            message=message + 'unexpected error: '+err;
        }
        console.log(message);*/
        setData({...data,loading:false,loadingError:'Failed to load data: Server error. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'})
      }) 
    //}, 1000);
  }


  function addNewUser(){
    //console.log('addNewUser: ', data)
    let user = {username:data.username,
                firstname: data.firstname,
                lastname:data.lastname,
                organization:data.organization,
                orgtype:data.orgtype,
                level:2,
                email:data.email,
                password: data.password}
    setData({...data,loading:true,loadingError:null})
    //setTimeout(() => {
     dbService
      .postUser(user)
      .then(response => {
        let result = response.data
        if (result==='incorrectUsername'){
          setValidated(false); 
          setData({...data,error: errorUsername,loadingError:null})
        }else{
          if (result==='incorrectEmail'){
            setValidated(false); 
            setData({...data,error: errorEmail,loadingError:null})
          }else{
            console.log('New user id: ', result)
            setSessionData({...sessionData,
                              id: result,
                              username:data.username,
                              firstname: data.firstname,
                              lastname:data.lastname,
                              organization:data.organization,
                              orgtype:data.orgtype,
                              level:2,
                              email:data.email,
                              mapType:3})
            setData({...data,changed:false,saved:true,loading:false,loadingError:null})
            nextShow('openManagement')
          }
        }
      })  
      .catch(err => {
        /*let message = 'Add new user DB: ';
        if (err.response) {
            message=message+'client received an error response (5xx, 4xx) '+err;
        } else if (err.request) {
            message=message+'client never received a response, or request never left '+err;
        } else {
            message=message+'unexpected error';
        }
        console.log('error: '+message)*/
        setData({...data,loading:false,loadingError:'Failed to add new user: server error. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'})
      }) 
    //}, 1000);
    }

    function updateUserProfile(){
      //console.log('data: ',data)
      let username = data.username
      let email = data.email
      if (data.originalUserName===username) username = null
      if (data.originalEmail===email) email = null
      let user = {id:data.id,
                  firstname: data.firstname,
                  lastname:data.lastname,
                  organization:data.organization,
                  orgtype:data.orgtype,
                  email:email, 
                  username:username}
      //console.log('update user profile: ', user)
      setData({...data,loading:true,loadingError:null})
      //setTimeout(() => {
       dbService
        .updateUserProfile(user)
        .then(response => {
          let result = response.data
          console.log('Update user res.data: ', result)
          if (result==='incorrectUsername'){
            setValidated(false); 
            setData({...data,error: errorUsername,loadingError:null})
          }else{
            if (result==='incorrectEmail'){
              setValidated(false); 
              setData({...data,error: errorEmail,loadingError:null})
            }else{
              if (sessionData.level===showLevel){ //admin update own profile or other user update own profile
                setSessionData({...sessionData,
                                  username:data.username,
                                  firstname: data.firstname,
                                  lastname:data.lastname,
                                  organization:data.organization,
                                  orgtype:data.orgtype,
                                  email:data.email})
              }  
              setData({...data,changed:false,saved:true,loading:false,loadingError:null})
            }
          }
        })  
        .catch(err => {
          /*let message = 'Update user profile DB: ';
          if (err.response) {
              message=message+'client received an error response (5xx, 4xx) '+err;
          } else if (err.request) {
              message=message+'client never received a response, or request never left '+err;
          } else {
              message=message+'unexpected error: '+err;
          }
          console.log('error: '+message)*/
          setData({...data,loading:false,loadingError:errorServer})
          setValidated(false);
        }) 
      //}, 1000);
    }

    function showLabel(error){
      if (error===errorUsername) return <Form.Label style={{color:'red'}}>Username *<small style={{color:'black'}}>Change this, please</small></Form.Label>
      return <Form.Label style={{color:'black'}}>Username *</Form.Label>
    }

    function invalidEmail(){
      if (data.email==='') return 'Please enter your email address.'
      return 'Please provide a valid email address.'
    } 

    function invalidConfirmPassword(){
      if (data.confirmPass==='') return 'Please confirm your password.'
      return "Please try again. Passwords don`t match."
    } 

    function invalidPassword(){
      if (data.password==='') return 'Please enter your password.'
      if (data.password.length<6) return 'Password minimum length is 6 character.'
      if (data.password.length>64) return 'Password maximum length is 64 character.' //bcrypt Input Limits
    }
    
    function showUsernameGroup(){
      return <>
          <Form.Group controlId="formUsername">
              {showLabel(data.error,'Username')}
              <Form.Control type="text" name="username" value={data.username} onChange={handleOnChange} required/>
              <Form.Control.Feedback type="invalid">
                  Please enter your username.
              </Form.Control.Feedback>
          </Form.Group>
      </>
    }

    function editProfileTitle(userId){
      switch (userId) {
        case 1:           //admin
          return "Admin's profile"
          break;
        case 2:           //partner
          return "Partners' profile"
          break;    
        default:          //others
          return 'Your profile'
          break;
      }
    }
  
    return (
      <>
      {data.loading===true
        ?<Spinner title={'Loading data in progress.'} />
        :<>
          <Form noValidate validated={validated} onSubmit={onFormSubmit} className="loginForm mb-4">
            {data.error!=='' &&
              <Card bg="danger" text="white" className='mb-2 text-center' style={{width:'100%'}}>
                <Card.Header>{data.error}</Card.Header>
              </Card>
            }

            {data.saved &&
              <Card bg="success" text="white" className='mb-2 text-center' style={{width:'100%'}}>
                <Card.Header>The data is saved</Card.Header>
              </Card>
            }
            {sessionData.id===0 
              ?<>
                <h4 style={{textAlign: "center"}}>New user registration</h4>
                <hr/>
                {data.loadingError!==null &&
                  <Alert variant='danger'>
                    {data.loadingError}
                  </Alert>
                }

                <h6 className='font-weight-bold'>Your profile</h6>
              </>
              :<>
                {data.loadingError!==null &&
                  <Alert variant='danger'>
                    {data.loadingError}
                  </Alert>
                }
                <h5 className='font-weight-bold mb-2'>{editProfileTitle(data.id)}</h5>
                {showUsernameGroup()}
              </>
            }
          
            <Form.Group controlId="formFirstName">
                <Form.Label>First Name *</Form.Label>
                <Form.Control type="text" name="firstname" value={data.firstname} onChange={handleOnChange} required/>
                <Form.Control.Feedback type="invalid">
                    Please enter your first name.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formLastName">
                <Form.Label>Last Name *</Form.Label>
                <Form.Control type="text" name="lastname" value={data.lastname} onChange={handleOnChange} required/>
                <Form.Control.Feedback type="invalid">
                    Please enter your last name.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formOrganization">
                <Form.Label>Organization</Form.Label>
                <Form.Control type="text" name="organization" value={data.organization} onChange={handleOnChange}/>
            </Form.Group>

            {data.organization!=='' &&
              <Form.Group controlId="formOrganizationType">
                <Form.Label>Type of organization</Form.Label>
                <Form.Control as="select" name="orgtype" value={data.orgtype} onChange={handleOnChange} placeholder="Select..">
                  <option value='' readOnly>Select:</option>
                  <option>Village association</option>
                  <option>Sport institution</option>
                  <option>School</option>
                  <option value='other'>Other..</option>
                </Form.Control>
              </Form.Group>
            }

            <Form.Group controlId="formEmail">
                <Form.Label>Email address *</Form.Label>
                <Form.Control type="email" name="email" value={data.email} onChange={handleOnChange} required/>
                <Form.Control.Feedback type="invalid">
                  {invalidEmail()}
                </Form.Control.Feedback>
            </Form.Group>

            {sessionData.id===0 &&
            <>
              <hr/>
              <h6 className='font-weight-bold'>Your identification</h6>
              {showUsernameGroup()}

              <Form.Group controlId="formPassword">
                  <Form.Label>Password *</Form.Label>
                  <Form.Control type="password" name="password" value={data.password} onChange={handleOnChange} required minLength="6" maxLength="64"/>
                  <Form.Control.Feedback type="invalid">
                      {invalidPassword()}
                  </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPassword">
                  <Form.Label>Confirm Password *</Form.Label>
                  <Form.Control type="password" name="confirmPass" value={data.confirmPass} onChange={handleOnChange} required pattern={data.password}/>
                  <Form.Control.Feedback type="invalid">
                      {invalidConfirmPassword()}
                  </Form.Control.Feedback>
              </Form.Group>
            </>
            }

            {data.error==='' &&
              <div className='buttonRight'>
                {data.changed
                ?<Button variant="success" type="submit">Save</Button>
                :<Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button> 
                }
              </div>
            }

          <p style={{textAlign: "center"}}><a href="https://www.villagesonmove.com/privacy-policy/" target="_blank">- Privacy Policy -</a></p>

          </Form>
        </>
      }
      </>
    );
  }