import React, {useState, useEffect} from 'react'
import {
    Button,
    Image,
    Form,
    Alert
} from 'react-bootstrap';
import Dialog from '../../DialogModal';
import CheckSuitable from '../../../checks/CheckSuitable';
import CheckEvent from '../../../checks/CheckEvent';
import CheckIdea from '../../../checks/CheckIdea';
import PrimaryGroup from '../../PrimaryGroup';
import {allGroups, urlImages} from '../../../checks/ChecksData';
import dbService from '../../../../services/db';
import Spinner from '../../../LoadSpinner';

//this is also editGroups!!!
export default function NewGroups({type,markId,markGroups,markPrimary,onHide,showDialog}) { 
  
  let noneCheck = []

  initChecks()
  
  function initChecks(){
    for (let i = 0; i < 50; i++) {
      noneCheck.push(false);
    }
    if ((type===1)&&(markId===null)&&(markPrimary===null)) { //init time of new event mark
      noneCheck[markGroups[0].value]=true
      //console.log('init time: ',markGroups[0].value)
    }
  }

  const [data, setData] = useState({primary:{id:null,
                                            name:'',
                                            icon:null},
                                    checked:noneCheck,
                                    changed: false,
                                    missing: false,
                                    timegroup: null,
                                    loading: null,
                                    error:null,
                                    info:null,
                                    saved:false})

  useEffect(() => {  
    if (markId!==null) getGroups(null)          //edit

    if ((markId===null)&&(markPrimary!==null)) { //new edit EI TOIMI!!!
      let checks = []
      for (let i = 0; i < 50; i++) {
        checks.push(false);
      }
      let timegroup = null
      markGroups.forEach(group => {
        checks[group.value]=true
        if (group.value===19 || group.value===33){ //pick timegroup
            timegroup=group
        }
      });
      setData({...data,
                primary:{id:markPrimary.id,
                        name:markPrimary.name,
                        icon:markPrimary.icon},
               checked:checks,
               changed: false,
               missing: false,
               timegroup: timegroup,
               info: null})
    }
  }, []) 

  function getGroups(info){
   setData({...data,loading:'Loading data',error:null,info:null})
   //setTimeout(() => {
    dbService
      .getMarkGroups(markId)      
      .then(response => {   
        let groups = response.data 
        let checks = []
        for (let i = 0; i < 50; i++) {
          checks.push(false);
        }
        groups.forEach(group => {
          checks[group.id]=true
        });

        if (info===null&&data.error===null){ //loading first time
          let allgroups = allGroups()
          let prim = allgroups[markPrimary]
          setData({...data,
                      primary:{id:prim.value,
                                name:prim.groupname,
                                icon:prim.icon},
                      checked:checks,
                      changed: false,
                      missing:false,
                      timegroup: null,
                      loading:null,
                      error:null,
                      info: info}) 
        }else{
          setData({...data,
                      checked:checks,
                      changed: false,
                      missing:false,
                      timegroup: null,
                      loading:null,
                      error:null,
                      info: info,
                      saved: true}) 
        } 
    })  
    .catch(err => {
        /*let message = '';
        if (err.response) {
            message='Groups DB: client received an error response (5xx, 4xx)';
        } else if (err.request) {
            message='Groups DB: client never received a response, or request never left';
        } else {
            message='Groups DB: unexpected error: '+err;
        }
        console.log(message)*/ 
        setData({...data,changed:false,loading:null,error:'Database error: failed to load data.',info:null})
    })  
   //}, 1000);
  }

  function updateGroups(markId,newGroups,deleteGroups,newPrimary){
    setData({...data,loading:'Classification update in progress',error:null,info:null})
    //setTimeout(() => {
      dbService
        .updateGroups(markId,newGroups,deleteGroups,newPrimary)
        .then(response => {
          //console.log('Mark groups updated', response.data)
          setData({...data,
                      changed: false,
                      missing:false,
                      loading:null,
                      error:null,
                      saved: true}) 
          getGroups('Classification updated successfully');
      })  
      .catch(err => {
        /*let message = 'updateGroup changed DB: ';
        if (err.response) {
            message=message+'client received an error response (5xx, 4xx) '+err;
        } else if (err.request) {
            message=message+'client never received a response, or request never left '+err;
        } else {
            message=message+'unexpected error';
        }
        console.log('error: '+message)*/
        setData({...data,loading:null,error:'Database error: failed to update data.',info:null})
      }) 
    //}, 1000);
  }

  const groupsOnSubmit = (event) => {
    event.preventDefault();
    if (markId===null){     //new
      if (type===0) submitNewIdea(event) //ideas
      if (type===1) checkEventChecks(event) //events
    }else{
      submitEditDB(event)
    }
  };

  function checkEventChecks(e){
    let targ = null;
    let ids = []
    for (let index = 0; index < 13; index++) {
      targ = e.target[index];
      let targId = parseInt(targ.value)
      if ((targ.checked)&&(targId!==data.primary.id)) {
        //console.log('push group id: ', targId)
        ids.push(targId);
      }
    }
    if (ids.includes(21)||ids.includes(20)){ //price is checked
      submitNewEvent(ids)    //ok
    }else{
      setData({...data, missing: true})
    }
  }
    

  function submitNewIdea(event){
    let groups = allGroups()
    let selectGroups = []

    let targ = null;
    let pcs = 13; //amount of checks          

    for (let index = 0; index < pcs; index++) {
      targ = event.target[index];
      let targId = parseInt(targ.value)
      if ((targ.checked)&&(targId!==data.primary.id)) selectGroups.push(groups[targId]);
    }

    console.log('submitNew: ',selectGroups)
    onHide({groups:selectGroups,primary:data.primary})
  }

  function submitNewEvent(ids){
    let groups = allGroups()
    let selectGroups = []     

    ids.forEach(id => {
      selectGroups.push(groups[id])
    });

    console.log('timegroup submit: ',data.timegroup)
    if (markPrimary!==null) selectGroups.push(data.timegroup) //when  edit new/old

    console.log('submitNew Event: ',selectGroups)
    console.log('submitNew Event Primary: ',data.primary)
    onHide({groups:selectGroups,primary:data.primary})
  }

  function submitEditDB(event){
    let newGroups = [];
    let delGroups = [];

    let targ = null;
    let i = 15;           //amount of eventchecks

    if (type === 0) i = 13; //amount of ideaschecks

    for (let index = 0; index < i; index++) {
      targ = event.target[index];
      if (targ.checked){
        if(data.checked[targ.value]===false) newGroups.push(targ.value)
      }else{
        if(data.checked[targ.value]===true) delGroups.push(targ.value)
      }
    }

    let nGroups = null;
    let dGroups = null;
    let prim = 0;
    let newPcs = 0
    let delPcs = 0

    newPcs = newGroups.length
    delPcs = delGroups.length
    if (newPcs > 0) nGroups = newGroups.toString() //!!!! groupIDs must be two digits!!!
    if (delPcs > 0) dGroups = delGroups.toString() //!!!! groupIDs must be two digits!!!
    if (data.primary.id!==markPrimary) prim = data.primary.id

    console.log('newPcs: ',newPcs,', nGroups: ',nGroups,', delPcs: ',delPcs,', dGroups: ',dGroups,' prim: ',prim)
    let groupsData = {markId:markId,addGroups:nGroups,addPcs:newPcs,deleteGroups:dGroups,delPcs:delPcs,primary:prim}

    updateGroups(groupsData)
  }

  function handleOnPrimarySelect(selectdata){
    setData({...data,
             primary:{id:selectdata.value,
                      name:selectdata.name,
                      icon: selectdata.icon},
             changed: true,
             missing:false})
  }

  const handleOnChange = () => {
    if (!data.changed) setData({...data, changed: true});
  }

  const closeOnClick = () => {
    if (!data.changed){
      onHide(data.saved)
    }else{
      data.error===null?showDialog('unsaved'):onHide(false)
    }
  }

  function hideDialog(){
    setData({...data, missing: false})
  }

  return (
    <>
    {data.loading!==null
    ?<Spinner title={data.loading} />
    :<>
      {data.error!==null &&
        <Alert variant='danger'>
          {data.error}
        </Alert>
      }

      {data.info!==null &&
        <Alert variant='success'>
          {data.info}
        </Alert>
      }

      {markPrimary===null && (
        <h3 className="mb-4">Classification</h3>
      )}
    
    {!data.error
      ?
      <>
        <h5>Primarily suitable* :</h5>

        <div className="checks">
          {data.primary.id !== null && (
            <>
            <Image src={urlImages+data.primary.icon} className="imgSearch mr-2" style={{marginLeft:'20px'}}/>
            <span style={{marginRight:'60px'}} >{data.primary.name}</span>
            </>
          )}
          <PrimaryGroup onSelect={(selectdata) => handleOnPrimarySelect(selectdata)} primaryId={data.primary.id}/>
        </div>
        

        {data.primary.id !== null && (
          <Form onSubmit={groupsOnSubmit}>
            <h5 className='mt-4'>Select others:</h5>

            <CheckSuitable checked={data.checked} onChange={handleOnChange} disabledId={data.primary.id}/>

            {type===1
            ?<CheckEvent type='radio' checked={data.checked} onChange={handleOnChange} newEvent={true}/>
            :<CheckIdea type='checkbox'checked={data.checked} onChange={handleOnChange}/>
            }

            {markPrimary===null && (
            <div className='mt-2 buttonRight'>
              <Button variant="primary" type="submit" className='mr-2'>Next</Button>
            </div>
            )}

            {markPrimary!==null && (
              <div className='mt-2 buttonRight'>
              {data.changed ===false
                  ?<Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Save</Button>
                  :<Button variant="success" type="submit" className='mr-2'>Save</Button>
                } 
                <Button onClick={() => closeOnClick()}>Close</Button>
              </div>
            )}

          </Form>
          
        )}
      </>
      :<div className='mt-2 buttonRight'>
          <Button onClick={() => closeOnClick()}>Close</Button>
       </div>
    }

      {data.missing===true &&        
        <Dialog 
          show={data.missing} 
          onHide={() => hideDialog()} 
          onYes={() => hideDialog()} 
          title="Missing classifications!" 
          message="You have to select the price: fee or free" 
          style='dialogUnsaved' 
          btext=''/>
      }
      </>
    }
    </>
  );
  }