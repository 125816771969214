import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Form,
    Alert
} from 'react-bootstrap';
import dbService from '../../../../services/db';
import Dialog from '../../DialogModal';
import {urlUploads} from '../../../checks/ChecksData';
import Spinner from '../../../LoadSpinner';

export default function EditImages({onHide,markId,type}) {

  const [imagesData, setImagesData] = useState({
                                      images : [],
                                      file : null,
                                      selected:  false, //is it file selected
                                      spinner: null,
                                      addLabel: 'Select image..',
                                      error: '',
                                      delError:''});

  const [deleteDialog, setDeleteDialog] = useState({
                                          show:false,
                                          imgId: null,
                                          imgName: ''
                                        });

  const errorText = ' Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'                    
  
  //fetch images from db
  useEffect(() => {  
    getImages();
  }, []) 

  function getImages(){
    setImagesData({...imagesData, spinner: 'Loading images'})
    //setTimeout(() => {
      dbService
      .getMarkImages(markId)      
      .then(response => {
          console.log('Mark images', response.data);
          setImagesData({
                      images: response.data,
                      file: null,
                      selected: false,
                      spinner: null,
                      addLabel: 'Select image..',
                      error: '',
                      delError:''     
          });
          /*setTimeout(() => {setImagesData({
                      images: response.data,
                      file: null,
                      selected: false,
                      spinner: null,
                      addLabel: 'Select image..',
                      error: '',
                      delError:''     
          });}, 1500); */
      })  
      .catch(err => {
        /*let message = '';
        if (err.response) {
            message='Edit get Images DB: client received an error response '+err;
        } else if (err.request) {
            message='Edit get Images DB: client never received a response, or request never left';
        } else {
            message='Edit get Images DB: unexpected error '+err;
        }
        //console.log(message);*/
        setImagesData({
                      ...imagesData,
                      spinner:null,
                      error: 'Error: Failed to display images.'+errorText   
        }); 
      }) 
    //}, 1000);
  }

  /* ADD NEW ----------------------------------*/

  const fileSelected = event => {
    let validType = ['image/jpeg','image/png','image/gif','image/tiff','image/gif','image/webp']
    let imgFile = event.target.files[0]
    if (validType.includes(imgFile.type)){
      setImagesData({
                    ...imagesData,
                    file : imgFile,
                    selected : true,
                    spinner: null,
                    addLabel: imgFile.name,
                    error:'', 
                    delError:''
      });
    }else{
      setImagesData({...imagesData,
                        spinner: null,
                        error:'Images type is invalid. Valid image types are jpg, jpeg, gif, tiff, png or webp.'})
    }
  }

  function addImageDB(){
    setImagesData({...imagesData, spinner: 'Saving the image is in progress.'})
    //setTimeout(() => {
      dbService
        .postMarkImage(imagesData.file,markId)
        .then(response => {
          //console.log('File DB res: ',response);
          getImages();
        })
        .catch(err => {
          /*let message = '';
          if (err.response) {
              message='Add Images DB: client received an error response '+err;
          } else if (err.request) {
              message='Add Images DB: client never received a response, or request never left';
          } else {
              message='Add Images DB: unexpected error '+err;
          }*/
          setImagesData({
                      ...imagesData,
                      spinner: null,
                      error: 'Database error: Failed to add image(s).'+errorText        
          });
          
          //console.log(message);
        }) 
    //}, 1000);
  }

  /* DELETE -------------------------------------------*/

  function deleteImageDB(id,name){
    hideDialog();
    setImagesData({...imagesData, spinner: 'Deleting the image is in progress.'})
    //setTimeout(() => {
      dbService
        .deleteImage(id,name)
        .then(response => {
          //console.log('Image '+id+' delete:'+response.data);
          getImages();
        })
        .catch(err => {
          /*let message = 'Delete Images DB: ';
          if (err.response) {
              message=message+'client received an error response '+err;
          } else if (err.request) {
              message=message+'client never received a response, or request never left';
          } else {
              message=message+'unexpected error '+err;
          }*/
          setImagesData({
                      ...imagesData,
                      spinner:null,
                      delError: 'Database error: Failed to remove image(s).'+errorText        
          });
          //console.log(message);
        }); 
    //}, 1000);
  }



  function hideDialog(){
    setDeleteDialog({
      show:false,
      imgId: null,
      imgName: ''
    })
  }

  function showDialog(id,name){
    setDeleteDialog({
      show:true,
      imgId: id,
      imgName: name
    })
  }

  function closeModal(images){
    if (type!==2){ //not firesoul
      onHide(false)
    }else{
      if (images.length===0){
        setImagesData({...imagesData,
          spinner:null,
          error:'Image is required.'})
      }else{
        onHide(false)
      }
    }
  }

  return (
    <>
      {imagesData.spinner!==null
      ?<Spinner title={imagesData.spinner} />
      :<>
        <h5 className='mt-4'>Add new</h5>
    
        <Form >
          <Form.File 
            id="custom-file"
            label={imagesData.addLabel}
            onChange={fileSelected}
            custom
          />
        </Form>
    
        {imagesData.error!=='' &&
        <>
          <Alert variant='danger' className='mt-2' style={{width:'100%'}}>{imagesData.error}</Alert>
        </>
        }

        {imagesData.delError!=='' &&
        <>
          <Alert variant='danger' className='mt-2' style={{width:'100%'}}>{imagesData.delError}</Alert>
        </>
        }
    
        {imagesData.selected
          ?<Button variant="success" onClick={() => addImageDB()} className="mt-2">Add and save</Button>
          :<Button variant="success" onClick={() => addImageDB()} className="disabledButton mt-2" disabled>Add and save</Button>
        }
    
        {imagesData.images.length!==0 &&
          <>
            <h5 className='mt-4'>Saved images</h5>
            {imagesData.images.map((img, i) => 
              <Card className="imageCard" key={i}>
                <Card.Img 
                  variant="top" 
                  src={urlUploads+img.imgName} 
                  alt={img.imgName} />
                <button onClick={() => showDialog(img.id,img.imgName)} size="sm" className="imgDelete">Delete</button>
              </Card>
            )}
          </>
        }
    
        {deleteDialog && (
          <Dialog show={deleteDialog.show} onHide={() => hideDialog()} onYes={() => deleteImageDB(deleteDialog.imgId,deleteDialog.imgName)} title='Delete image' message='Are you sure you want to delete this image?' style='dialogUnsaved' btext='Delete'/>
        )}
      
        <div className='mt-4 buttonRight'>
          <Button onClick={() => closeModal(imagesData.images)}>Close</Button>
        </div>
  
      </>
      
      }
    </>
  );
  }