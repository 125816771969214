import React from 'react';
import {
    Navbar,
    Nav
  } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default function MapNav({handleSelect, type, active, userId}) {
 
    //console.log('MapNav active: ',active, 'type: ', type,' userId: ',userId)

    return (
      <>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
          <Nav onSelect={handleSelect} activeKey={active}>
            <Nav.Link className="navLink" as={Link} to="/Events" eventKey="1">Events</Nav.Link>
            <Nav.Link className="navLink" as={Link} to="/Ideas" eventKey="0">Ideas</Nav.Link>
            <Nav.Link className="navLink" as={Link} to="/Firesouls" eventKey="2">Firesouls</Nav.Link>

            {(type===2)
            ?<Nav.Link className="navLink" eventKey="Search" disabled>Search</Nav.Link>
            :<Nav.Link className="navLink" eventKey="Search">Search</Nav.Link>
            }

            {(userId===0)
            ?<Nav.Link className="navLink" eventKey="Login">Cooperate</Nav.Link>
            :<Nav.Link className="navLink" as={Link} to="/Management" eventKey="3">Cooperate</Nav.Link>
            }
            
            <Nav.Link className="navLink" eventKey="Info">Info</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </>
    )
}