import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Button
} from 'react-bootstrap';
import Summary from './Summary';

const PrintSummary = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Summary ref={componentRef} />
      <div className='mt-2 buttonRight'>
        <Button onClick={handlePrint} className='mr-2'>Print</Button>
      </div>
    </div>
  );
};

export default PrintSummary;