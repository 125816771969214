import React, {useContext} from 'react'
import {
    Modal,
    Card
} from 'react-bootstrap';
import SearchForm from './SearchForm'
import {MapContext} from '../../contexts/MapContext';
import dbService from '../../services/db';
import Spinner from '../LoadSpinner';


export default function SearchModal({show, onHide, mapType}) {

  const [mapData,setMapData] = useContext(MapContext);
  
  let cssClass='modalHeader';
  let title = 'Search from ';

  if (mapType===0){
    title = title+'ideas';
  } else {
    title = title+'events';
  }

  const makeSearch = (data) => {
    console.log('context search start: ',data.start,' end: ',data.end,' groupIds: ',data.target )
    let ids = null
    if (data.target.length>0) ids=data.target
    setMapData({...mapData,
                   loading:'Loading searchdata in progress',
                   error:null})
    //setTimeout(() => {
        dbService
        .getSearchMarks(mapType, ids, data.start, data.end)      
        .then(response => {
            console.log('search marks get ', response.data, ' and type ',mapType)
            setMapData({...mapData,
                type: mapType,
                searchGroups: data.target,
                searchMarks: response.data,
                startDate: data.start,
                endDate: data.end,
                loading:null,
                error: null
                }); 
            onHide();
        })
        .catch(err => {
          /*let message = '';
          if (err.response) {
              message=`Search DB: client received an error response ${err}`;
          } else if (err.request) {
              message='Search DB: client never received a response, or request never left';
          } else {
              message=`Search DB: unexpected error ${err}`;
          }*/

          //console.log(err)
          setMapData({...mapData,
                        loading:null,
                        error: 'Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'}); 
        })
        onHide();  
      //}, 3000);
  }
  
  return (
    <>
        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className={cssClass} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {mapData.loading!==null
            ?<Spinner title={mapData.loading} />
            :<>
              <Card>
                <Card.Body>
                  <Card.Title>Select, please!</Card.Title>
                    <SearchForm type={mapType} onFormSubmit={(data) =>makeSearch(data)} onHide={onHide} />
                </Card.Body>
              </Card>
            </>}
          </Modal.Body>
        </Modal>
    </>
  );
  }
