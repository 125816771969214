import React, {useState} from 'react'
import {
  Form,
  Button,
  Card,
  Popover,
  OverlayTrigger,
  Alert
} from 'react-bootstrap';
import dbService from '../../services/db';
import Spinner from '../LoadSpinner';

export default function SignInForm({nextShow,setUser}) {

    const [data, setData] = useState({
      username: '',
      password: '',
      changed: false,
      error:false,
      loading:false,
      loadingError:false         
    });

    const [validated, setValidated] = useState(false);

    const errorMessage='Incorrect username or password.'

    const onFormSubmit = async (event) => {
      const form = event.currentTarget;
      event.preventDefault();
  
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setData({...data,error:false})
        setValidated(true); 
      }else{
        //console.log('signIn data: ',data)
        userValidation(data.username,data.password)
      }  
    }

    function userValidation(username,password){
      let ids ={username:username,
                password:password}
      setData({...data,loading: true,loadingError:false})
     //setTimeout(() => {
      dbService
      .getUserValidation(ids)   
      .then(response => {
        let result = response.data
        //console.log('validation data : ', result)

        if (result==='incorrect') { //username or password is incorrect
          setValidated(false); 
          setData({...data,error: true,loading:false})
        }else{
          setData({...data,error: false,loading:false})
          setValidated(true); 
          let storageUser = ({
                    id:result.id,
                    firstname: result.firstname,
                    lastname:result.lastname,
                    organization:result.organization,
                    orgtype:result.orgtype,
                    email:result.email,
                    username:username,
                    level:result.level,   //admin=0, partner=1, other=2
                    mapType: 3
                    });
          setUser(storageUser)
          nextShow('openManagement')
        }
      })  
      .catch(err => {
        /*let message = 'Userdata from DB: ';
        if (err.response) {
            message=message + 'client received an error response: '+err;
        } else if (err.request) {
            message=message + 'client never received a response, or request never left: '+err;
        } else {
            message=message + 'unexpected error: '+err;
        }
        console.log(message);*/
        setData({...data,loading:false,loadingError:true})
      }) 
     //}, 1000);
    }

    const handleOnChange = (event) => {
      let value = event.target.value;
      let name = event.target.name;
      setData({...data,
          [name]:value,
          changed: true,
          error: false})
      setValidated(false); 
    }

    const popover = (
      <Popover id="popoverInfo">
        <Popover.Title as="h3">
          Not available
        </Popover.Title>
        <Popover.Content>
          <p>Public Registration opens in August. Only project partners can collaborate now.</p>
        </Popover.Content>
      </Popover>
    );

    const popover2 = (
      <Popover id="popoverInfo">
        <Popover.Title as="h3">
          Forgotten password
        </Popover.Title>
        <Popover.Content>
          <p>Project partners: check Drive.</p>
        </Popover.Content>
      </Popover>
    );
    
    const infoPopover = () => (
      <OverlayTrigger trigger="hover" placement="bottom" overlay={popover}>
        <button className="linkButton">Register</button>
      </OverlayTrigger>
    );

    const infoPopover2 = () => (
      <OverlayTrigger trigger="hover" placement="bottom" overlay={popover2}>
        <button className="linkButton">Password?</button>
      </OverlayTrigger>
    );

    return (
      <>
      {data.loading===true
        ?<Spinner title={'Login in progress.'} />
        :<>
          <Form  noValidate validated={validated} onSubmit={onFormSubmit} className="loginForm mb-4">
            <h4 style={{textAlign: "center"}}>Sign In</h4>
            <hr/>

            {data.loadingError===true &&
            <Alert variant='danger'>
              Failed to login: Server error. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.
            </Alert>
            }

            <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text"  name="username" value={data.username} onChange={handleOnChange} required />
                <Form.Control.Feedback type="invalid">
                    Please enter your username.
                </Form.Control.Feedback>
                <Form.Text className="text-muted text-right">
                  Don't have a username? <button type="button" onClick={() => nextShow('SignUp')} className="linkButton">Register</button>
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" name="password" value={data.password} onChange={handleOnChange} required />
                <Form.Control.Feedback type="invalid">
                    Please enter your password.
                </Form.Control.Feedback>
                <Form.Text className="text-muted text-right">
                  Forgot <button type="button" onClick={() => nextShow('ForgotPassword')} className="linkButton">password</button>?
                </Form.Text>
            </Form.Group>

            {data.error &&
              <Card bg="danger" text="white" className='mb-2 text-center' style={{width:'100%'}}>
                <Card.Header>{errorMessage}</Card.Header>
              </Card>
            }

            {!data.error &&
            <div className='buttonRight'>
              <Button variant="success" type="submit">
                Submit
              </Button>
            </div>
            }

          </Form>
        </>
      }
      </>
    );
  }

//Don't have a username? {infoPopover()}
//Forgot {infoPopover2()}