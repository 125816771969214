import React, {useEffect,useState} from 'react'
import {
  Button,
  ListGroup,
  ButtonGroup,
  Alert
} from 'react-bootstrap';
import dbService from '../../../services/db';
import Dialog from '../DialogModal';
import Spinner from '../../LoadSpinner';

export default function DeleteUsers() {

    const [modals, setModals] = useState({dialogModal:false,
                                          title:'',
                                          text1:'',
                                          text2:'',
                                          btext:'',
                                          userId: null,
                                          userMarks:[],         //all user marks id
                                          userImages:[]})       //all user images name
                                          
    const [users, setUsers] = useState({data:null,
                                        loading:'Loading users',
                                        error:null})

    //fetch private users
    useEffect(() => {  
        getUsersDB(2) //level 2 = private users
    }, [])  

    function getUsersDB(level){
      setUsers({...users,loading:'Loading users',error:null})
      dbService
        .getUsers(level) 
        .then(response => {
          console.log('Private users DB: ',response.data);
          let res = response.data
          if (res.length===0) res=null
          setUsers({data:res,loading:null,error:null});
          //setTimeout(() => {setUsers({data:res,loading:null,error:null});}, 2000);
      })
      .catch(err => {
        let message = 'Get private users from DB: ';
        if (err.response) {
          message=message+'client received an error response '+err;
        } else if (err.request) {
          message=message+'client never received a response, or request never left';
        } else {
          message=message+'unexpected error '+err;
        }
        console.log(message);
        //setTimeout(() => {setUsers({...users,loading:null,error:'Oh no ... problems loading user. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'});}, 2000);
        setUsers({...users,loading:null,error:'Oh no ... loading users failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'});
      })  
  }

    const showDialog = (userId, userName) => {
      dbService
      .getUserMarks(userId) 
      .then(response => {
        let data = response.data
        //console.log('users data: ',data)

        let marks = [] 
        let images = [] 
        let previous = null
        data.forEach(e => {
          if (e.id!==previous) marks.push(e.id)
          if (e.imgName!==null) images.push(e.imgName)
          previous = e.id
        });

        //console.log('users marks: ',marks)
        //console.log('users images: ',images)

        setModals({...modals,
                 dialogModal:true,
                 title:'Delete user',
                 text1:`Are you sure that you want delete: ${userName} ?`,
                 text2:`If you delete user, all user's targets on the map (${marks.length} pcs) will also be destroyed.`,
                 btext:'Delete',
                 userId: userId,
                 userMarks:marks,
                 userImages:images})
      })
      .catch(err => {
        /*let message = 'Get private users marks from DB: ';
        if (err.response) {
          message=message+'client received an error response '+err;
        } else if (err.request) {
          message=message+'client never received a response, or request never left';
        } else {
          message=message+'unexpected error '+err;
        }
        console.log(message);*/
        setUsers({...users,loading:null,error:`Database error: The user has not been deleted. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.`});
      })  
    }

    const dialogClose = async () => {

     console.log('dialog.close: ', modals.userId,' ',modals.userMarks,modals.userImages) 
     let id=modals.userId
     let images=modals.userImages
     if (images.length===0) images=null
     
     setModals({...modals,
      dialogModal:false,
      title:'',
      text1:'',
      text2:'',
      btext:'',
      userId:null,
      userMarks:[],
      userImages:[]
      })

     setUsers({...users,loading:'Deleting user',error:null})

     dbService
      .deleteUser(id,images)
      .then(response => {
        console.log('Delete user: ',response)
        getUsersDB(2)
      })  
      .catch(err => {
        /*let message = 'User delete DB: ';
        if (err.response) {
            message='client received an error response (5xx, 4xx) '+err;
        } else if (err.request) {
          message='client never received a response, or request never left '+err;
        } else {
          message='unexpected error';
        }
        console.log('error: '+message)*/
        setUsers({...users,loading:null,error:'Oh no ... problems deleting user. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'});
      })
    }

    function authorText(user){
      let author = ''
      if (user.firstname!=='') author=user.firstname
      if (user.lastname!=='') author=author+' '+user.lastname
      if (author!=='') return author
      if (user.organization!=='') return user.organization
      if (user.email!=='') return user.email
      return 'unknow';
    }

    return (
     <>
      {users.loading!==null
      ?<Spinner title={users.loading} />
      :<>
        <div className="CrudBox mt-2">
        <h4>Users</h4>

        {users.error!==null &&
          <Alert variant='danger'>
            {users.error}
          </Alert>
        }
    
        <hr/>
          {(users.data!==null)
            ?<>
              <ListGroup variant="flush">
              {users.data.map((user, i) =>            
                <ListGroup.Item key={i}>
                  <strong>{authorText(user)}</strong> 
    
                  <div className='mt-2 mb-4'>
                    <ButtonGroup aria-label="Basic example" style={{float: "right"}}>
                      <Button variant="danger" onClick={() => showDialog(user.id,authorText(user))}>Delete</Button>
                    </ButtonGroup>
                  </div>
    
                </ListGroup.Item>
              )}
              </ListGroup>
            </>
            :<p>No users</p>
          }

        {(modals.dialogModal===true) &&        
          <Dialog 
            show={modals.dialogModal} 
            onHide={() => setModals({...modals,dialogModal:false,title:'',text1:'',text2:'',btext:'',userId:null,userMarks:[],userImages:[]})} 
            onYes={() => dialogClose()} 
            title={modals.title} 
            message={modals.text1}
            style='dialogUnsaved' 
            btext={modals.btext}
            message2={modals.text2} />
        }

        </div>
      </>
      }
     </>
    );
}