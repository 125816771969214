import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


class TextEditor extends React.Component {
    constructor(props) {
      super(props)
      this.state = { text: '' }
      this.handleChange = this.handleChange.bind(this)
      this.newText = this.newText.bind(this)
    }

    modules = {
        toolbar: [
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link']
        ],
      }
     
    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ]

    componentDidMount() {  
        this.setState({text: this.props.text.text})
    }

    handleChange(value) {
        this.setState({ text: value })
        this.props.newText(this.state.text)
    }

    newText(){
      return this.state.text
    }
   
    render() {
      return (
        <div className="CrudBox mt-2">
            <h4>{this.props.title}</h4>
            <ReactQuill value={this.state.text}
                        onChange={this.handleChange}
                        theme='snow'
                        modules={this.modules}
                        formats={this.formats}
                        className='mb-2'
                        />
        </div>
      )
    }
  }

  export default TextEditor;