import React, { useState, useEffect } from 'react';
import {
    Button,
    Alert
} from 'react-bootstrap';
import dbService from '../../../../services/db';
import TextEditor from './TextEditor';
import Dialog from '../../DialogModal';
import Spinner from '../../../LoadSpinner';

//new texts of mark (edit=false)
//editing texts of existing mark (edit=true, newEdit=false)
//editing text of new, preview mark (edit=true, newEdit=true)
export default function EditText({onHide,mark,edit,newEdit}) {

  const [textData, setTextData] = useState({texts:[],
                                            changed: false,
                                            loading: 'Loading texts',
                                            error:null,
                                            info:null,
                                            saved:false,
                                            firstLoad: true}) 

  const [dialog, setDialog] = useState(false)

  let text1 = ''
  let text2 = ''

  //--------------------Editing texts of existing mark:
 
  //fetch texts of existing mark
  useEffect(() => {  
    if (edit===true && newEdit===false) getTexts(null)
  }, []) 

  function getTexts(infodata){
    setTextData({...textData,
                    loading:'Loading texts',
                    error:null,
                    info:null})  
    //setTimeout(() => {
      dbService
        .getMarkTexts(mark.id,true)      
        .then(response => { 
            let data = response.data
            console.log('Mark gettext data', data)
            setTextData({...textData,
                texts:data,
                changed: false,
                loading:null,
                error:null,
                info:infodata}) 
            console.log('getText text1: '+text1+' text2: '+text2)
      })  
      .catch(err => {
        /*let message = 'Texts DB: ';
        if (err.response) {
            message='client received an error response (5xx, 4xx)';
        } else if (err.request) {
            message='client never received a response, or request never left';
        } else {
            message='unexpected error';
        }
        console.log('error: '+message)*/
        setTextData({...textData,
                        loading:null,
                        error:'Error: Loading texts failed',
                        info:null})  
      })  
    //}, 1000);
  }

  function updateTextsToDB(id1,text1,id2,text2){
    //console.log(`Try update texts id1: ${id1}, text1: ${text1}, id2: ${id2}, text2: ${text2}`);
    setTextData({...textData,
                loading:'Texts update is in progress',
                error:null,
                info:null}) 
    //setTimeout(() => {
        dbService
            .updateTexts(id1,text1,id2,text2,mark.id)
            .then(response => {
                //console.log(`Update mark ${mark.id} texts: ${response.data}`)
                setTextData({...textData,
                                loading:null,
                                changed:false,
                                error:null,
                                saved:true}) 
                getTexts('Texts updated successfully')
            })  
        .catch(err => {
            /*let message = 'EditTexts update DB: ';
            if (err.response) {
                message='client received an error response (5xx, 4xx) '+err;
            } else if (err.request) {
                message='client never received a response, or request never left '+err;
            } else {
                message='unexpected error';
            }
            console.log('error: '+message)*/
            setTextData({...textData,
                            loading:null,
                            error:'Error: Failed to update texts.',
                            info:null}) 
        }) 
    //}, 1000);
  }

  function updateTexts(){                       // !!! texts include html tags
    let text=text1.replace( /(<([^>]+)>)/ig, '') //remove html tags from english text
    if (text!==''){                           
        text=text2.replace( /(<([^>]+)>)/ig, '') //remove html tags
        if (text==='') text2=''                 

        /*console.log('textData.texts: ',textData.texts)
        console.log('saveText1: ',text1)
        console.log('saveText2: ',text2)*/

        let idEnglish=null
        let textEnglish=null
        let idOther=null
        let textOther=null

        if (textData.texts[0].text!==text1){               //english text is changed
            idEnglish=textData.texts[0].id
            textEnglish=text1
        }  

        if (textData.texts.length>1){                      //other language text is already exists
            idOther=textData.texts[1].id
            if (text2===''){                               //text was removed
                textOther='removed'
            }else{
                if (textData.texts[1].text!==text2) {
                    textOther=text2 //other language text is changed
                }else{
                    idOther=null
                }
            }                                   
        }else{                                              //other language text is not exists
            if (text2!=='') textOther=text2                 //add new other language text
        }

        console.log('idEnglish ,textEnglish ,idOther ,textOther: ',`${idEnglish}, ${textEnglish}, ${idOther}, ${textOther}`)
        
        if ((textEnglish!==null)||(textOther!==null)) {
            updateTextsToDB(idEnglish,textEnglish,idOther,textOther)
        }else{
            getTexts('Texts updated successfully')
        }
    }else{                                                  //english text missing!
        setDialog(true)
    }
   }

  //--------------------Editing text of new, preview mark or new texts of mark:

  function checkText(){ //TÄSSÄ other HÄVIÄÄ KUN PELKKÄ english päivittyy (preview mark)!!!!!!
    console.log('checkText, text1: ',text1)
    let text=text1.replace( /(<([^>]+)>)/ig, '') //remove html tags
    if (text!==''){
        console.log('text1: ', text)
        text=text2.replace( /(<([^>]+)>)/ig, '') //remove html tags
        if (text==='') text2='' 
        onHide([text1,text2])
    }else{
        setDialog(true)
    }
   }

  //--------------------Available to all:

  function textLanguageTitle(type){
    if (type === 1)  return 'Description in English *'
    if (type === 2)  return 'Description in other languages'
  }

  function newText(editorText,language){ 
    (language===1) ? text1=editorText : text2=editorText
    //console.log('newText text1: '+text1+' text2 '+text2)
  }


  return (
        <>
        {(textData.loading!==null)&&(edit)&&(!newEdit)
        ?<Spinner title={textData.loading} />
        :<>
            {textData.error!==null &&
              <div className="CrudBox2">
                <Alert variant='danger'>
                    {textData.error}
                </Alert>
              </div>
            }
            
            {textData.info!==null &&
              <div className="CrudBox2">
                <Alert variant='success'>
                    {textData.info}
                </Alert>
              </div>
            }
        
            {(edit===true && newEdit===false) &&( //editing existing mark
            <>
                {textData.texts.map((text, i) => 
                    <TextEditor text={text} title={textLanguageTitle(text.language)} edit={edit} newText={(editorText) =>newText(editorText,text.language)} key={i}/>
                )}

                {(textData.texts.length===1) && (
                    <TextEditor text={{id:2,text:''}} title={textLanguageTitle(2)} edit={edit} newText={(editorText) =>newText(editorText,2)} key={2}/>
                )}

                {(!textData.error)
                    ?<div className='mt-4 buttonRight'>
                        <Button  variant="success" className='mr-2' onClick={() => updateTexts()}>Save</Button>
                        <Button  variant="secondary" onClick={() => onHide(textData.saved)}>Close</Button>
                    </div>
                    :<div className='mt-4 buttonRight'>
                        <Button  variant="success" className='mr-2 disabledButton' disabled onClick={() => updateTexts()}>Save</Button>
                        <Button  variant="secondary" onClick={() => onHide(textData.saved)}>Close</Button>
                    </div>
                }
            </>
            )}

            {(edit===true && newEdit===true) &&( //editing preview mark
            <>
                <TextEditor text={{id:1,text:mark.text1}} title={textLanguageTitle(1)} edit={false} newText={(editorText) =>newText(editorText,1)} key={1}/>
                <TextEditor text={{id:2,text:mark.text2}} title={textLanguageTitle(2)} edit={false} newText={(editorText) =>newText(editorText,2)} key={2}/>
                
                <div className='mt-4 buttonRight'>
                    <Button  variant="success" className='mr-2' onClick={() => checkText()}>Save</Button>
                    <Button  variant="secondary" onClick={() => onHide('noChanges')}>Close</Button>
                </div>
            </>
            )}

            {!edit &&(  //new mark
            <>
                <h4 className="mb-4">Write descriptions</h4>
                <TextEditor text={{id:1,text:''}} title={textLanguageTitle(1)} edit={edit} newText={(editorText) =>newText(editorText,1)} key={1}/>
                <TextEditor text={{id:2,text:''}} title={textLanguageTitle(2)} edit={edit} newText={(editorText) =>newText(editorText,2)} key={2}/>
                
                <div className='mt-4 buttonRight'>
                    <Button onClick={() => checkText()}>Next</Button>
                </div>
            </>)
            }

            {dialog &&        
            <Dialog 
                show={dialog} 
                onHide={() => setDialog(false)} 
                onYes={() => setDialog(false)} 
                title='Description missing' 
                message='You have to write description in english.' 
                style='dialogUnsaved' 
                btext=''/>
            }
        </>
    }
    </>
  );
  }