import React, {useEffect, useState} from 'react'
import {
  Button,
  ButtonGroup,
  Row,
  Col
} from 'react-bootstrap';
import dbService from '../../../services/db';
import MarkModal from '../../mark/MarkModal';
import Dialog from '../DialogModal';
import Spinner from '../../LoadSpinner'

//show past events
export default function OldEvents() {

    const [events, setEvents] = useState({marks:null,
                                          error:null,
                                          loading:'Loading past events'})

    const [modals, setModals] = useState({markModal:false,
                                          mark: null,
                                          dialogModal:false,
                                          title:'',
                                          text1:'',
                                          text2:'',
                                          btext:'',
                                          id: null,
                                          operation: ''})                                         

    //fetch marks
    useEffect(() => {  
        expiredEventsDB()
    }, [])  

    function expiredEventsDB(){
      setEvents({...events,
                    error: null,
                    loading: 'Loading past events'}); 

      //setTimeout(() => {
        dbService
          .getExpiredEvents() 
          .then(response => {
            //console.log('expiredEvents DB res: ',response);
            let data = response.data
            if (data.length===0) data=null
            setEvents({marks:data,
                      error:null,
                      loading:null})
        })
        .catch(err => {
          /*let message = 'Get expired events from DB: ';
          if (err.response) {
            message=message+'client received an error response '+err;
          } else if (err.request) {
            message=message+'client never received a response, or request never left';
          } else {
            message=message+'unexpected error '+err;
          }
          console.log(message);*/
          setEvents({...events,
                    error: 'Oh no ... problems loading old events. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com',
                    loading: null}); 
        })  
      //}, 1500)
    }

    function deleteMark (markId){
      console.log('try delete mark: ',markId)

      setEvents({...events,
        error: null,
        loading: 'Deleting in progress'}); 

      //setTimeout(() => {
        dbService
        .deleteMark(markId)
        .then(response => {
          //console.log('mark delete res:',response)
          expiredEventsDB()
        })
        .catch(err => {
            /*let message = 'Mark deleting failed: ';
            if (err.response) {
                message=message+'client received an error response '+err;
            } else if (err.request) {
                message=message+'client never received a response, or request never left';
            } else {
                message=message+'unexpected error '+err;
            }
            console.log(message);*/
            let errorMessage= 'Oh no.. event deleting failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
            setEvents({...events,
                          error: errorMessage,
                          loading: null}); 
        })
      //}, 2000)
    }

    function setMarkArchived (id) {   
      setEvents({...events,
                    error: null,
                    loading: 'Archiving in progress'}); 

      //setTimeout(() => {
        dbService
        .updateMarkStatus(id,0) // 0 = archived
        .then(response => {
          //console.log('mark setMarkStatus res:',response)
          expiredEventsDB()
        })
        .catch(err => {
            /*let message = 'Set mark status failed: ';
            if (err.response) {
            message=message+'client received an error response '+err;
            } else if (err.request) {
            message=message+'client never received a response, or request never left';
            } else {
            message=message+'unexpected error '+err;
            }
            console.log(message);*/
            let errorMessage= 'Oh no.. event archiving failed. Please try again later. If the error persist, send an email to villagesonmovego@gmail.com.'
            setEvents({...events,
                          error: errorMessage,
                          loading: null}); 
        }) 
      //}, 1500)
  } 

    function authorText(event){
      if (event.author!=='') return event.author;
      if (event.orgname!=='') return event.orgname;
      if (event.contact!=='') return event.contact;
      return 'unknow';
    }

    function hideMarkModal(){
      setModals({...modals,markModal:false,mark:null});
    }
  
    function showMarkModal(mark){
      setModals({...modals,markModal:true,mark:mark});
    }

    const showDialog = (markId, markTitle, operation) => {
      let dialogTitle = ""
      let dialogText1 = ""
      let dialogText2 = ""
      let buttonText = ''

      switch (operation) {
        case 'archive':
          dialogTitle = `Archive event`
          dialogText1 = `Are you sure that you want to archive: ${markTitle} ?`
          dialogText2 = `After archiving you can find it on the Archived events list.`
          buttonText = 'Archive'        
          break;
        case 'delete':
          dialogTitle = `Delete event`
          dialogText1 = `Are you sure that you want to delete: ${markTitle} ?`
          buttonText = 'Delete'      
          break;
        default:
          break;
      }

      setModals({...modals,
                 dialogModal:true,
                 title:dialogTitle,
                 text1:dialogText1,
                 text2:dialogText2,
                 btext:buttonText,
                 id: markId,
                 operation: operation})
    }

    const dialogClose = async () => {
      if (modals.operation==='delete'){
        deleteMark(modals.id)
      }
      if (modals.operation==='archive'){
        setMarkArchived(modals.id)
      }

      setModals({...modals,
                 dialogModal:false,
                 title:'',
                 text1:'',
                 text2:'',
                 btext:'',
                 id:null,
                 operation: ''})
    }

    function eventTime (start,end){
      if (start==null || start=='0000-00-00') return 'No date'
      let startDate = new Date(start)
      let startEvent = startDate.toLocaleDateString()
      if (startEvent!=='Invalid Date'){
        if (end===null || end==='0000-00-00'){
          return startEvent
        }else{
          let endDate = new Date(end)
          let endEvent = endDate.toLocaleDateString()
          if (startEvent===endEvent || endEvent==='Invalid date'){
            return startEvent
          } else {
            return startEvent + '-' + endEvent
          }
        }
      }else{
        return 'Invalid date'
      }
    }

    return (
      <>
      {events.loading!==null
        ?<Spinner title={events.loading} />
        :<>
          {events.error===null
          ?<>
            <div className="CrudBox mt-2">
              {(events.marks!==null)
                ?<>
                {events.marks.map((event, i) => 
                  <div key={i}>
                    <Row>
                      <Col md="auto" className='mb-2'>
                        <strong>{authorText(event)}</strong> : <i>" {event.title}"</i>
                        <br/>
                        {eventTime(event.start,event.end)}
                      </Col>
                      <Col>
                        <ButtonGroup aria-label="Action group" style={{float: "right"}}>
                          <Button variant="success" onClick={() => showMarkModal(event)}>Show</Button>
                          <Button variant="warning" onClick={() => showDialog(event.id,event.title,'archive')}>Archive</Button>
                          <Button variant="danger" onClick={() => showDialog(event.id,event.title,'delete')}>Delete</Button>
                        </ButtonGroup> 
                        <div style={{clear: "both"}}></div>                 
                      </Col>
                    </Row>
                    <hr/>
                  </div>
                )}
                </>
                :<p>Empty</p>
              }
            </div>
           </>
          :<>
            <div className="CrudBox">
              <p className="mb-3">{events.error}</p>
            </div>
          </>
        }
      </>
      }

      {(modals.markModal===true) && (
          <MarkModal show={true} onHide={() => hideMarkModal()} mark={modals.mark} />
      )}

      {(modals.dialogModal===true) &&        
        <Dialog 
          show={modals.dialogModal} 
          onHide={() => setModals({...modals,dialogModal:false,title:'',text1:'',text2:'',btext:'',id:null})} 
          onYes={() => dialogClose()} 
          title={modals.title} 
          message={modals.text1}
          style='dialogUnsaved' 
          btext={modals.btext}
          message2={modals.text2} />
      }
    </>
    );
}