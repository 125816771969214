import React from 'react'
import {
    Card,
    OverlayTrigger,
    Popover,
    Button
} from 'react-bootstrap';
import logo from '../../images/vomb-selfie-valtermarika-phototransparent.png'

const popover1 = (
  <Popover id="popoverInfo">
    <Popover.Title as="h3" className='modalHeader'>
        How to use the map?
    </Popover.Title>
    <Popover.Content>
      <p>
        You can click a target of the map and look at the description of an event, idea or firesoul. 
        You can also search for events or ideas using the search form.
        Please select first from the top menu: <i>Events</i>, <i>Ideas</i> or <i>Fire souls</i>. </p>
    </Popover.Content>
  </Popover>
);

const popover2 = (
  <Popover id="popoverInfo">
    <Popover.Title as="h3" className='modalHeader'>
        How to cooperate?
    </Popover.Title>
    <Popover.Content>
      <p>
          Share Your ideas, events or fire souls on the map. 
          Select first <i>Cooperate</i> from the top menu and log in.
          Please, feel free to share Your information.   </p>
    </Popover.Content>
  </Popover>
);

const infoPopover1 = (text) => (
  <OverlayTrigger trigger="hover" placement="bottom" overlay={popover1}>
    <Button className='linkButton2'>{text}</Button>
  </OverlayTrigger>
);

const infoPopover2 = (text) => (
  <OverlayTrigger trigger="hover" placement="bottom" overlay={popover2}>
    <Button className='linkButton2'>{text}</Button>
  </OverlayTrigger>
);

export default function InfoCard() {
  
    return (
      <>
      <Card>
        <Card.Img variant="top" src={logo} className="imginfo" style={{margin: "auto"}} alt="Marika and Valter"/>
        <Card.Body>
          <h2 style={{textAlign: "center"}}>Ideas For Your Every Day Life!</h2>

          <p className="infoParagraph">
          Welcome to share creative and inspiring ideas of physical activity.
          On this map {infoPopover1('you can find')} new ideas for training, events and running a club. 
          You can also {infoPopover2('share')} your own best practices on the map. 
          If you are a sport club manager, coach, volunteer or just active in sport and physical activity – the map app of Villages on Move is at your service. 
          </p>

          <h3 style={{textAlign: "center"}}>Let’s cooperate! </h3>
              
          <p className="infoParagraph">
          Villages on Move –concept is activating people to give ideas to promote everyday physical activity. 
          Collected ideas are developed by means of culture of experimentation. 
          In the newest <strong>Villages on the Move Go</strong> project we collect stories of the “fire souls” in rural villages promoting active lifestyle. 
          Our goal is to activate inhabitants in rural areas to have more movement and more joy of sports. 
          This is possible <strong>with the support of the Erasmus+ Programme of the European Union</strong>.  
          </p>
               
          <p style={{textAlign: "center"}}>Our projects site: <a href="http://www.villagesonmove.com/" target="_blank">www.villagesonmove.com</a></p>
          <p style={{textAlign: "center"}}><a href="https://www.villagesonmove.com/privacy-policy/" target="_blank">Privacy Policy</a> | <a href="https://www.villagesonmove.com/privacy-policy/" target="_blank">Cookies</a></p>
        
        </Card.Body>
      </Card>
      </>
    );
  }
