import React, {useState, useEffect} from 'react'
import {
  Form,
  Button
} from 'react-bootstrap';
import SearchCheckSuitable from '../checks/CheckSuitable';
import SearchCheckEvent from '../checks/CheckEvent';
import SearchCheckIdea from '../checks/CheckIdea';
import DateRange from '../DateRange';

export default function SearchForm({type,onFormSubmit,onHide}) {

    const [formData, setFormData] = useState({start:null,
                                              end: null,
                                              changed: false,
                                              checked: []})

    useEffect(() => {  
      console.log('searchform useEffect')
      let checked = []
      for (let index = 0; index < 50; index++) {
        checked.push(false)
      }
      setFormData({...formData,checked: checked})
    }, [])                                            

    const handleOnChange = () => {
      if (!formData.changed) setFormData({...formData,changed:true});
    }

    function changeDates(data){
      console.log('setDate data: ',data)
      if ((data.type==='start')&&(data.date!==null)){
        setFormData({...formData,start:parseDate(data.date),changed:true})
      } 
      if ((data.type==='end')&&(data.date!==null)){
        setFormData({...formData,end:parseDate(data.date),changed:true})
      } 
    }

    function parseDate(d){
      let dd = d.getDate()
      let mm = d.getMonth()+1
      let yyyy = d.getFullYear()
      return yyyy+'-'+mm+'-'+dd
    }

    const onSubmit = (event) => {
      event.preventDefault();
  
      let eventTarget = [];
      let targ = null;
      let i = 16;           //amount of eventchecks
  
      if (type === 0) i = 13; //amount of ideaschecks
  
      for (let index = 0; index < i; index++) {
        targ = event.target[index];
        if (targ.checked) eventTarget.push(targ.value);
      }
  
      //console.log('Search: '+eventTarget)
  
      let data ={target: eventTarget,
                 start: formData.start,
                 end: formData.end}

      onFormSubmit(data)
    };

    return (
      <>
      <Form onSubmit={onSubmit}>

        <SearchCheckSuitable checked={formData.checked} onChange={handleOnChange}/>

        {type===1 //event
          ?<>
            <SearchCheckEvent type='radio' checked={formData.checked} onChange={handleOnChange}/>
            <div className="checks">
              <h5>Time</h5>
              <DateRange setDateData={(data) =>changeDates(data)} start={null} end={null} />
            </div>
           </>
          :<SearchCheckIdea type='checkbox' checked={formData.checked} onChange={handleOnChange}/>
        }

        <div className='buttonRight'>
          {formData.changed ===false
            ?<Button variant="success" type="submit" className='mr-2 disabledButton' disabled>Make search</Button>
            :<Button variant="success" type="submit" className='mr-2'>Make search</Button>
          } 
          
          <Button variant="secondary" onClick={onHide}>Close</Button>
        </div>
        
      </Form>
    </>
    )
}
