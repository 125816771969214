import React from 'react';
import YouTube from 'react-youtube';
 
class YoutubePlayer extends React.Component {
  constructor(props){
    super(props)
  }

  render() {
    const opts = {
      width: '100%'
    };
 
    return <YouTube videoId={this.props.videoId} opts={opts} onReady={this._onReady} />;
  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default YoutubePlayer;