import React from 'react';
import {
    Container,
    Spinner,
    Card,
    Image
  } from 'react-bootstrap';

export default function LoadSpinner({title}) {
    return (
        <>
        <Container>
            <Card className="text-center spinnerCard" border="info">
                <Card.Header as='h4'>{title}</Card.Header>
                <Card.Body>
                    <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>
                    <Card.Text style={{ padding:'20px' }}>
                        Please wait a moment.
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                <Image
                    height={60}
                    fluid={true}
                    alt="logo"
                    src="/images/logo.png"
                />
                </Card.Footer>
            </Card>
        </Container>

        </>
    )
}