import React from 'react'
import {
  Image,
  Form
} from 'react-bootstrap';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import {suitableGroups} from './ChecksData'
import {urlImages} from '../../components/checks/ChecksData';

export default function CheckSuitable({checked,onChange,disabledId}) {

    function checkClasses(groupId, disabledId){
        if (groupId === disabledId) {
             return 'searchCheck hiddenElement'
        }else{
             return 'searchCheck'
        }  
     }

    function imgShow(groupId, disabledId){
        if (groupId === disabledId) {
            return false
        }else{
            return true
        }  
    }

    return (
        <>
        <div className="checks">
            <h6 className='checkH6'>Suitable</h6>
            <div className="searchGroup">
                {suitableGroups.map((group,idx) => (
                    <span key={idx}>
                    {group.value!==17 &&                // no multiple
                        <FormCheckLabel>
                        {imgShow(group.value,disabledId) &&(
                            <Image src={urlImages+group.icon} className='imgSearch'  />
                        )}
                        
                        <Form.Check 
                            inline 
                            custom 
                            label={group.name} 
                            type='checkbox' 
                            id={idx+30} 
                            className={checkClasses(group.value,disabledId)} 
                            value={group.value} 
                            defaultChecked={checked[group.value]} 
                            onChange={onChange}/>
                    </FormCheckLabel>     
                    }
                    </span>
                ))}
            </div>
        </div>
        </>
    )
}
